import axios from "axios";
import { BASE_URL, DOWNLOAD_JS_REPORT, HCR, PAGE, PAGE_CARD, ROLL_BACK } from "../../constant/urls";
import ViewDialog from "./HcrJumperPopup";
import { ADMIN, ENGINEER_MANAGER } from "../../constant/userConfig";
import { Checkbox, TableCell } from "@mui/material";

export const handleClose = (setAlertOpen, setsucessAlertOpen) => {
    setAlertOpen(false);
    setsucessAlertOpen(false);
    return false
};
export const getExcelReport = (job_id, setExcelLink, setAlertOpen, setSnackMsg, planner_name, cable_id, exchange_name, offloaded_pcps, search, accounts, setLoading, setOpen, setExcelResp, setCsvLink) => {
    let params = {
        job_id,
        planner_name,
        cable_id,
        exchange_name,
        offloaded_pcps,
        search_key: search.toUpperCase(),
        filter_column: '',
        is_primary_report: `True, xlsx`,
        logged_user: accounts[0]?.idTokenClaims.EIN
    }
    setLoading(true);
    setOpen(true);
    const reporturl = `${BASE_URL}${HCR}/${PAGE}`;
    axios.get(reporturl, { params: params }).then((response) => {
        response.data && (() => {
            let url = response.data['presigned_url'];
            setExcelResp(url);
            setExcelLink(true);
            setLoading(false);
            setCsvLink(false);

        })()
    }).catch((error) => {
        console.error(error);
        setLoading(false);
        setAlertOpen(true);

        setSnackMsg('Something went wrong!.Please try again!');

    });
    return false
}

export const onExcelDownload = (excelResp, setLoading, setOpen, setExcelLink, setAlertOpen, setSnackMsg) => {
    setLoading(true);
    setOpen(true);
    axios.get(excelResp, { responseType: 'blob' }).then((response) => {
        response.data && (() => {
            const url = URL.createObjectURL(response.data) //<---- this should be data.data
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'pcp_jumper_schedules.xlsx');
            link.click();
            setLoading(false);
            setExcelLink(false);
        })()
    }).catch((error) => {
        setAlertOpen(true);
        setLoading(false);
        console.error(error);
        setSnackMsg('Something went wrong!.Please try again!');
        setExcelLink(false);

    });
    return excelResp
};

export const getCsvReport = (job_id, planner_name, cable_id, exchange_name, offloaded_pcps, search, accounts, setLoading, setOpen, setCsvLink, setCsvResp, setExcelLink, setAlertOpen, setSnackMsg) => {
    let params = {
        job_id,
        planner_name,
        cable_id,
        exchange_name,
        offloaded_pcps,
        search_key: search ? search.toUpperCase() : '',
        filter_column: '',
        is_primary_report: `True, csv`,
        logged_user: accounts[0]?.idTokenClaims.EIN
    }

    const reportsurl = `${BASE_URL}${HCR}/${PAGE}`;
    setLoading(true);
    setOpen(true);
    axios.get(reportsurl, { params: params }).then((response) => {
        response.data && (() => {
            let url = response.data['presigned_url'];
            setCsvResp(url);
            setCsvLink(true);
            setLoading(false);
            setExcelLink(false);
        })()
    }).catch((error) => {
        setAlertOpen(true);

        console.error(error);
        setLoading(false);
        setSnackMsg('Something went wrong!.Please try again!');

    });
    return null
}

export const onCsvDownload = (setOpen, csvResp, setLoading, setAlertOpen, setSnackMsg, setCsvLink) => {
    setLoading(true);
    setOpen(true);
    axios.get(csvResp, { responseType: 'blob' }).then((response) => {
        const url = URL.createObjectURL(response.data) //<---- this should be data.data
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'pcp_jumper_schedules.csv');
        link.click();
        setLoading(false);
        setCsvLink(false);


    }).catch((error) => {
        setAlertOpen(true);

        setLoading(false);
        console.error(error);
        setSnackMsg('Something went wrong!.Please try again!');
        setCsvLink(false);

    });
    return null

};

/** Search */
export const requestSearch = (event, setSearch, tabledataCopy, setTabledata) => {
    let searchedVal = event?.target?.value;
    setSearch(searchedVal);
    const filteredRows = tabledataCopy.filter((row) => {
        return (row.pcp.toLowerCase().includes(searchedVal.toLowerCase())
            || row.fn.toLowerCase().includes(searchedVal.toLowerCase()));
    });
    setTabledata(filteredRows);
    return null
};
export const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
        return -1
    }
    if (b[orderBy] > a[orderBy]) {
        return 1
    }
}

export const handleChangePage = (event, newPage, setPage) => {
    setPage(newPage);
    return newPage
};

export const handleChangeRowsPerPage = (event, setRowsPerPage, setPage) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    return 0
};

export const handleSort = (property, order, orderBy, setOrder, setOrderBy) => (event) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    return isAsc
}
export const getComparator = (order, orderBy) => {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => descendingComparator(a, b, orderBy) * -1
}
export const cardDetails = (job_id, params, setOpen, setLoading, setPage, setCardList, setAlertOpen, setSnackMsg) => {

    axios.get(`${BASE_URL}${HCR}/${PAGE_CARD}?job_id=${job_id}`, { params: params }).then((response) => {
        setOpen(true);
        setLoading(true);
        setPage(0);
        response.data?.results && (() => {
            setCardList(response?.data?.results);
            setLoading(false);
            setOpen(false);
        })()
    }).catch((error) => {
        setLoading(false);
        setOpen(false);
        setAlertOpen(true);
        setSnackMsg('Something went wrong!.Please try again!');
        setAlertOpen(true);

    });
    return null
}

export const HomeDetails = (tableHeader, checkboxInfo, job_id, planner_name, cable_id, exchange_name, offloaded_pcps, accounts, setLoading, setOpen, setPage, setTabledata, setTabledataCopy, setAlertOpen, setSnackMsg) => {
    const filteredHeader = tableHeader.filter((headerId) => {
        return checkboxInfo.some((checkId) => {
            return (checkId.id === headerId.id && checkId.checked)
        });
    })?.map(item => { return item.id });;

    let params = {
        job_id,
        planner_name,
        cable_id,
        exchange_name,
        offloaded_pcps,
        search_key: '',
        filter_column: filteredHeader.join(','),
        is_primary_report: `False,''`,
        logged_user: accounts[0]?.idTokenClaims.EIN
    }
    setLoading(true)
    axios.get(`${BASE_URL}${HCR}/${PAGE}`, { params: params }).then((response) => {
        setOpen(true);
        setLoading(true);
        setPage(0);

        response?.data?.results && (() => {
            setTabledata(response?.data?.results);
            setTabledataCopy(response?.data?.results);
            setLoading(false);
            setOpen(false);
        })()
    }).catch((error) => {
        setLoading(false);
        setOpen(false);
        setAlertOpen(true);

        setSnackMsg('Something went wrong!.Please try again!');
        setAlertOpen(true);

    });
    return null
}

export const jumperSchedule = (file_name, jobId, accounts, setLoading, setOpen, setJumperResp, setAlertOpen, setSnackMsg) => {
    const jumperurl = `${BASE_URL}${HCR}/${DOWNLOAD_JS_REPORT}`;
    const params = {
        file_name: file_name,
        job_id: jobId,
        logged_user: accounts[0]?.idTokenClaims.EIN
    }
    setLoading(true);
    setOpen(true);
    axios.patch(jumperurl, params)
        .then((response) => {
            setJumperResp(response.data['presigned_url']);
            setLoading(false);
        }).catch((error) => {
            setAlertOpen(true);
            console.error(error);
            setLoading(false);
            setSnackMsg('Something went wrong!.Please try again!');

        });
    return null

}

export const download = (file_name, setLoading, setOpen, jumperResp, setAlertOpen, setSnackMsg) => {
    setLoading(true);
    setOpen(true);
    axios.get(jumperResp, { responseType: 'blob' }).then((response) => {
        const url = URL.createObjectURL(response.data) //<---- this should be data.data
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', file_name);
        link.click();
        setLoading(false);
    }).catch((error) => {
        setAlertOpen(true);
        setLoading(false);
        console.error(error);
        setSnackMsg('Something went wrong!.Please try again!');
    });
    return null


};


export const rollBack = (setLoading, accounts, selectedRows, cable_id, job_id, exchange_name, setSelectedRows, getCardDetails, getHomeDetails, setsucessAlertOpen, setSnackMsg, setAlertOpen) => {
    setLoading(true);
    const rollbackurl = `${BASE_URL}${HCR}/${ROLL_BACK}`;
    const details = {
        "logged_user": accounts[0]?.idTokenClaims.EIN
    }
    const pcp_data = selectedRows.map((item) => {
        return {
            "pcp": item.pcp,
            "cable": cable_id,
            "job_id": job_id,
            "sau": exchange_name
        }
    })
    const rollbackdata = { ...details, pcp_data }
    axios.put(rollbackurl, rollbackdata)
        .then((res) => {
            setLoading(false);
            setSelectedRows([]);
            getCardDetails();
            getHomeDetails()
            setsucessAlertOpen(true);
            setSnackMsg('Rollback done Sucessfully');
        })
        .catch((error) => {
            setLoading(false);
            setAlertOpen(true);
            setSnackMsg('Something went wrong!.Please try again!');
        });
    return null
}

export const handleCheckboxdisable = (row) => {
    return !(row.status === "Completed" || row.status === "Issue");
}

export const selectedRowsColor = (selectedRows) => {
    if (selectedRows.length) { return '#142032' }
    else { return 'rgb(150, 150, 180)' }
}

export const file_size = (headcell, row) => {
    if (headcell?.id === "file_size") {
        return row[headcell?.id] + "Kb"
    }
    else {
        return row[headcell?.id]
    }
}

export const handleRowSelect = (row, selectedRows, setSelectedRows) => {
    const selectedIndex = selectedRows.indexOf(row);
    const newSelected = [...selectedRows];
    if (selectedIndex === -1) {
        newSelected.push(row);
    }
    else {
        newSelected.splice(selectedIndex, 1);
    }
    setSelectedRows(newSelected);

    return true

}

export const headcellfn = (headcell, row, onDownload, job_id, getJumperSchedule) => {
    if (headcell?.id === "fn") {
        return <ViewDialog jumper={row[headcell?.id]} onDownload={onDownload} jobId={job_id} getJumperSchedule={getJumperSchedule} />
    }
    else { return file_size(headcell, row) }
}

export const userdataRole = (row, userData, selectedRows, setSelectedRows) => {

    if (userData?.ROLE === ADMIN || userData?.ROLE === ENGINEER_MANAGER) {
        return <TableCell sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "2px" }} align="right">
            <Checkbox id="select-tablecell-checkbox-select-all" checked={isSelected(row, selectedRows)} onClick={() => handleRowSelect(row, selectedRows, setSelectedRows)} disabled={handleCheckboxdisable(row)} />

        </TableCell>
    }
    else { return null }
}

export const role = (userData) => {
    if (userData?.ROLE === ADMIN || userData?.ROLE === ENGINEER_MANAGER) {
        return <TableCell key="select" sx={{ color: "white", minWidth: "30px", border: "1px solid rgba(224, 224, 224, 1)", fontSize: "12px", fontWeight: "bold" }} align="center">
            Select
        </TableCell>
    }
    else { return null }
}

/** for passing to child component*/

export const EventFromChild = (type, fileName, job_id, getHomeDetails, getJumperSchedule) => {
    getHomeDetails();
    type === 'popupClose' && getHomeDetails();
    type === 'jumper' && getJumperSchedule(fileName, job_id)

    return null
};

export const isSelected = (row, selectedRows) => selectedRows.indexOf(row) !== -1;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    handleClose, getExcelReport, onExcelDownload, getCsvReport, onCsvDownload,
    requestSearch, descendingComparator, handleChangePage, handleChangeRowsPerPage,
    handleSort, getComparator, cardDetails, HomeDetails, jumperSchedule, download,
    rollBack, handleCheckboxdisable, selectedRowsColor, file_size, handleRowSelect,
    headcellfn, role, EventFromChild, isSelected
}
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Link, Box, Snackbar, Alert, Button, Backdrop, Typography, Card, Dialog, Tab, Tabs, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import JumperEdit from './JumperEdit';
import JumperTable from "./JumperTable";
import JumperAdd from './JumperAdd';
import { tabsClasses } from '@mui/material/Tabs';
import { ACR, ADD_CIRCUIT, BASE_URL, EDIT_CIRCUIT, GENERATE_JUMPER } from '../../constant/urls';
import CircularProgressWithLabel from '../common/CircularProgressWithLabel';
import { Back, getComparator, handleReset, handleSave, handlesnackCloseSuccess, jumperdata } from './JumperSchedule.util';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`pcp-tabname-${index}`}
            aria-labelledby={`pcp-tabname-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `pcp-tabname-${index}`,
        'aria-controls': `pcp-tabname-${index}`,
    };
}

export default function JumperScheduleTabs({ hostNames, scheduleNames, handlecount, loading, job_id, job_owner, message, tableData, count, sucesss, alertOpen, donorCables, schedules, selectedIndex, setSucesss, setAlertOpen, setSelectedIndex, pcpNames, tabNames, setselectedtabname, setLoading, sau, setTableData, selectedtabname, setCount, donor_cables, cable_ids, schedule, PCPs, setSnackMsg, snackMsg, actual_donor_cables }) {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isEditOpen, setIsEditOpen] = useState(false)
    const [open, setOpen] = useState(true);
    const order = 'desc';
    const USER_EIN = JSON.parse(localStorage.getItem("USER_EIN"))
    const [selectedrow, setSelectedrow] = useState(null);
    const [dropdownCircuit, setDropdownCircuit] = useState([]);
    const [dropdownMdf, setDropdownMdf] = useState([]);
    const [dropdownEditCircuit, setDropdownEditCircuit] = useState([]);
    const [dropdownEditfromMdf, setDropdownEditfromMdf] = useState([]);
    const [dropdownEdittoMdf, setDropdownEdittoMdf] = useState([]);
    const [snackopenfail, setsnackOpenfail] = useState(false);
    const [snackopensucess, setsnackopensucess] = useState(false);

    const params = {
        logged_user: USER_EIN
    }
    const handlesnackCloseFail = () => {
        setsnackOpenfail(false);
    };

    const [editedData, setEditedData] = useState({
        "ADSL_TAM_LLU": null,
        "Amendments": null,
        "Circuit": null,
        "D_side": null,
        "EET": null,
        "From_MDF_Bar": null,
        "From_MDF_No": null,
        "From_MDF_Pair": null,
        "LLU": null,
        "New_E_Side": null,
        "Pcp": null,
        "Recovery_e_side": null,
        "Status": null,
        "Tie": null,
        "Tie1": null,
        "To_MDF_Bar": null,
        "To_MDF_No": null,
        "To_MDF_Pair": null,
        "To_Status": null,
        "Unit": null,
        "Host_Cable": null
    })
    const last = selectedIndex === tabNames?.length - 1;




    //set selected tab index value
    const handletabchange = (event, newvalue) => {
        const data = dataforSelectedtab();
        const mdfpair = data.map((item) => { return item.To_MDF_Pair });
        const dside = data.map((item) => { return item.D_side });
        const recovery = data.map((item) => { return item.Recovery_e_side },);

        if (mdfpair.includes("____")) {
            setSnackMsg('Value missing in some of the From MDF Number field');
            setsnackOpenfail(true)
            setAlertOpen(true);
            setShowLink(false);
        }
        else if (dside.includes("____")) {
            setSnackMsg('Value missing in some of the From D_side field');
            setsnackOpenfail(true)
            setAlertOpen(true);
            setShowLink(false)
        }
        else if (recovery.includes("____")) {
            setSnackMsg('Value missing in some of the From Recovery_e_side field');
            setsnackOpenfail(true)
            setAlertOpen(true);
            setShowLink(false)
        }
        else {
            setSelectedIndex(newvalue)
            setselectedtabname(tabNames[newvalue]);
            setDropdownCircuit('');
            setDropdownMdf('');
            setDropdownEditCircuit('');
            setDropdownEditfromMdf('');
            setDropdownEdittoMdf('');
        }
    }



    //data for a selected tab name
    const dataforSelectedtab = () => {
        if (tableData !== null && tableData !== undefined) {
            for (const element of tableData) {
                const tabdata = element;
                if (tabdata[selectedtabname]) {
                    tabdata[selectedtabname].sort(getComparator(order, "Recovery_e_side"))
                    return tabdata[selectedtabname]
                }
            }
        }
        return [];
    }

    // to open the modal onClick of add button

    const handleOpen = (tabName) => {
        const host_cableindex = pcpNames.indexOf(selectedtabname);
        const donor_cable = donor_cables.split(",")[host_cableindex]


        const addurl = `${BASE_URL}/${ACR}/${ADD_CIRCUIT}?sau=${sau}&donor_cable=${donor_cable}&pcp=${selectedtabname}`;
        axios.get(addurl, { params: params })
            .then((res) => {
                setDropdownCircuit(res.data.result.circuit_status);
                setDropdownMdf(res.data.result.to_data[0])
                setLoading(false)
                setIsModalOpen(true);
            })
            .catch((error) => {
                console.error(error);
            })
    };

    const handleClose = () => {
        setIsModalOpen(false);
        setAlertOpen(false);
        setOpen(false);
    }


    // delete functions
    const [deleteConfirmation, setDeleteConfirmation] = useState(false);

    const handleOpenDelete = (row) => {
        setSelectedrow(row);
        setDeleteConfirmation(true);
    }

    const handleCloseDelete = () => {
        setSelectedrow(null);
        setDeleteConfirmation(false);
    }

    const handleConfirmDelete = () => {
        const currentTabdata = dataforSelectedtab();
        const updatedData = currentTabdata.filter((item) => item.Circuit !== selectedrow.Circuit || item.From_MDF_No !== selectedrow.From_MDF_No || item.From_MDF_Pair !== selectedrow.From_MDF_Pair);
        const updatedTabData = [...tableData];
        updatedTabData[selectedIndex][tabNames[selectedIndex]] = updatedData;
        setTableData(updatedTabData);
        setSelectedrow(null)
        setAlertOpen(true);
        setSnackMsg("Deleted Sucessfully!");
        setsnackopensucess(true)
        setCount(count + 1)
        handleCloseDelete();
    }

    //  edit functions
    const handleEditOpen = () => {
        const host_cableindex = pcpNames.indexOf(selectedtabname);
        const donor_cable = donor_cables.split(",")[host_cableindex]
        selectedrow && (() => {
            setLoading(true)
            const editurl = `${BASE_URL}/${ACR}/${EDIT_CIRCUIT}?sau=${sau}&donor_cable=${donor_cable}&pcp=${selectedtabname}&schedules=${schedules[selectedIndex]}`
            axios.get(editurl, { params: params })
                .then((res) => {
                    setDropdownEditCircuit(res.data.result.circuit_status);
                    const data_from_data = res.data.result.from_data[0]
                    setDropdownEditfromMdf(data_from_data);
                    const data_to_data = res.data.result.to_data[0]
                    setDropdownEdittoMdf(data_to_data);
                    setLoading(false)
                    setEditedData(selectedrow);
                    setIsEditOpen(true)
                })
                .catch((error) => {
                    console.error(error);
                    setSnackMsg('Something went wrong!.Please try again!');
                    setsnackOpenfail(true)
                    setAlertOpen(true);
                })
        })()
    };

    const [currentindex, setCurrentindex] = useState(0)

    const handleEditClose = () => {
        setIsEditOpen(false);
        setEditedData({
            "ADSL_TAM_LLU": '',
            "Amendments": '',
            "Circuit": "",
            "D_side": '',
            "EET": '',
            "From_MDF_Bar": "",
            "From_MDF_No": "",
            "From_MDF_Pair": '',
            "LLU": '',
            "New_E_Side": '',
            "Pcp": "",
            "Recovery_e_side": '',
            "Status": "",
            "Tie": '',
            "Tie1": '',
            "To_MDF_Bar": "",
            "To_MDF_No": '',
            "To_MDF_Pair": '',
            "To_Status": "",
            "Unit": '',
            "Host_Cable": ''
        })
        setCurrentindex(0);
        setSelectedrow(null)
    };

    //functionality to handle next button in edit modal
    const handleNext = () => {
        const currentTabdata = dataforSelectedtab() || [];
        const selectedIndex = currentTabdata.findIndex((item) => item.id === selectedrow.id);
        const nextIndex = currentindex === 0 || currentindex === tableData.length ? (selectedIndex + 1) % currentTabdata.length : (currentindex + 1) % currentTabdata.length
        setCurrentindex(nextIndex)
        const nextrow = currentTabdata[nextIndex];
        setSelectedrow(nextrow)
        setEditedData(nextrow);
    }

    //functionality to handle back button in edit modal
    const handleBack = () => {
        Back(dataforSelectedtab, selectedrow, currentindex, tableData, setCurrentindex, setSelectedrow, setEditedData)
    }

    // generate excel
    const [showLink, setShowLink] = useState(false);
    const [jumperScheduleResp, setJumperScheduleResp] = React.useState(null);     //pdn code
    const [fileName, setFileName] = useState("") //pdn code

    const handleGeneratejumper = () => {
        setLoading(true)
        const data = dataforSelectedtab();
        const mdfpair = data.map((item) => { return item.To_MDF_Pair });
        const dside = data.map((item) => { return item.D_side });
        const recovery = data.map((item) => { return item.Recovery_e_side });

        if (mdfpair.includes("____")) {
            setSnackMsg('Value missing in some of the From MDF Number field');
            setsnackOpenfail(true)
            setAlertOpen(true);
            setShowLink(false)
            setLoading(false)
        }
        else if (dside.includes("____")) {
            setSnackMsg('Value missing in some of the From D_side field');
            setsnackOpenfail(true)
            setAlertOpen(true);
            setShowLink(false)
            setLoading(false)
        }
        else if (recovery.includes("____")) {
            setSnackMsg('Value missing in some of the From Recovery_e_side field');
            setsnackOpenfail(true)
            setAlertOpen(true);
            setShowLink(false)
            setLoading(false)
        }
        else {
            const excelurl = `${BASE_URL}/${ACR}/${GENERATE_JUMPER}`;
            const details = {
                "cable_id": cable_ids,
                "exchange": "",
                "job_owner": job_owner,
                "job_id": job_id,
                "sau": sau,
                "logged_user": USER_EIN,
                "pcps": pcpNames,
                "schedules": schedules,
                "host_cables": donorCables,
                "actual_donor_cables": actual_donor_cables
            }

            const jumper = scheduleNames.map((item, index) => {
                if (tabNames[index] !== undefined) {
                    return {
                        "schedule": item,
                        "working": tableData[index][tabNames[index]]?.length || '',
                        "host_cable": hostNames[index],
                        [tabNames[index]]: tableData[index][tabNames[index]]
                    }
                }
                return null

            })

            const jumper_data = jumperdata(jumper)
            const exceldata = { ...details, jumper_data };

            axios.post(excelurl, exceldata,)
                .then((response) => {
                    let url = response.data['presigned_url'];    //pdn code                
                    setJumperScheduleResp(url);                  //pdn code
                    setShowLink(true);
                    setLoading(false);
                    setFileName(response.data.fileName) //pdn code

                })
                .catch((error) => {
                    setLoading(false);
                    console.error(error);
                    setSnackMsg('Something went wrong!.Please try again!');
                    setsnackOpenfail(true)
                    setAlertOpen(true);
                });
        }
    }
    const onDownload = () => {
        axios.get(jumperScheduleResp, { responseType: 'blob' }).then((response) => {       //pdn code
            (response.data) && (() => {
                const url = window.URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                link.click();
            })()
        }).catch((error) => {
            setLoading(false);
            console.error(error);
            setSnackMsg('Something went wrong!.Please try again!');
            setsnackOpenfail(true)
            setAlertOpen(true);
        });
    };






    const [progress, setProgress] = React.useState(10);

    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
        }, 2000);
        return () => {
            clearInterval(timer);
        };
    }, []);

    return (
        <>
            {loading &&
                <Box sx={{ width: '100%' }}>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={open}
                        onClick={handleClose}
                    >
                        <CircularProgressWithLabel value={progress} />
                    </Backdrop>
                </Box>

            }

            <Snackbar open={snackopenfail} autoHideDuration={6000} onClose={handlesnackCloseFail} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert onClose={handlesnackCloseFail} severity="error" sx={{ width: '100%' }}>
                    {snackMsg}
                </Alert>
            </Snackbar>

            <Snackbar open={snackopensucess} autoHideDuration={6000} onClose={() => handlesnackCloseSuccess(setsnackopensucess)} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert onClose={() => handlesnackCloseSuccess(setsnackopensucess)} severity="success" sx={{ width: '100%' }}>
                    {snackMsg}
                </Alert>
            </Snackbar>
            <Card id="jumper-schedule-card" data-testid="jumper-schedule-card" sx={{ marginTop: "10px" }}>
                <>
                    <Box id="jumper-schedule-box" sx={{
                        borderBottom: 1, borderColor: 'divider', marginLeft: "8px", flexGrow: 1,
                        bgcolor: 'background.paper',
                    }}>
                        <Tabs variant="scrollable"
                            scrollButtons id="jumper-schedule-tabs" value={selectedIndex} onChange={handletabchange} data-testid="jumper-schedule-tab" aria-label="basic tabs example"
                            sx={{
                                [`& .${tabsClasses.scrollButtons}`]: {
                                    '&.Mui-disabled': { display: "none" },
                                }, marginTop: "-8px"
                            }}>
                            {tabNames?.map((tabName, index) => (

                                <Tab id={"jumper-schedule-tab-" + sau + "-" + tabName} data-testid={"jumper-schedule-tab-" + sau + "-" + tabName} key={tabName} label={tabName} {...a11yProps(index)} />
                            ))}
                        </Tabs>
                    </Box>

                    {tabNames?.map((tabName, index) => (
                        <Box sx={{ marginLeft: "-20px" }} key={tabName}>
                            <TabPanel id={"jumper-schedule-" + tabName + selectedIndex} value={selectedIndex} key={tabName} index={index} style={{ marginTop: "-8px" }}>
                                <Button id={"jumper-schedule-button-add-" + sau + "-" + tabName} data-testid={"jumper-schedule-button-add-" + sau + "-" + tabName} variant="outlined" onClick={() => handleOpen(tabName)} disabled={count > 0 ? false : true} style={{ marginLeft: 10, height: "30px", backgroundColor: "#142032", color: "white" }}>
                                    Add
                                </Button>
                                <Button id={"jumper-schedule-button-delete-" + sau + "-" + tabName} data-testid={"jumper-schedule-button-delete-" + sau + "-" + tabName} variant="outlined" style={{ marginLeft: 10, height: "30px", backgroundColor: "#142032", color: "white" }} onClick={(e) => {
                                    e.preventDefault();
                                    handleOpenDelete(selectedrow)
                                }} disabled={!selectedrow}>
                                    Delete
                                </Button>

                                <Button id={"jumper-schedule-button-edit-" + sau + "-" + tabName} data-testid={"jumper-schedule-button-edit-" + sau + "-" + tabName} variant="outlined" style={{ marginLeft: 10, height: "30px", backgroundColor: "#142032", color: "white" }}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleEditOpen()
                                    }} disabled={!selectedrow}>
                                    Edit
                                </Button>

                                <Button id={"jumper-schedule-button-reset-" + sau + "-" + tabName} data-testid={"jumper-schedule-button-reset-" + sau + "-" + tabName} variant="outlined" style={{ marginLeft: 10, height: "30px", backgroundColor: "#142032", color: "white" }} onClick={() => handleReset(tabName, index, scheduleNames, hostNames, sau, tableData, selectedtabname, selectedIndex, setTableData, setShowLink, handlecount, setsnackopensucess, setSnackMsg, cable_ids, job_owner, USER_EIN, job_id, setsnackOpenfail, setAlertOpen)}>
                                    Reset
                                </Button>

                                <Button id={"jumper-schedule-button-save-" + sau + "-" + tabName} data-testid={"jumper-schedule-button-save-" + sau + "-" + tabName} variant="outlined" style={{ marginLeft: 10, height: "30px", backgroundColor: USER_EIN === job_owner ? "#142032" : "grey", color: "white" }} onClick={() => handleSave(tabName, index, pcpNames, cable_ids, job_id, donor_cables, job_owner, USER_EIN, tableData, setsnackOpenfail, setSnackMsg, setsnackopensucess, setAlertOpen)} disabled={USER_EIN !== job_owner}>
                                    Save
                                </Button>

                                <JumperTable data={dataforSelectedtab()} tabName={tabName} onRowclick={(row) => setSelectedrow(row)} selectedrow={selectedrow} />
                                {dropdownCircuit ?
                                    <JumperAdd isOpen={isModalOpen} data={dataforSelectedtab()} dropdownMdf={dropdownMdf} dropdownCircuit={dropdownCircuit} closeModal={handleClose} pcpName={tabName} sau={sau} donor_cables={donor_cables} tableData={tableData} selectedIndex={selectedIndex} tabNames={tabNames} setCount={setCount} count={count} setIsModalOpen={setIsModalOpen} setTableData={setTableData} /> :
                                    <Snackbar open={alertOpen} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                                        <Alert onClose={handleClose} id="alert" severity="error" sx={{ width: '100%' }}>
                                            {snackMsg}
                                        </Alert>
                                    </Snackbar>
                                }

                                {(dropdownEditCircuit && dropdownEditfromMdf && dropdownEdittoMdf !== undefined && dropdownEdittoMdf) ?
                                    <JumperEdit isOpen={isEditOpen} dropdownEdittoMdf={dropdownEdittoMdf} dropdownEditfromMdf={dropdownEditfromMdf} dropdownEditCircuit={dropdownEditCircuit} closeModal={handleEditClose} data={editedData} pcpName={tabName} dataforSelectedtab={dataforSelectedtab()} selectedrow={selectedrow} selectedIndex={selectedIndex} tableData={tableData} setTableData={setTableData} tabNames={tabNames} onNext={handleNext} onBack={handleBack} /> :
                                    <Snackbar open={alertOpen} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                                        <Alert onClose={handleClose} id="alert" severity="error" sx={{ width: '100%' }}>
                                            {snackMsg}
                                        </Alert>
                                    </Snackbar>
                                }

                                <Dialog
                                    open={deleteConfirmation}
                                    onClose={handleCloseDelete}
                                >
                                    <DialogContent>
                                        <DialogContentText id="jumper-schedule-button-delete">Do you want to delete the row?</DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button variant="outlined" id="jumper-schedule-button-dismiss" style={{ marginBottom: "2px", marginRight: "8px", height: "30px", backgroundColor: "#142032", color: "white" }} onClick={handleCloseDelete}>Dismiss</Button>
                                        <Button variant="outlined" id="jumper-schedule-button-apply" data-testid="jumper-schedule-delete-button-apply" style={{ marginBottom: "2px", marginRight: "8px", height: "30px", backgroundColor: "#142032", color: "white" }} onClick={handleConfirmDelete}>Apply</Button>
                                    </DialogActions>

                                </Dialog>
                            </TabPanel>
                        </Box>
                    ))}
                </>
                {((dataforSelectedtab()?.length === 0 && message === "") || last) && (hostNames && scheduleNames) ? <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom: "7px" }}>
                    {showLink &&
                        <div >
                            <Link
                                id="exchange-download-Jumper-schedule"
                                variant="body2"
                                underline='hover'
                                data-testid="download-link"
                                onClick={onDownload}
                                sx={{ fontSize: 16, color: 'red', cursor: 'pointer' }}
                            >
                                Click here to download the Jumper Schedule
                            </Link>
                        </div>
                    }
                    <Button id="button-generate-jumper-schedule" data-testid="button-generate-jumper-schedule" style={{ backgroundColor: "#142032", color: "white", height: "30px", }}
                        onClick={handleGeneratejumper}>
                        Generate Jumper Schedule
                    </Button>:
                </div> : null}
                {tableData.length === 0 ? <p>{message}</p> : null}
            </Card>
        </>
    )
}

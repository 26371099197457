
import axios from 'axios';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';

import headCells from '../../constant/exchangejobheadcell';
import mucjHeadCells from '../../constant/mucjexchangejobheadcell';
import '../../container/App.css';
import WarningModal from './WarningModal';

import { useMsal } from '@azure/msal-react';
import DownloadIcon from '@mui/icons-material/Download';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { Alert, Backdrop, Box, Button, Card, CardContent, CardHeader, Checkbox, CircularProgress, InputAdornment, Link, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, TextField, Switch } from '@mui/material';
import VisNetworkGraph from "react-vis-network-graph";
import { ACR, BASE_URL, EXCHANGE_VIEW, GENERATE_SCHEMATICS, HIGH_LEVEL_EXPORT } from '../../constant/urls';
import JobAssignDialog from '../Data-Freeze/JobAssignDialog';
import PickaJobModal from '../Data-Freeze/PickaJobModal';
import { BreadCrumbContext } from '../common/BreadCrumbContext';
import CircularProgressWithLabel from '../common/CircularProgressWithLabel';
import Tablepagination from '../common/Tablepagination';
import './Exchangecable.css';
import { data, displayLength, getComparator, handleDownloadPDF, handleExchangeRowColor, removeDuplicates, newexchangevalues, options, pages, rows, selectedRowsColor, setLocalStorage, handleRowSelection, handleSort, warnings, handleMucjCableData} from './Exchangecabledetails.util';
import ExchangecabledetailsFilter from './ExchangecabledetailsFilter';
import ViewDialog from './ViewDialog';
import CreateNewModal from '../Data-Freeze/CreateNewModal';
import MucjCableUpdateModal from '../Data-Freeze/MucjCableUpdateModal';
// assigning color for rows based on solution values 
export const solutionColor = (sol) => {
    switch (parseInt(sol)) {
        case 1:
            return '#7cbd7c';
        case 2:
            return '#ffff9a';
        case 3:
            return '#ffa500';
        case 4:
            return '#f7c8d1';
        case 5:
            return 'white';
        default:
            break;
    }
}

const Exchangecabledetails = ({ css, exch_sau, exchange }) => {
    const { accounts } = useMsal()


    const { setExchrecall, viewjobid, setSelectedrow, exchrecall, setjobdetails } = useContext(BreadCrumbContext);

    /****  snackbar close */
    const [snackMsg, setSnackMsg] = React.useState('');
    const [alertOpen, setAlertOpen] = React.useState(false);
    const [open, setOpen] = React.useState(true);
    const handleClose = () => {
        setAlertOpen(false);
        setOpen(false)
    };

    //to pass logged user params for apis

    let params = {
        logged_user: accounts[0]?.idTokenClaims.EIN
    }
    /**
     *
     * HCR Report download for  local
     */
    const [showLink, setShowLink] = React.useState(false);
    const [highResp, setHighResp] = React.useState(null);

    const [fileName, setFileName] = React.useState('HCR_REPORT')
    const [selectedRows, setSelectedRows] = useState([]);


    /**
           High Level code for prod env
    */
    const [perloading, setPerloading] = useState(false)

    // to get the presigned url for high level report
    const highLevelExportApiCall = () => {
        let cableList = [];
        let queryStr = `${BASE_URL}/${ACR}/${HIGH_LEVEL_EXPORT}?exchange=${exchange}&sau=${exch_sau}&cables=`;

        if (selectedRows.length > 0 && selectedRows.length <= 10) {
            selectedRows.forEach((row) => {
                cableList.push(row['RecoveryCable']);
            });
            queryStr = `${queryStr}${cableList.join()}`
        }
        else if (selectedRows.length > 10) {
            setSnackMsg("Please select cables less than or equals to 10 and proceed.");
            setAlertOpen(true);
        }
        if (selectedRows.length <= 10) {
            setOpen(true);
            setPerloading(true);
            axios.get(queryStr, { params: params }).then((response) => {

                // eslint-disable-next-line eqeqeq
                if (response.status == "Fail" && response.Status_code === 200) {
                    setPerloading(false);
                    setShowLink(false);
                }
                else {
                    let url = response.data['presigned_url'];
                    let file_name = response.data['fileName'];
                    setFileName(file_name);
                    setHighResp(url);
                    setShowLink(true);
                    setPerloading(false);
                    setOpen(false)
                }
            }).catch((error) => {
                console.error(error);
                setPerloading(false);
                setOpen(false);
                setSnackMsg('Something went wrong!.Please try again!');
                setAlertOpen(true);

            });
        }
    }

    // downloading the high level report using presign url
    const onDownload = () => {
        setPerloading(true);
        axios.get(highResp, { responseType: 'blob' }).then((response) => {
            (response.data) && (() => {
                const url = URL.createObjectURL(response.data) //<---- this should be data.data
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${fileName}.xlsx`);
                link.click();
                setPerloading(false);
                setShowLink(false)
                setSelectedRows([])
            })()
        }).catch((error) => {
            setPerloading(false);
            console.error(error);
            setSnackMsg('Something went wrong!.Please try again!');
            setAlertOpen(true);
        });

    };


    const highLevelExportClick = (event) => {
        event.preventDefault();
        highLevelExportApiCall();

    }


    /**
    * using axios 
    * api calls for table data
    */
    const [tableData, setTableData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [noSolution, setNoSolution] = useState([])
    const [search, setSearch] = useState('');

    const url1 = `${BASE_URL}/${ACR}/${EXCHANGE_VIEW}?district=${css}&exchname=${exchange}&sau=${exch_sau}`;
    useEffect(() => {
        handletabledata();
        // eslint-disable-next-line
    }, [])
    const [rowjob_id, setRowjob_id] = useState('');



    // axios call for exchange table data
    const handletabledata = async () => {
        await setExchrecall(false)
        axios.get(url1, { params: params })
            .then((res) => {
                const data = res.data.Data

                setTableData(data);
                const arr = data.map((q) => q.Solution);
                const row = data.filter((item) => item.job_id === viewjobid);
                setRowjob_id(row);
                setSelectedrow(row);
                setNoSolution(removeDuplicates(arr));
                setLoading(false);
                viewjobid && setPage(0);
                // if (viewjobid) {
                //     setPage(0);
                // }
            })
            .catch((error) => {
                // console.error(error);
                setSnackMsg('Something went wrong!.Please try again!');
                setAlertOpen(true);
                setLoading(false)
            })
    }

    if (exchrecall === true) {
        handletabledata()
    }


    // to assign a job for a
    const [assignopen, setAssignopen] = useState(false);
    const [pickassignopen, setPickassignopen] = useState(false);

    const handlenewexchangevalues = async (sau, RecoveryCable, exchange, Uservalue, pick) => {
        newexchangevalues(sau, RecoveryCable, exchange, Uservalue, pick, css, setTableData, params, setSnackMsg, setAlertOpen, setjobdetails, setPickassignopen, setAssignopen)
    }

    /**
    * using axios 
    * api calls for warning data
    */
    const [Warningopen, setWarningOpen] = useState(false);
    const [hazardData, setHazardData] = useState([]);
    const [hazardCount, setHazardCount] = useState([]);





    const isSelected = (row) => selectedRows.indexOf(row) !== -1;

    //table pagination
    const storagePage = parseInt(localStorage.getItem("exchangepage"));


    const initialPage = pages(storagePage)
    const [page, setPage] = useState(initialPage);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('column');


    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }

    // selecting the solution for filter in table
    let list = JSON.parse(localStorage.getItem('checkedList'));

    const tonne = () => {
        if (list?.[0]?.excludeTone === false) { return list?.[0]?.excludeTone } else { return true }
    }

    const eodps = () => {
        if (list?.[0]?.excludeEODP === false) { return list?.[0]?.excludeEODP } else { return true }
    }

    const potentialNet = () => {
        if (list?.[0]?.netPotential === false) { return list?.[0]?.netPotential } else { return true }
    }

    const solution = () => {
        if (list?.[0]?.selectedSolution) { return list?.[0]?.selectedSolution } else { return [true] }
    }
    const [tonnechecked, setTonnechecked] = useState(tonne());
    const [eodpschecked, setEodpschecked] = useState(eodps());
    const [potentialNetchecked, setPotentialNetchecked] = useState(potentialNet());
    const [selectedSolution, setSelectedSolution] = useState(solution());

    useEffect(() => {
        const a = selectedSolution?.filter((values) => !noSolution.includes(values))
        setSelectedSolution(a)
        // eslint-disable-next-line
    }, [noSolution])

    useEffect(() => {
        setLocalStorage(eodpschecked, potentialNetchecked, selectedSolution, tonnechecked);
        // eslint-disable-next-line
    }, [eodpschecked, potentialNetchecked, tonnechecked, selectedSolution]);

    // filtering data based on search, potentialNetchecked,tonnechecked,eodpschecked,selectedSolution, sorteddata
    const filteredRows = useMemo(() => {

        return tableData?.filter((row) => {
            return (
                (`${row.RecoveryCable}`.toLowerCase()?.includes(search.toLowerCase())) &&
                (!potentialNetchecked || row.PotentialNet >= 1000) &&
                (!tonnechecked || row.Tonnage > 1) &&
                (!eodpschecked || row.E0DPs <= 0) &&
                (!selectedSolution?.includes(parseInt(row.Solution)))
            )
        })
        // eslint-disable-next-line
    }, [tableData, search, potentialNetchecked, tonnechecked, eodpschecked, selectedSolution, order])

    const displayall = !potentialNetchecked && !tonnechecked && !selectedSolution && !eodpschecked;


    const tableDataArr = data(displayall, tableData, filteredRows);
    const sortedarray = [...tableDataArr].sort(getComparator(order, orderBy));

    const tabledata = sortedarray;

    const selectedrows = rows()


    const [progress, setProgress] = React.useState(10);

    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
        }, 2000);
        return () => {
            clearInterval(timer);
        };
    }, []);


    const [nodes, setNodes] = React.useState([]);
    const [edges, setEdges] = React.useState([]);
    const componentRef = useRef()

    // axios call to get edges and nodes data 
    const getSchematics = () => {
        let cableList = [];
        let queryurl = `${BASE_URL}/${ACR}/${GENERATE_SCHEMATICS}?sau=${exch_sau}&cable_ids=`;

        if (selectedRows.length > 0 && selectedRows.length <= 1) {
            selectedRows.forEach((row) => {
                cableList.push(row['RecoveryCable']);
            });
            queryurl = `${queryurl}${cableList.join()}`
        }
        (selectedRows.length > 1) && (() => {
            setSnackMsg("Please select one cable at a time.");
            setAlertOpen(true);
            setOpenschematics(false);
        })()

        if (selectedRows.length <= 1) {

            setLoading(true);
            axios.get(queryurl,)
                .then((res) => {
                    if (res.data.status === "Fail" && res.data.Status_code === 200) {
                        setAlertOpen(true);
                        setSnackMsg('Something went wrong!.Please try again!');
                        setOpenschematics(false);
                    }
                    res.data && (() => {
                        const nodesdata = res.data.nodes
                        const edgedata = res.data.edges;
                        // on hover edges popup styling
                        const edgesdata = edgedata.map((item) => ({
                            from: item.from,
                            to: item.to,
                            title: `
                        <span style={{fontSize:'4px'}}><b>MEASURED_LENGTH</b></span>: ${item.title.measured_length},
                        <br><span style={{fontSize:'4px'}}><b>CABLE_SIZE</b></span>: ${item.title.cable_size},
                        <br><span style={{fontSize:'4px'}}><b>CABLE_WEIGHT</b></span>: ${item.title.cable_weigth},
                        <br><span style={{fontSize:'4px'}}><b>WORKING</b></span>: ${item.title.working},
                        <br><span style={{fontSize:'4px'}}><b>FAULTY</b></span>: ${item.title.faulty},
                        <br><span style={{fontSize:'4px'}}><b>SPARES</b></span>: ${item.title.spares}
                        <br><span style={{fontSize:'4px'}}><b>OTHERS</b></span>: ${item.title.others},`
                        }))
                        setNodes(nodesdata)
                        setEdges(edgesdata)
                        setLoading(false);
                        setSelectedRows([])
                    })()
                })
                .catch((error) => {
                    console.error(error)
                    setLoading(false);
                    setOpen(false);
                    setAlertOpen(true);
                    setOpenschematics(false);
                    setSnackMsg('Something went wrong!.Please try again!');
                })
        }
    }

    const graph = {
        nodes: nodes,
        edges: edges
    }

    const [openschematics, setOpenschematics] = useState(false);
    const [option, setOption] = useState(null);
    const generateschematics = (event) => {
        event.preventDefault();
        setOpenschematics(true);
        getSchematics();
        setNodes(false);
        setEdges(false);
        setOption(options)
    }

    //state variable for Switch
    const [checked, setChecked] = React.useState(false);

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };
    const [openModal, setOpenModal] = useState(false);
    const [updateFields, setUpdateFields] = useState({
        CABLE: '',
        CABLE_WEIGHT: '',
        CABLE_LENGTH: '',
        CABLE_GAUGE: ''
    });

    const handleCloseUpdateModal = () => setOpenModal(false);

const handleOpenUpdateModal = () => {
    if (selectedMUCJRows.length > 0) {
        const selectedRow = selectedMUCJRows[0]; 
        setUpdateFields({
            JOB_ID:selectedRow.JOB_ID|| '',
            USER_EIN: selectedRow.USER_EIN || '',
            CABLE: selectedRow.CABLE || '',
            CABLE_WEIGHT: selectedRow.CABLE_WEIGHT || '',
            CABLE_LENGTH: selectedRow.CABLE_LENGTH|| '',
            CABLE_GAUGE: selectedRow.CABLE_GAUGE || '',
        });
        setOpenModal(true);
    }
};

   
    // const [selectedMucjRows, setSelectedMucjRows] = useState([]);
    const handleMucjRowSelection = (row) => {
        setSelectedMUCJRows((prevSelectedRows) => {

            const isSelected = prevSelectedRows.some(
                (selectedMUCJRows) => selectedMUCJRows.crRef === row.crRef
            );

            if (isSelected) {
                setSelectedMUCJRows(null);
                return [];
            } else {
                setSelectedMUCJRows(row);
                return [row];
            }
        });
    };

       //MUCJ table API url and state variable
    const [MUCJTableData, setMUCJTableData] = React.useState([]);
    //const [MUCJRowjob_id, setMUCJRowjob_id] = React.useState([]);
    const [selectedMUCJRows, setSelectedMUCJRows] = React.useState([]);
    //const [MUCJNoSolution, setMUCJNoSolution] = useState([]);

    const url2 = `${BASE_URL}/${ACR}/get_mucj_cable`;

    useEffect(() => {
        handleMucjCableData(params,url2,setMUCJTableData,viewjobid,setSelectedMUCJRows,setLoading,setSnackMsg,setAlertOpen);
    }, [])

    //axios call MUCJ table data

    return (
        <>
            {loading &&
                <Box sx={{ width: '100%' }}>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={open}
                        onClick={handleClose}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </Box>
            }

            {perloading &&
                <Box sx={{ width: '100%' }}>
                    <Backdrop
                        sx={{ color: '#fff', display: "flex", justifyContent: "center", alignItems: "center", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={open}
                        onClick={handleClose}
                    >
                        <CircularProgressWithLabel value={progress} />
                    </Backdrop>
                </Box>
            }

            <Snackbar open={alertOpen} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                    {snackMsg}
                </Alert>
            </Snackbar>
            <ExchangecabledetailsFilter setPotentialNetchecked={setPotentialNetchecked} potentialNetchecked={potentialNetchecked} setPage={setPage} eodpschecked={eodpschecked} setEodpschecked={setEodpschecked} setTonnechecked={setTonnechecked} tonnechecked={tonnechecked} selectedSolution={selectedSolution} noSolution={noSolution} setSelectedSolution={setSelectedSolution} />

            <Card id="exchange-card" style={{ minWidth: 275, marginTop: "10px" }}>

                <CardHeader id="exchange-cardheader" sx={{ background: 'linear-gradient(to bottom,  #142032 ,#2e3a4c)', padding: 0.5 }}
                    titleTypographyProps={{ fontSize: 16, fontWeight: 'bold', color: 'white' }}
                    title="EXCHANGE CABLE DETAILS"
                />

                <CardContent id="exchange-cardcontent">
                    <h4 id="exchange-heading-name" style={{ display: "flex", justifyContent: "center", alignItems: "center", color: "#1976d2", marginTop: 0 }}>{css} ({exchange})</h4>

                    {/* cs */}
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <div id="ToggleSwitch_e-side" style={{ fontSize: "14px", marginTop: 4 }}>E-Side</div>
                        <Switch
                            checked={checked}
                            onChange={handleChange}
                            inputProps={{ 'aria-label': 'controlled',  }}
                            />
                         <div id="ToggleSwitch_mucj" style={{ fontSize: "14px", marginTop: 4 }}>MUCJ</div>
                     </div>
                                      
                <div>
              
                    {!checked ? 
                    <div>
                        <div id="exchange-rowsperpage" style={{ display: "flex", justifyContent: "space-between", flexDirection: 'row', marginBottom: "10px", marginTop: "-10px" }}>
                            <div style={{ display: "flex", alignItems: "flex-end" }}>
                                <span id="exchange-rowsperpagename" style={{ fontSize: "14px" }}>Rows per Page</span>
                                <select style={{ margin: "0 9px", fontSize: "14px" }} data-testid="exchange-rowsperchange" value={rowsPerPage} onChange={handleChangeRowsPerPage}>
                                {[5, 10, 25, 50, 100].map((option) => (
                                    <option id={"exchange-rowsperpage-" + option} key={option} value={option}>{option}</option>
                                ))}
                                </select>
                            </div>
                            <div style={{ display: "flex", alignItems: "flex-end", flexDirection: 'column', }}>

                                        {selectedRows.length === 0 ?
                                            <Button
                                                id="exchange-button-pick-a-job"
                                                style={{ color: "white", backgroundColor: "rgb(150, 150, 180)", width: "100px", height: "30px" }}
                                                disabled={true} >Pick a job</Button>
                                            :
                                            <PickaJobModal
                                                selectedRows={selectedRows}
                                                exch_sau={exch_sau}
                                                exchange={exchange}
                                                handlenewexchangevalues={handlenewexchangevalues}
                                                css={css} pickassignopen={pickassignopen}
                                                setPickassignopen={setPickassignopen}
                                            />}
                                        <TextField
                                            id="input-with-icon-textfield"
                                            label="Search"
                                            size="small"
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <SearchOutlinedIcon />
                                                    </InputAdornment>
                                                ),

                                            }}
                                            variant="standard"
                                            sx={{ marginTop: "2px" }}
                                            value={search}
                                            onChange={(e) => { setSearch(e.target.value); setPage(0) }}
                                        />
                                    </div>
                                </div>

                                <TableContainer id="exchange-tablecontainer" component={Paper} sx={{ position: "relative", maxHeight: "400px" }} data-testId="table-wrapper">
                                    <Table id="exchange-table">
                                        <TableHead id="exchange-tablehead" sx={{ top: 0, position: "sticky", zIndex: 1 }}>
                                            <TableRow id="exchange-tablerow" sx={{ backgroundColor: "#5488c7", color: "white", padding: "20px" }} data-testId={"row-css"} >

                                                <TableCell id="exchange-tablecell-checkbox" align='center' sx={{ color: "white", minWidth: "20px", border: "1px solid rgba(224, 224, 224, 1)" }}>
                                                    Select
                                                </TableCell>

                                                {headCells.map((headcell) => (
                                                    <TableCell id={"exchange-" + headcell.id} key={headcell.id} sx={{ color: "white", minWidth: "50px", maxWidth: headcell.width, border: "1px solid rgba(224, 224, 224, 1)", fontSize: "12px", fontWeight: "bold", padding: headcell.padding, overflow: 'hidden', flex: 1 }}>
                                                        <TableSortLabel
                                                            active={orderBy === headcell.id}
                                                            direction={orderBy === headcell.id ? order : 'asc'}
                                                            onClick={handleSort(headcell.id, orderBy, order, setOrder, setOrderBy)}
                                                        >
                                                            {headcell.label}
                                                        </TableSortLabel>
                                                    </TableCell>
                                                ))}

                                            </TableRow>

                                        </TableHead>
                                        {
                                            <TableBody id="exchange-tablebody" >
                                                {tabledata?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                                                    <TableRow id={"exchange-tablerow-" + row.RecoveryCable} key={row.RecoveryCable} >
                                                        <TableCell id="exchange-checkbox-row" sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "1px", backgroundColor: handleExchangeRowColor(row.RecoveryCable, row.Solution, row.job_id, viewjobid, selectedrows, exch_sau, solutionColor, rowjob_id) }} align='center'>
                                                            <Checkbox id={"exchange-checkbox-row-" + row.RecoveryCable + "-" + exch_sau} data-testid={`exchange-checkbox-row-${row.RecoveryCable}`} checked={isSelected(row)} onClick={() => handleRowSelection(row, selectedRows, setSelectedRows, setOpenschematics)} />
                                                        </TableCell>
                                                        <TableCell id={"exchange-Exch1141-" + row.Exch1141 + "-" + row.RecoveryCable} sx={{
                                                            border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "1px", backgroundColor: handleExchangeRowColor(row.RecoveryCable, row.Solution, row.job_id, viewjobid, selectedrows, exch_sau, solutionColor, rowjob_id)
                                                        }} align='center'>{row.Exch1141}</TableCell>
                                                        <TableCell id={"exchange-RecoveryCable-" + row.Exch1141 + "-" + row.RecoveryCable} sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "1px", backgroundColor: handleExchangeRowColor(row.RecoveryCable, row.Solution, row.job_id, viewjobid, selectedrows, exch_sau, solutionColor, rowjob_id) }} align='center'>{row.RecoveryCable}</TableCell>
                                                        <TableCell id={"exchange-Cabinets-" + row.Exch1141 + "-" + row.RecoveryCable} sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "1px", backgroundColor: handleExchangeRowColor(row.RecoveryCable, row.Solution, row.job_id, viewjobid, selectedrows, exch_sau, solutionColor, rowjob_id) }} align='center'>{row.Cabinets}</TableCell>
                                                        <TableCell id={"exchange-Diverts-" + row.Exch1141 + "-" + row.RecoveryCable} sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "1px", backgroundColor: handleExchangeRowColor(row.RecoveryCable, row.Solution, row.job_id, viewjobid, selectedrows, exch_sau, solutionColor, rowjob_id) }} align='center'>{row.Diverts}</TableCell>
                                                        <TableCell id={"exchange-Distance-" + row.Exch1141 + "-" + row.RecoveryCable} sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "1px", backgroundColor: handleExchangeRowColor(row.RecoveryCable, row.Solution, row.job_id, viewjobid, selectedrows, exch_sau, solutionColor, rowjob_id) }} align='center'>{row.Distance}</TableCell>
                                                        <TableCell id={"exchange-Tonnage-" + row.Exch1141 + "-" + row.RecoveryCable} sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "1px", backgroundColor: handleExchangeRowColor(row.RecoveryCable, row.Solution, row.job_id, viewjobid, selectedrows, exch_sau, solutionColor, rowjob_id) }} align='center'>{row.Tonnage}</TableCell>
                                                        <TableCell id={"exchange-PotentialNet-" + row.Exch1141 + "-" + row.RecoveryCable} sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "1px", backgroundColor: handleExchangeRowColor(row.RecoveryCable, row.Solution, row.job_id, viewjobid, selectedrows, exch_sau, solutionColor, rowjob_id) }} align='center'>{row.PotentialNet}</TableCell>
                                                        <TableCell id={"exchange-Category-" + row.Exch1141 + "-" + row.RecoveryCable} sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "1px", backgroundColor: handleExchangeRowColor(row.RecoveryCable, row.Solution, row.job_id, viewjobid, selectedrows, exch_sau, solutionColor, rowjob_id) }} align='center'>{row.Category}</TableCell>
                                                        <TableCell id={"exchange-Solution-" + row.Exch1141 + "-" + row.RecoveryCable}
                                                            sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "1px", backgroundColor: handleExchangeRowColor(row.RecoveryCable, row.Solution, row.job_id, viewjobid, selectedrows, exch_sau, solutionColor, rowjob_id) }}
                                                            align='center'>{row.Solution}</TableCell>
                                                        <TableCell id={"exchange-viewcable-" + row.Exch1141 + "-" + row.RecoveryCable + row.Exch1141 + row.RecoveryCable} sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "1px 2px", backgroundColor: handleExchangeRowColor(row.RecoveryCable, row.Solution, row.job_id, viewjobid, selectedrows, exch_sau, solutionColor, rowjob_id) }} align='center'>
                                                            {row.user_name === '_' ? <JobAssignDialog
                                                                sau={exch_sau}
                                                                assignopen={assignopen}
                                                                RecoveryCable={row.RecoveryCable}
                                                                exchange={exchange}
                                                                handlenewexchangevalues={handlenewexchangevalues}
                                                                cabinet={row.Cabinets}
                                                                job_id={row.job_id}
                                                                css={css}
                                                                job_owner={row.ein}
                                                                cable_id={row.RecoveryCable} /> : <ViewDialog sau={exch_sau} RecoveryCable={row.RecoveryCable} exchange={exchange} job_id={row.job_id} job_owner={row.ein} css={css} cable_id={row.RecoveryCable} />}
                                                        </TableCell>

                                                        <TableCell id={"exchange-warnings-" + row.Exch1141 + "-" + row.RecoveryCable} sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "12px 1px", backgroundColor: handleExchangeRowColor(row.RecoveryCable, row.Solution, row.job_id, viewjobid, selectedrows, exch_sau, solutionColor, rowjob_id) }} align='center'>
                                                            {warnings(row, setHazardCount, setLoading, setWarningOpen, setSnackMsg, setAlertOpen, Link, exch_sau, setHazardData, params)}

                                                        </TableCell>

                                                        <TableCell id={"exchange-viewcable" + row.Exch1141 + "-" + row.RecoveryCable} sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "1px", backgroundColor: handleExchangeRowColor(row.RecoveryCable, row.Solution, row.job_id, viewjobid, selectedrows, exch_sau, solutionColor, rowjob_id) }} align='center'>
                                                            {row.user_name}
                                                        </TableCell>
                                                    </TableRow>
                                                ))
                                                }
                                            </TableBody>
                                        }
                                    </Table>
                                </TableContainer>


                                <Tablepagination
                                    rowsPerPageOptions={[]}
                                    setPage={setPage}
                                    page={page}
                                    count={displayLength(displayall, tableData, filteredRows)}
                                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                                    rowsPerPage={rowsPerPage}
                                />

                            </div>
                            :
                            <div>
                                <div style={{ display: "flex", justifyContent: "flex-end", flexDirection: 'row', }}> 
                                    <Button variant="outlined" data-testid="exchange-button-update"  onClick={handleOpenUpdateModal}  style={{ color:"white", backgroundColor: selectedMUCJRows.length > 0 ? "#142032" : "rgb(153, 153, 174)", marginRight: "10px", marginBottom: "10px", fontSize:"12",height:"30px"}} disabled={selectedMUCJRows.length === 0}> UPDATE</Button>
                                     <MucjCableUpdateModal openModal={openModal} handleCloseUpdateModal={handleCloseUpdateModal} selectedRow={selectedMUCJRows[0]} setAlertOpen={setAlertOpen}setSnackMsg={setSnackMsg}setOpenModal={setOpenModal} setUpdateFields={setUpdateFields} updateFields={updateFields}params={params}/>
                                    <Button variant="outlined" style={{ color:  "white", backgroundColor: selectedMUCJRows.length > 0 ? "#142032" : "rgb(153, 153, 174)", marginRight: "10px", marginBottom: "10px",fontSize:"12",height:"30px" }} disabled={selectedMUCJRows.length === 0}>DELETE</Button>
                                   <CreateNewModal 
          setSnackMsg={setSnackMsg} 
          setAlertOpen={setAlertOpen} 
          
          sau={exch_sau} exchange={exchange}
        />
                                </div>
   
                                <>
                                    <div>
                                        <TableContainer data-testid = "table-container" component={Paper}>
                                            <Table>
                                                <TableHead>
                                                    <TableRow id="mucj-exchange-rows" sx={{ backgroundColor: "#5488c7", color: "white", padding: "20px" }}>
                                                        <TableCell
                                                            id="exchange-tablecell-checkbox"
                                                            align="center"
                                                            sx={{ color: "white", minWidth: "20px", border: "1px solid rgba(224, 224, 224, 1)" }}
                                                        >
                                                            Select
                                                        </TableCell>

                                                        {mucjHeadCells.map((mucjHeadCell) => (
                                                            <TableCell
                                                                id={"exchange-" + mucjHeadCell.id}
                                                                key={mucjHeadCell.id}
                                                                sx={{
                                                                    color: "white",
                                                                    minWidth: "50px",
                                                                    maxWidth: mucjHeadCell.width,
                                                                    border: "1px solid rgba(224, 224, 224, 1)",
                                                                    fontSize: "12px",
                                                                    fontWeight: "bold",
                                                                    padding: mucjHeadCell.padding,
                                                                    overflow: "hidden",
                                                                    flex: 1,
                                                                }}
                                                            >
                                                                <TableSortLabel
                                                                    active={orderBy === mucjHeadCell.id}
                                                                    direction={orderBy === mucjHeadCell.id ? order : "asc"}
                                                                    onClick={handleSort(mucjHeadCell.id, orderBy, order, setOrder, setOrderBy)}
                                                                >
                                                                    {mucjHeadCell.label}
                                                                </TableSortLabel>
                                                            </TableCell>
                                                        ))}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {MUCJTableData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                                                        <TableRow key={row.crRef}>
                                                            <TableCell>
                                                                <Checkbox
                                                                    id={`mucj-checkbox-row-${row.crRef}`}
                                                                    checked={selectedMUCJRows.some((selectedRow) => selectedRow.crRef === row.crRef)}
                                                                    onChange={() => handleMucjRowSelection(row)}
                                                                />
                                                            </TableCell>
                                                            <TableCell>{row.sau}</TableCell>
                                                            <TableCell>{row.exchange}</TableCell>
                                                            <TableCell>{row.CABLE}</TableCell>
                                                            <TableCell>{row.CABLE_WEIGHT}</TableCell>
                                                            <TableCell>{row.CABLE_LENGTH}</TableCell>
                                                            <TableCell>{row.CABLE_GAUGE}</TableCell>
                                                            <TableCell>{row.solution}</TableCell>
                                                            <TableCell>{row.crRef}</TableCell>
                                                            <TableCell>{row.createdDate}</TableCell>
                                                            <TableCell>{row.creatorName}</TableCell>
                                                            <TableCell>{row.creatorEin}</TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </>

                            </div>
                        }

                    </div>
                </CardContent>

                <div key={selectedRows.length} style={{ display: "flex", justifyContent: "flex-end", margin: "0px 15px 15px 0" }}>
                    {showLink &&
                        <div  >
                            <Link
                                id="exchange-download-High-Level-Report"
                                variant="body2"
                                underline='hover'
                                onClick={onDownload}
                                data-testid="exchange-download-High-Level-Report"
                                sx={{ fontSize: 18, color: 'red', '&:hover': { cursor: 'pointer' } }}
                            >
                                Click here to download the High Level Report
                            </Link>
                        </div>

                    }
                    <Button id="exchange-button-highlevel-export" data-testid="exchange-button-highlevel-export" style={{ color: "white", backgroundColor: selectedRowsColor(selectedRows), width: "145px", height: "30px", marginLeft: "5px", fontSize: 12 }} onClick={highLevelExportClick} disabled={!selectedRows.length}>Highlevel Export</Button>
                    <Button id="exchange-button-schematics" data-testid="exchange-button-generate-schematics" style={{ color: "white", backgroundColor: selectedRowsColor(selectedRows), width: "169px;", height: "30px", marginLeft: "5px", fontSize: 12 }} onClick={generateschematics} disabled={!selectedRows.length}>Generate Schematics</Button>                </div>
                {(hazardData && hazardCount) ? <WarningModal hazardData={hazardData} hazardCount={hazardCount} Warningopen={Warningopen} setWarningOpen={setWarningOpen} /> : <><Snackbar open={alertOpen} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                    <Alert onClose={handleClose} id="alert" severity="error" sx={{ width: '100%' }}>
                        {snackMsg}
                    </Alert>
                </Snackbar></>}
            </Card>
            {openschematics && nodes && edges ? <Card >
                <CardContent>
                    <Button onClick={() => handleDownloadPDF(componentRef)} id="schematics-button-download" style={{ color: "white", backgroundColor: "#142032", width: "60px", height: "30px", fontSize: 12 }}
                    ><DownloadIcon /></Button>
                    <div ref={componentRef}>
                        <VisNetworkGraph
                            graph={graph}
                            options={option}
                        />
                    </div>
                </CardContent>
            </Card> : null}
        </>
    )
}

export default Exchangecabledetails;

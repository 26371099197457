import React, { useState } from 'react';
import { Button, Dialog, DialogActions, Paper, DialogContent, TableBody, TableCell, DialogContentText, Modal, Box, TableContainer, Radio, Table, TableHead, TableRow } from '@mui/material';
import AssignedDialog from './AssignedDialog';
import { ACR, BASE_URL, HOST_CABLES } from '../../constant/urls';
import axios from 'axios';
import { useMsal } from '@azure/msal-react';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 850,
  height: "auto",
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 3,
};

export default function PickaJobModal({ selectedRows, exch_sau, exchange, handlenewexchangevalues, pickassignopen, setPickassignopen, css }) {
  const [opensummary, setOpenSummary] = useState(false);
  const [pick, setPick] = useState(true)
  const [selectedvalues, setSelectedvalues] = useState(1);
  const columns = ["Select/Unselect Cables to Offload", "Mark as Unviable", "Mark as Onhold"]
  const [pickajobopen, setPickajobopen] = useState(false);
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false)
  const [hostpopup, setHostpopup] = useState(false);
  const [hostmessage, setHostmessage] = useState("");
  const { accounts } = useMsal()

  //to pass logged user params for apis
  let params = {
    logged_user: accounts[0]?.idTokenClaims.EIN
  }

  // job assign
  const handlePickJob = () => {
    if (selectedRows?.length > 1) {
      setPickajobopen(true);
      setMessage("Please select a single cable to create a job.")
    }
    else if (selectedRows[0]?.user_name?.length > 0 && selectedRows[0]?.user_name !== "_") {
      setPickajobopen(true);
      setMessage("The cable is already assigned in a new job.Please select another cable.")
    }
    else {
      setOpen(true)
      const cable_id = selectedRows[0].RecoveryCable;
      const hostcablesurl = `${BASE_URL}/${ACR}/${HOST_CABLES}?sau=${exch_sau}&exchange=${exchange}&cable=${cable_id}`;
      axios.get(hostcablesurl, { params: params })
        .then((res) => {
          res.data.Status_code === 200 && res.data.status === "fail" && setHostpopup(false)
          // if (res.data.Status_code === 200 && res.data.status === "fail") {
          //   setHostpopup(false)

          // }
          res.data.Status_code === 200 && res.data.status === "Success" && (() => {
            setHostpopup(true)
            setHostmessage(res.data.MESSAGE)
          })()
          // else if (res.data.Status_code === 200 && res.data.status === "Success") {
          //   setHostpopup(true)
          //   setHostmessage(res.data.MESSAGE)
          // }
        })
    }
  }

  const handlepickClose = () => {
    setPickajobopen(false);
  }

  // table cells
  const handleClose = () => {
    setOpen(false);
  };

  const handleSummaryOpen = () => setOpenSummary(true);

  const handleSummaryClose = () => {
    setOpenSummary(false);
  };

  const handleCheckboxChange = (value) => {
    setSelectedvalues(value);
  }

  const values = {
    "sau": exch_sau,
    "cable": selectedRows[0]?.RecoveryCable,
    "exchange": exchange,
    "total_pcps_to_offload": selectedRows[0]?.Cabinets,
    "marked_as_unviable": false,
    "marked_as_onhold": false,
    "overall_status": "YET TO START"
  }
  // to theck user selected value
  // const getvalues = () => {
  //   // if (selectedvalues === 1) {
  //   //   return { ...values, ...{ marked_as_unviable: false, marked_as_onhold: false } }
  //   // }
  //   // else if (selectedvalues === 2) {
  //   //   return { ...values, ...{ marked_as_unviable: true, marked_as_onhold: false } }
  //   // }
  //   // else if (selectedvalues === 3) {
  //   //   return { ...values, ...{ marked_as_unviable: false, marked_as_onhold: true } }
  //   // }
  // }
  const getvalues = () => {
    const mapping = {
      1: { marked_as_unviable: false, marked_as_onhold: false },
      2: { marked_as_unviable: true, marked_as_onhold: false },
      3: { marked_as_unviable: false, marked_as_onhold: true }
    };
  
    return { ...values, ...mapping[selectedvalues] };
  };
  const getjsonValues = getvalues();

  const handleSelectOk = () => {
    handlenewexchangevalues(exch_sau, selectedRows[0]?.RecoveryCable, exchange, getjsonValues, pick);
    close()
  }
  const close = () => {
    handleSummaryClose();
    handleClose();
    setPickassignopen(true);
    setPick(true)
  }

  return (
    <div>
      <Button data-testid="exchange-button-pick-a-job" id="exchange-button-pick-a-job" style={{ color: "white", backgroundColor: "#142032", width: "100px", height: "30px" }} onClick={handlePickJob}>Pick a job</Button>

      <Dialog
        id="alert-dialog"
        open={pickajobopen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ marginTop: "-350px", marginLeft: "450px", width: "400px" }}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>

          <Button id="alert-dialog-button-dismiss" data-testid="alert-dialog-button-dismiss" style={{ color: "white", backgroundColor: "rgb(2, 2, 81)", margin: "0px 10px 9px", width: "100px", height: "30px", float: "right", fontSize: "12px" }} onClick={handlepickClose}>Dismiss</Button>
        </DialogActions>
      </Dialog>

      <div>
        {hostpopup ? <Dialog
          id="alert-dialog"
          open={open}
          data-testid="alert-dialog"
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          sx={{ marginTop: "-300px", marginLeft: "450px", width: "500px" }}
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description" sx={{ marginBottom: "-20px" }}>
              <div id="message" dangerouslySetInnerHTML={{ __html: hostmessage }} />
            </DialogContentText>
          </DialogContent>
          <DialogActions>

            <Button id="alert-dialog-button-Dismiss" style={{ color: "white", backgroundColor: "rgb(2, 2, 81)", margin: "0px 10px 9px", width: "100px", height: "30px", float: "right", fontSize: "12px" }} onClick={handleClose}>Dismiss</Button>

          </DialogActions>
        </Dialog>
          :
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{ marginTop: "-350px", marginLeft: "450px", width: "400px" }}
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Do you want to proceed with assigning new job?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button style={{ color: "white", backgroundColor: "rgb(2, 2, 81)", margin: "0px 10px 9px", width: "auto", height: "30px", float: "right", fontSize: "12px" }} data-testid="summary-open-button" onClick={handleSummaryOpen}>Yes</Button>

              {opensummary && <div>
                <Modal
                  open={open}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                  sx={{ marginTop: "-200px" }}
                >
                  <Box sx={style}>
                    <p style={{ fontSize: "16px", textAlign: "center", fontWeight: "500", marginBottom: "5px" }}>SELECTION SUMMARY</p>
                    <TableContainer component={Paper}>
                      <Table >

                        <TableHead>
                          <TableRow sx={{ backgroundColor: "#5488c7", color: "white" }}>
                            {columns.map((headcell) => (
                              <TableCell key={headcell} align='center' sx={{ color: "white", minWidth: "50px", margin: 0, padding: "4px", border: "1px solid rgba(224, 224, 224, 1)", fontSize: "13px" }}>
                                {headcell}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>

                        <TableBody >

                          <TableRow >
                            {[1, 2, 3].map((value) => (
                              <TableCell sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "1px" }} align='left' key={value}> <Radio checked={selectedvalues === value} inputProps={{ "data-testid": `radio-button-${value}` }} onChange={() => handleCheckboxChange(value)} />{selectedRows[0]?.RecoveryCable}</TableCell>
                            ))}
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <p>Do you want to proceed with selection?</p>
                    <Button style={{ color: "white", backgroundColor: "rgb(2, 2, 81)", marginTop: "30px", marginRight: "10px", width: "100px", height: "30px", float: "right" }} data-testid="select-cancel" onClick={() => setOpenSummary(false)}>Cancel</Button>
                    <Button style={{ color: "white", backgroundColor: "rgb(2, 2, 81)", marginTop: "30px", marginRight: "10px", width: "100px", height: "30px", float: "right" }} data-testid="select-ok" onClick={handleSelectOk}>OK</Button>
                  </Box>
                </Modal>
              </div>}

              <Button style={{ color: "white", backgroundColor: "rgb(2, 2, 81)", margin: "0px 10px 9px", width: "auto", height: "30px", float: "right", fontSize: "12px" }}
                data-testid="close-dialog"
                onClick={() => setOpen(false)}>No</Button>
            </DialogActions>
          </Dialog>}
        {pickassignopen ? <AssignedDialog assignopen={pickassignopen} setAssignopen={setPickassignopen} sau={exch_sau} RecoveryCable={selectedRows[0]?.RecoveryCable} exchange={exchange} pick={pick} css={css} /> : null}

      </div>

    </div>
  );
}
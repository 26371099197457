import React, { useState, useEffect, useContext } from 'react'
import {
  Box, Card, CardHeader, CardContent, Typography, MenuItem, FormControl, FormControlLabel, Select, Checkbox,
  Paper, Input, InputLabel, FormLabel, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Link, TableSortLabel, Snackbar, Alert, Grid, InputAdornment, CircularProgress, Backdrop
} from '@mui/material';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { BASE_URL, CARD_URL, DISTRICTS, ACR, HOME } from '../../constant/urls';
import { BreadCrumbContext } from '../common/BreadCrumbContext';
import ViewUser from '../Data-Freeze/ViewUser';
import { useMsal } from '@azure/msal-react';
import { getComparator, handlecolor, requestSearch } from './Home.util';
import Tablepagination from '../common/Tablepagination';
/**
*
* Home component
* @export
* @return {*} 
*/
export default function Home() {

  /** Loader Implementation */
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(true);

  /** Breadcrumb */
  const { pathList, setPathList, setJobdetailstable, exchsau } = useContext(BreadCrumbContext);
  const { accounts } = useMsal()


  useEffect(() => {
    setPathList(pathList?.filter((item) => item.pathname !== 'cable' && item.pathname !== 'jumper_schedule'));
    // eslint-disable-next-line
  }, [])

  const isSelected = (row) => {
    if (pathList.length) {
      let splitedCss = (pathList[0]?.search.split('?').filter((x) => x)[0]?.split('&')[0]?.split('='))[1];
      let splitedSau = (pathList[0]?.search.split('?').filter((x) => x)[0]?.split('&')[1]?.split('='))[1];
      let splitedExchange = (pathList[0]?.search.split('?').filter((x) => x)[0]?.split('&')[2]?.split('='))[1];
      return !!(splitedCss === row.css && splitedSau === row.exch_sau && splitedExchange === row.exchange);
    }
  }

  /** Snackbar */
  const [snackMsg, setSnackMsg] = React.useState('');
  const [alertOpen, setAlertOpen] = React.useState(false);
  const handleClose = () => {
    setAlertOpen(false);
  };

  /** Routing- Navgation */
  const navigate = useNavigate();

  /** Card variables */
  const [cardList, setCardList] = React.useState([]);

  /** select CSS*/
  const [cssList, setCssList] = React.useState([]);
  const [css, setCss] = React.useState({ css: 'All' });
  const handleCssChange = (event) => {
    setCss({ ...css, 'css': event.target.value });
  };

  /** Table data */
  const [tabledata, setTabledata] = React.useState([]);
  const [tabledataCopy, setTabledataCopy] = React.useState([]);

  /** Exclude Solution */
  const selectedSolutionvalue = () => {
    if (localStorage.getItem('checkedList') !== null) {
      return JSON.parse(localStorage.getItem('checkedList'))[0].selectedSolution
    }
    else {
      return [2, 3, 4, 5]
    }
  }
  const [selectedSolution, setSelectedSolution] = React.useState(selectedSolutionvalue());
  useEffect(() => {
    selectedSolutionvalue()
  }, [selectedSolution])
  const handleSolutionchange = (value) => {
    if (selectedSolution.includes(value)) {
      setSelectedSolution(selectedSolution.filter((item) => item !== value));

    } else {
      setSelectedSolution([...selectedSolution, value])
    }
  }

  /** Exclude Net potentioal */
  const netPotentialvalue = () => {
    if (localStorage.getItem('checkedList') !== null) {
      return JSON.parse(localStorage.getItem('checkedList'))[0].netPotential
    }
    else {
      return true
    }
  }
  const [netPotential, setNetPotential] = React.useState(netPotentialvalue());
  useEffect(() => {
    netPotentialvalue()
  }, [netPotential])
  const potentialText = "Exclude net Potential < £1k";

  /** Exclude EODP */
  const excludeEODPvalue = () => {
    //excludeEODPvalue
    if (localStorage.getItem('checkedList') !== null) {
      return JSON.parse(localStorage.getItem('checkedList'))[0].excludeEODP
    }
    else {
      return true
    }
  }
  const [excludeEODP, setExcludeEODP] = React.useState(excludeEODPvalue());
  useEffect(() => {
    excludeEODPvalue()
  }, [excludeEODP])

  /** Exclude Tone */
  const tonevalue = () => {
    // tonevalue
    if (localStorage.getItem('checkedList') !== null) {
      return JSON.parse(localStorage.getItem('checkedList'))[0].excludeTone
    }
    else {
      return true
    }
  }
  const [excludeTone, setExcludeTone] = React.useState(tonevalue());
  useEffect(() => {
    tonevalue()
  }, [excludeTone])
  const excludeToneText = "Exclude tonnes < 1"

  /**
   *
   * for retaining the filter
   */
  const setLocalStorage = () => {
    const check = [{ excludeEODP: excludeEODP, netPotential: netPotential, selectedSolution: selectedSolution, excludeTone: excludeTone }]
    localStorage.setItem('checkedList', JSON.stringify(check));
  }

  useEffect(() => {
    if (pathList.length) {
      const list = JSON.parse(localStorage.getItem('checkedList'));
      list?.length && (() => {
        setSelectedSolution(list[0]?.selectedSolution ? list[0]?.selectedSolution : [2, 3, 4, 5]);
        setNetPotential(list[0]?.netPotential === false ? list[0]?.netPotential : true);
        setExcludeEODP(list[0]?.excludeEODP === false ? list[0]?.excludeEODP : true)
        setExcludeTone(list[0]?.excludeTone === false ? list[0]?.excludeTone : true)
      })()
    }
    // eslint-disable-next-line
  }, []);

  /** Pagination */
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  /** Search */
  const [search, setSearch] = useState('');



  /** Table Header cells*/
  const headCells = [
    {
      id: 'css',
      numeric: false,
      disablePadding: true,
      label: 'CSS',
      width: '10%'
    },
    {
      id: 'exch_sau',
      numeric: false,
      disablePadding: true,
      label: 'Exch SAU',
      width: '9%'
    },
    {
      id: 'exchange',
      numeric: false,
      disablePadding: true,
      label: 'Exchange',
      width: 95
    },
    {
      id: 'recovery_cables',
      numeric: false,
      disablePadding: true,
      label: 'Recovery Cables',
      width: 130
    },
    {
      id: 'distance',
      numeric: false,
      disablePadding: true,
      label: 'Distance',
      width: 90
    },
    {
      id: 'tonnage',
      numeric: false,
      disablePadding: true,
      label: 'Tonnage',
      width: 90
    },
    {
      id: 'priority',
      numeric: false,
      disablePadding: true,
      label: 'Priority',
      width: 90
    },
    {
      id: 'potential_net',
      numeric: false,
      disablePadding: true,
      label: 'Potential Net',
      width: '5%'
    },
    {
      id: 'user_details',
      numeric: false,
      disablePadding: true,
      label: 'View User Details',
      width: '12%'
    },
    {
      id: 'view_exchange',
      numeric: false,
      disablePadding: true,
      label: 'View Exchange',
      width: '9%'
    }];


  /** Table Sorting */
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('exch_sau');
  const handleSort = (property) => (event) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)

  }


  /**
   * using axios 
   * api calls 
   */
  const getHomeDetails = () => {
    let params = {
      select_district: css['css'],
      exclude_solution: selectedSolution.toString(),
      exclude_negative_potential: (netPotential.toString()).charAt(0).toUpperCase() + (netPotential.toString()).slice(1),
      exclude_eodps: (excludeEODP.toString()).charAt(0).toUpperCase() + (excludeEODP.toString()).slice(1),
      exclude_tonnes: (excludeTone.toString()).charAt(0).toUpperCase() + (excludeTone.toString()).slice(1),
      logged_user: accounts[0]?.idTokenClaims.EIN
    }


    axios.get(`${BASE_URL}/${ACR}${CARD_URL}`, { params: params }).then((response) => {
      setOpen(true);
      setLoading(true);
      setPage(0);
      setCardList(response?.data?.results);
      setLoading(false);
      setOpen(false);
    }).catch((error) => {
      console.error(error);
      setLoading(false);
      setOpen(false);
      setSnackMsg('Something went wrong!.Please try again!');
      setAlertOpen(true);

    });

    axios.get(`${BASE_URL}/${ACR}${HOME}`, { params: params }).then((tableResponse) => {
      setOpen(true);
      setLoading(true);
      const tabledata = tableResponse?.data?.results;
      search === "" && setTabledata(tabledata);
      search === "" && setTabledataCopy(tabledata);
      // if (search === "") {
      //   setTabledata(tabledata);
      //   setTabledataCopy(tabledata);
      // }
      if (exchsau) {
        setSearch(exchsau);
        const filteredRows = tabledata.filter((row) => {
          return (row.exch_sau.toLowerCase().includes(exchsau.toLowerCase()) || row.exchange.toLowerCase().includes(exchsau.toLowerCase()));
        });
        setTabledata(filteredRows);
      }
      setLoading(false);
      setOpen(false);


    }).catch((error) => {
      console.error(error);
      setLoading(false);
      setOpen(false);
      setSnackMsg('Something went wrong!.Please try again!');
      setAlertOpen(true);
    });

  };


  const getCssList = async () => {
    setLoading(true);
    setOpen(true);

    const params = {
      logged_user: accounts[0]?.idTokenClaims.EIN
    }
    await axios.get(`${BASE_URL}/${ACR}${DISTRICTS}`, { params: params }).then((cssResponse) => {
      setCssList(cssResponse?.data?.results);
      setLoading(false);
      setOpen(false);
    }).catch((error) => {
      console.error(error);
      setSnackMsg('Something went wrong!.Please try again!');
      setAlertOpen(true);
      setLoading(false);
      setOpen(false);
    });
  }


  useEffect(() => {
    getHomeDetails();
    getCssList();
    // eslint-disable-next-line
  }, []);


  /**
   *
   * apply filter
   * @param {*} event
   */
  const applyFilter = (event) => {
    event.preventDefault();
    getHomeDetails();
    setSearch('');
  };
  // const handlecolor = (row) => {
  //   if (row.exchange === exchsau) { return "rgb(222, 184, 135)" } else { return null }
  // }

  return (
    <>
      {loading &&
        <Box sx={{ width: '100%' }}>
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
            onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </Box>
      }
      <Snackbar open={alertOpen} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          {snackMsg}
        </Alert>
      </Snackbar>
      <Box sx={{ mt: 1 }}>
        <Grid container >
          <Grid item md lg>
            <Card id="cable_card" sx={{ width: 195, height: '75%', background: 'linear-gradient(to bottom, #142032, #2e3a4c)' }}>
              <CardContent id="cable_identified_card">
                <div style={{ display: "flex", flexDirection: 'column', justifyContent: "center", alignItems: 'center', alignContent: 'center' }}>
                  <Typography sx={{ textAlign: 'center', fontSize: 13, fontWeight: 'bold', color: 'white', }}>
                    Cable Identified
                  </Typography>
                  <Typography sx={{ textAlign: 'center', fontSize: 13, fontWeight: 'bold', color: 'white' }}>
                    {cardList[0]?.['cables_identified']}
                  </Typography>
                </div>
              </CardContent>
            </Card>
          </Grid>
          <Grid item md lg>
            <Card id="exchanges_card" sx={{ width: 195, height: '78%', background: 'linear-gradient(to bottom,  #5488c7, #75a5e0)' }}>
              <CardContent id="no_exchanges_card">
                <div style={{ display: "flex", flexDirection: 'column', justifyContent: "center", alignItems: 'center', alignContent: 'center' }}>
                  <Typography sx={{ textAlign: 'center', fontSize: 13, fontWeight: 'bold', color: 'white', }}>
                    No. of Exchanges
                  </Typography>
                  <Typography sx={{ textAlign: 'center', fontSize: 13, fontWeight: 'bold', color: 'white', }}>
                    {cardList[0]?.['no_of_exchanges']}
                  </Typography>
                </div>
              </CardContent>

            </Card>
          </Grid>
          <Grid item md lg>
            <Card id="cable_length_card" sx={{ width: 195, height: '78%', background: 'linear-gradient(to bottom,  #43B072, #52be81)' }}>
              <CardContent id="total_cable_length_card">
                <div style={{ display: "flex", flexDirection: 'column', justifyContent: "center", alignItems: 'center', alignContent: 'center' }}>
                  <Typography sx={{ textAlign: 'center', fontSize: 13, fontWeight: 'bold', color: 'white', }}>
                    Total Cable Length (KMs)
                  </Typography>
                  <Typography sx={{ textAlign: 'center', fontSize: 13, fontWeight: 'bold', color: 'white', }}>
                    {cardList[0]?.['total_cable_length']}
                  </Typography>
                </div>
              </CardContent>
            </Card>
          </Grid>
          <Grid item md lg>
            <Card id="divert_pair_card" sx={{ width: 195, height: '78%', background: 'linear-gradient(to bottom,  #818387, #b8bbc0)' }}>
              <CardContent id="total_divert_pair_card">
                <div style={{ display: "flex", flexDirection: 'column', justifyContent: "center", alignItems: 'center', alignContent: 'center' }}>
                  <Typography sx={{ textAlign: 'center', fontSize: 13, fontWeight: 'bold', color: 'white', }}>
                    Total Divert Pairs
                  </Typography>
                  <Typography sx={{ textAlign: 'center', fontSize: 13, fontWeight: 'bold', color: 'white', }}>
                    {cardList[0]?.['total_divert_pairs']}
                  </Typography>
                </div>
              </CardContent>

            </Card>
          </Grid>
          <Grid item md lg>
            <Card id="tonnage_card" sx={{ width: 195, height: '78%', background: 'linear-gradient(to bottom,  #d0d0d0, #d1d1d1)' }}>
              <CardContent id="total_tonnage_card">
                <div style={{ display: "flex", flexDirection: 'column', justifyContent: "center", alignItems: 'center', alignContent: 'center' }}>
                  <Typography sx={{ textAlign: 'center', fontSize: 13, fontWeight: 'bold', color: 'white', }}>
                    Total Tonnage
                  </Typography>
                  <Typography sx={{ textAlign: 'center', fontSize: 13, fontWeight: 'bold', color: 'white', }}>
                    {cardList[0]?.['total_tonnage']}
                  </Typography>
                </div>
              </CardContent>

            </Card>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ mt: 0.5 }}>
        <Grid container>
          <Grid item xs={12} md>
            <Card id="apply_filters" sx={{ height: '95%' }}>
              <CardHeader id="apply_filters_header" sx={{ background: 'linear-gradient(to bottom,  #142032 ,#2e3a4c)', padding: 0.5 }}
                titleTypographyProps={{ fontSize: 16, fontWeight: 'bold', color: 'white' }}
                title="FILTERS"
              />
              <CardContent>
                <Box sx={{ width: '100%' }}>
                  <Grid container rowSpacing={12} columnSpacing={1} justifyContent='flex-start'>
                    <Grid item xs={2}>
                      <FormControl sx={{ m: 0, minWidth: 190, height: 10 }} size="small">
                        <InputLabel id="home-css" sx={{ fontSize: 12, fontWeight: 'bold' }} >Select District (CSS)</InputLabel>
                        <Select
                          labelId="home-css"
                          id="home-css-id"
                          sx={{ fontSize: 12, width: '110px' }}
                          value={css.css}
                          label="Select District (CSS)"
                          onChange={handleCssChange}
                        >
                          {cssList.map((cssVal, index) => (
                            <MenuItem
                              key={cssVal['css']}
                              value={cssVal['css']}
                              data-testid={`select-${cssVal['css']}`}
                              sx={{ fontSize: 12 }}
                            >
                              {cssVal['css']}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                    </Grid>
                    <Grid item xs={5}>
                      <div style={{ display: 'flex' }}>
                        <FormLabel sx={{ mt: '2%' }} id="demo-radio-buttons-exclude-solution">{<Typography sx={{ mt: '2%', fontSize: 12, fontWeight: 'bold', color: 'black' }}>Exclude Solution:</Typography>}</FormLabel>
                        {Array.from({ length: 5 }, (x, index) => (
                          <FormControlLabel
                            key={x} id={selectedSolution.includes(index + 1)}
                            control={<Checkbox size="small" id={"exclude_solution" + (index + 1)} data-testid={`demo-checkbox-buttons-exclude-solution-${index}`} checked={selectedSolution.includes(index + 1)} onChange={() => handleSolutionchange(index + 1)} />}
                            label={<Typography sx={{ fontSize: 12, fontWeight: 'bold' }}>{index + 1}</Typography>}
                          />
                        ))}
                      </div>
                    </Grid>
                    <Grid item xs={3.5}>
                      <FormControlLabel id="demo-radio-buttons-exclude-net-potential" control={< Checkbox checked={netPotential} data-testid="demo-radio-buttons-exclude-net-potential" onChange={event => setNetPotential(event?.target?.checked)} size="small" />} labelPlacement="start" label={<Typography sx={{ fontSize: 12, fontWeight: 'bold' }}>{potentialText}</Typography>} />

                    </Grid>
                  </Grid>
                  <Grid container rowSpacing={12} justifyContent='flex-start'>
                    <Grid item xs={2}>
                      <div style={{ marginLeft: '-16px' }} >
                        <FormControlLabel id="demo-radio-buttons-exclude-eodps" control={<Checkbox checked={excludeEODP} data-testid="demo-radio-buttons-exclude-eodps" onChange={event => setExcludeEODP(event?.target?.checked)} size="small" />} labelPlacement="start" label={<Typography sx={{ fontSize: 12, fontWeight: 'bold' }}>Exclude EODPs</Typography>} />
                      </div>
                    </Grid>
                    <Grid item xs={3} style={{ width: "300px" }}>
                      <div style={{ marginLeft: '-15px' }}>
                        <FormControlLabel id="demo-radio-buttons-exclude-tonnes" control={<Checkbox checked={excludeTone} data-testid="demo-radio-buttons-exclude-tonnes" onChange={event => setExcludeTone(event?.target?.checked)} size="small" />} labelPlacement="start" label={<Typography sx={{ fontSize: 12, fontWeight: 'bold' }}>{excludeToneText}</Typography>} />
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container rowSpacing={3} columnSpacing={5} justifyContent='flex-start'>
                    <Grid item xs={4} style={{ width: "300px" }}>
                      <Button id="button-apply_filter" data-testid="button-apply_filter" size="small" variant="contained" onClick={applyFilter} sx={{ background: 'linear-gradient(to bottom,  #142032 ,#2e3a4c)', color: 'white', mt: '2%', ml: '3%' }}>
                        Apply Filter
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </CardContent>
            </Card >
          </Grid >
        </Grid >
      </Box >

      <Box sx={{ width: '100%', mt: 2 }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <span id="home-rowsperpagename" style={{ fontSize: "14px", marginTop: "22px" }}>Rows per Page</span>
            <select style={{ margin: "0 9px", marginTop: "17px", fontSize: "14px" }} data-testid="home-rowsperpagename" value={rowsPerPage} onChange={handleChangeRowsPerPage}>
              {[5, 10, 25, 50, 100].map((option) => (
                <option id={"home-rowsperpage-" + option} key={option} value={option}>{option}</option>
              ))}
            </select>
          </div>
          <FormControl sx={{ m: 1, width: '25ch' }} variant="standard">
            <InputLabel htmlFor="input-with-icon-textfield">Search</InputLabel>
            <Input
              id="input-with-icon-textfield"
              type='text'
              data-testid="input-with-icon-textfield"
              value={search}
              onChange={(searchVal) => { requestSearch(searchVal, setSearch, tabledataCopy, setTabledata); setPage(0) }}
              endAdornment={
                <InputAdornment position="end">
                  <SearchOutlinedIcon />
                </InputAdornment>
              }
            />
          </FormControl>
        </div>
        <Box sx>
          <Grid container rowSpacing={4} columnSpacing={12} style={{ display: 'table' }}
          >
            <Grid item xs={12}>
              <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer id="acr-home-table-container" sx={{ maxHeight: 440 }}>
                  <Table stickyHeader size="small">
                    <TableHead >
                      <TableRow sx={{
                        "& th": {
                          fontSize: "12px",
                          fontWeight: "bold",
                          backgroundColor: "#5488c7",
                          color: "white",
                        }
                      }}>
                        {headCells.map((headcell) => (
                          <TableCell key={headcell.id} sx={{ color: "white", minWidth: "80px", maxWidth: headcell.width, border: "1px solid rgba(224, 224, 224, 1)", fontSize: "12px", fontWeight: "bold" }} align="center">
                            <TableSortLabel
                              id={"home-tablehead" + headcell.label}
                              active={orderBy === headcell.id}
                              direction={orderBy === headcell.id ? order : 'asc'}
                              onClick={handleSort(headcell.id)}
                            > {headcell.label}</TableSortLabel>

                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {(tabledata.sort(getComparator(order, orderBy))).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                        <TableRow
                          sx={{ backgroundColor: isSelected(row) ? 'rgb(174 174 176)' : 'white' }}
                          key={row.exch_sau}
                        >
                          <TableCell sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "2px", backgroundColor: handlecolor(row, exchsau) }} align="center">{row.css}</TableCell>
                          <TableCell sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "2px", backgroundColor: handlecolor(row, exchsau) }} align="center">{row.exch_sau}</TableCell>
                          <TableCell sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "2px", backgroundColor: handlecolor(row, exchsau) }} align="center">{row.exchange}</TableCell>
                          <TableCell sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "2px", backgroundColor: handlecolor(row, exchsau) }} align="center">{row.recovery_cables}</TableCell>
                          <TableCell sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "2px", backgroundColor: handlecolor(row, exchsau) }} align="center">{row.distance}</TableCell>
                          <TableCell sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "2px", backgroundColor: handlecolor(row, exchsau) }} align="center">{row.tonnage}</TableCell>
                          <TableCell sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "2px", backgroundColor: handlecolor(row, exchsau) }} align="center">{row.priority}</TableCell>
                          <TableCell sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "2px", backgroundColor: handlecolor(row, exchsau) }} align="center">{row.potential_net}</TableCell>
                          <TableCell id={"home-tablecell-" + row.job_id + "-Viewusermodal"} sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "2px", backgroundColor: handlecolor(row, exchsau) }} align='center'><ViewUser sau={row.exch_sau} exchange={row.exchange} /></TableCell>

                          <TableCell sx={{ cursor: "pointer", border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "2px", backgroundColor: handlecolor(row, exchsau) }} align="center"> <Link
                            component="button"
                            variant="body2"
                            data-testid={`view-exchange-${index}`}
                            id={`view-exchange-${index}`}
                            onClick={() => {
                              navigate({
                                pathname: '/exchange',
                                search: `?css=${row.css}&exch_sau=${row.exch_sau}&exchange=${row.exchange}`,
                              });
                              setLocalStorage();
                              setJobdetailstable([])
                              localStorage.setItem('exchangepage', 0);
                              localStorage.setItem('selectedrow', []);
                            }}
                          >
                            View Exchange
                          </Link></TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>

                  <Tablepagination
                    rowsPerPageOptions={[]}
                    setPage={setPage}
                    page={page}
                    count={tabledata.length}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    rowsPerPage={rowsPerPage}
                  />
                </TableContainer>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Box >
    </>
  )
}

import Jobdetails from './Jobdetails';
import Exchangecabledetails from './Exchangecabledetails'
import { useSearchParams, useLocation } from 'react-router-dom';
import { BreadCrumbContext } from '../common/BreadCrumbContext';
import { useEffect, useContext } from 'react';

const ExchangeView = () => {

  /** assigining searchparams */
  const [searchParams] = useSearchParams();
  const css = (searchParams.get('css'));
  const exch_sau = (searchParams.get('exch_sau'));
  const exchange = (searchParams.get('exchange'));

  /** Breadcrum */
  const { pathList, setPathList } = useContext(BreadCrumbContext);
  const location = useLocation();
  useEffect(() => {
    let updatedList = pathList.map(item => {
      (item.pathname === 'exchange') && (item.search = location.search)
      return item;
    });
    setPathList(updatedList)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    setPathList(pathList?.filter((item) => item.pathname !== 'jumper_schedule'));
    // eslint-disable-next-line
  }, [])

  return (
    <div>
      <Jobdetails exchange={exchange} />
      <Exchangecabledetails css={css} exch_sau={exch_sau} exchange={exchange} />
    </div>
  )
}

export default ExchangeView
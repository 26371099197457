import { useEffect, useState } from 'react';

import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { Backdrop, Box, Button, CircularProgress, InputAdornment, Modal, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import Tablepagination from '../common/Tablepagination';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 850,
  height: "auto",
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 3,
};

export default function WarningModal({ hazardData, hazardCount, Warningopen, setWarningOpen }) {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filteredRows, setFilteredRows] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleClose = () => { setOpen(false); setLoading(false) };
  const handlewarningClose = () => setWarningOpen(false);


  // table cells
  const columns = ["SAU", "Grade", "Code", "Node", "Equipment", "Type", "Description"]
  const SummaryColumns = ["Grade", "Code", "Count"];

  // filtering data based on search, hazard data
  useEffect(() => {
    const filteredRow = hazardData.filter((row) => {
      return (
        `${row.sau}`.toLowerCase().includes(search.toLowerCase())
      )
    })
    setFilteredRows(filteredRow)
  }, [hazardData, search])

  // pagination

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  return (
    <div>
      {loading &&
        <Box sx={{ width: '100%' }}>
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
            data-testid="loading-state"
            onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </Box>
      }

      <Modal
        id="warnings-modal"
        open={Warningopen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <p id="hazards" style={{ margin: 0, fontSize: "20px", textAlign: "center", fontWeight: "bold" }}>Hazards</p>
          <div style={{ display: "flex", justifyContent: "space-between", flexDirection: 'row', marginBottom: "10px", marginTop: "-10px" }}>
            <div style={{ display: "flex", alignItems: "flex-end" }}>
              <span id="warnings-rowsperpage" style={{ fontSize: "14px" }}>Rows per Page</span>
              <select style={{ margin: "0 9px", fontSize: "14px" }} data-testid="warnings-handlechange-rowsperpage" value={rowsPerPage} onChange={handleChangeRowsPerPage}>
                {[5, 10, 25, 50, 100].map((option) => (
                  <option id={"warnings-rowsperpage" + option} key={option} value={option}>{option}</option>
                ))}
              </select>
            </div>
            <TextField
              id="input-with-icon-textfield"
              label="Search"
              data-testid="input-search"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchOutlinedIcon />
                  </InputAdornment>
                ),
              }}
              variant="standard"
              value={search}
              onChange={(e) => { setSearch(e.target.value); setPage(0); }}
            />

          </div>

          <TableContainer id="warnings-TableContainer" component={Paper}>
            <Table id="warnings-Table" >

              <TableHead id="warnings-TableHead">
                <TableRow id="warnings-TableRow-head" sx={{ backgroundColor: "#5488c7", color: "white" }}>
                  {columns.map((headcell) => (
                    <TableCell id="warnings-TableCell" key={headcell} align='center' sx={{ color: "white", minWidth: "50px", margin: 0, padding: "4px", border: "1px solid rgba(224, 224, 224, 1)", fontSize: "13px" }}>
                      {headcell}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody >
                {
                  filteredRows && (filteredRows).map((row) => (
                    <TableRow id="warnings-tablerow" key={row.id}>
                      <TableCell id={"warnings-rowsperpage" + row.sau} sx={{ border: "1px solid #c2c2c2", padding: "2px", margin: 0, fontSize: "12px", backgroundColor: "rgba(0, 0, 0, 0.023)" }} align='center'>{row.sau}</TableCell>
                      <TableCell id={"warnings-rowsperpage" + row.Grade} sx={{ border: "1px solid #c2c2c2", margin: 0, fontSize: "12px", backgroundColor: "rgba(0, 0, 0, 0.023)", padding: "4px" }} align='center'>{row.Grade}</TableCell>
                      <TableCell id={"warnings-rowsperpage" + row.Code} sx={{ border: "1px solid #c2c2c2", margin: 0, fontSize: "12px", backgroundColor: "rgba(0, 0, 0, 0.023)", padding: "4px" }} align='center'>{row.Code}</TableCell>
                      <TableCell id={"warnings-rowsperpage" + row.Node} sx={{ border: "1px solid #c2c2c2", margin: 0, fontSize: "12px", backgroundColor: "rgba(0, 0, 0, 0.023)", padding: "4px" }} align='center'>{row.Node}</TableCell>
                      <TableCell id={"warnings-rowsperpage" + row.Equipmnet} sx={{ border: "1px solid #c2c2c2", margin: 0, fontSize: "12px", backgroundColor: "rgba(0, 0, 0, 0.023)", padding: "4px" }} align='center'>{row.Equipment}</TableCell>
                      <TableCell id={"warnings-rowsperpage" + row.Type} sx={{ border: "1px solid #c2c2c2", margin: 0, fontSize: "12px", backgroundColor: "rgba(0, 0, 0, 0.023)", padding: "4px" }} align='center'>{row.Type}</TableCell>
                      <TableCell id={"warnings-rowsperpage" + row.Description} sx={{ border: "1px solid #c2c2c2", margin: 0, fontSize: "12px", backgroundColor: "rgba(0, 0, 0, 0.023)", padding: "4px" }} align='center'>{row.Description}</TableCell>
                    </TableRow>
                  ))
                }
              </TableBody>
            </Table>
          </TableContainer>


          <Tablepagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            setPage={setPage}
            page={page}
            count={filteredRows ? hazardData.length : filteredRows.length}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            rowsPerPage={rowsPerPage}
          />
          <Typography id="warnings-summary" sx={{ mt: 6, ml: 8, fontSize: "18px" }}>Summary</Typography>
          <TableContainer id="warnings-summary-TableContainer">
            <Table id="warnings-summary-table" sx={{ width: "25%" }}>

              <TableHead id="warnings-summary-tablehead">
                <TableRow id="warnings-summary-tablerow" sx={{ backgroundColor: "#5488c7", color: "white" }}>
                  {SummaryColumns.map((headcell) =>
                  (
                    <TableCell id={"warnings-summary" + headcell} key={headcell} sx={{ color: "white", border: "1px solid rgba(224, 224, 224, 1)", fontSize: "13px", margin: 0, padding: "4px" }} align='center'>
                      {headcell}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody >
                {
                  hazardCount.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell id={"warnings-summary-" + row.Grade} sx={{ border: "1px solid #c2c2c2", margin: 0, padding: "4px", backgroundColor: "rgba(0, 0, 0, 0.023)" }} align='center'>{row.Grade}</TableCell>
                      <TableCell id={"warnings-summary-" + row.Code} sx={{ border: "1px solid #c2c2c2", margin: 0, padding: "4px", backgroundColor: "rgba(0, 0, 0, 0.023)" }} align='center'>{row.Code}</TableCell>
                      <TableCell id={"warnings-summary-" + row.Count} sx={{ border: "1px solid #c2c2c2", margin: 0, padding: "4px", backgroundColor: "rgba(0, 0, 0, 0.023)" }} align='center'>{row.Count}</TableCell>
                    </TableRow>
                  ))
                }
              </TableBody>
            </Table>
          </TableContainer>

          <Button id="warnings-dismiss" data-testid="warnings-dismiss" style={{ color: "white", backgroundColor: "rgb(2, 2, 81)", marginTop: "30px", marginRight: "10px", width: "100px", height: "30px", float: "right" }} onClick={handlewarningClose}>Dismiss</Button>

        </Box>
      </Modal>
    </div>
  );
} 
// commmon table pagination component for all table in the application
import { TablePagination } from '@mui/material'
import React from 'react'
import { ChangePage } from '../Exchange-View/Exchangecabledetails.util'

export const Tablepagination = (props) => {
  const { setPage, page, count, handleChangeRowsPerPage, rowsPerPage, rowsPerPageOptions } = props
  const handleChangePage = (event, newPage) => {
    ChangePage(newPage, setPage)
  }

  return (
    <>
      <TablePagination
        id="pagination"
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        showFirstButton={count}
        showLastButton={count}
        data-testid="table-pagination"
      /></>
  )
}
export default Tablepagination
import axios from "axios";
import { ACR, BASE_URL, } from "../../constant/urls";
import handleMucjCableData from '../Exchange-View/Exchangecabledetails.util';

// axios call to handle mucj cable update
export const handleMucjCableUpdate = async (updateFields,setSnackMsg,setAlertOpen,params,setOpenModal,url2,setMUCJTableData,viewjobid,setSelectedMUCJRows,setLoading) => {
    try {
      const payload = {
        JOB_ID: updateFields.JOB_ID,
        USER_EIN: updateFields.USER_EIN,
        CABLE: updateFields.CABLE,
        CABLE_WEIGHT: updateFields.CABLE_WEIGHT,
        CABLE_LENGTH: updateFields.CABLE_LENGTH,
        CABLE_GAUGE: updateFields.CABLE_GAUGE,
      };
         
        const response = await axios.patch(`${BASE_URL}/${ACR}/update_mucj_cable`, payload, 
             { params},
        );
 
        if (response.statuscode === 200 && response.data.status.toLowerCase() === "success") {
            setAlertOpen(true);
            setSnackMsg(response.data.MESSAGE);
            setOpenModal(false); 
            handleMucjCableData(params,url2,setMUCJTableData,viewjobid,setSelectedMUCJRows,setLoading,setSnackMsg,setAlertOpen);
          
        } 
        else if(response.statuscode === 200 && response.data.status.toLowerCase() === "fail") {
            setSnackMsg(response.data.MESSAGE);
            setAlertOpen(true);
            setOpenModal(false);

        }
        else if(response.statuscode === 500 && response.data.status.toLowerCase() === "fail") {
            console.log(response.data.MESSAGE);
            setSnackMsg(response.data.MESSAGE);
            setAlertOpen(true);
        }
    } catch (error) {
        console.error(error);
        setSnackMsg('Something went wrong! Please try again.');
        setAlertOpen(true);
    }
  };
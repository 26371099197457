import React, { useEffect, useState } from 'react';
import CustomTabPanel from '../../constant/CustomTabPanel';
import CheckSharpIcon from '@mui/icons-material/CheckSharp';
import axios from "axios";
import { BASE_URL, HCR_REPORT, HCR } from '../../constant/urls';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import * as XLSX from 'xlsx-js-style';
import { CSVLink } from "react-csv";
import {
    Tabs, Tab, Box, Card, CardContent, Typography, MenuItem, FormControl, Select,
    Paper, InputLabel, FormLabel, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Link, Snackbar, Alert, Grid, Stack, InputAdornment, Input, CircularProgress, Backdrop
} from '@mui/material';

import { csv_header, excel_report_header, report_table_header, fileName } from './HcrReportConstant';
import HcrRd from './HcrRd';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useMsal } from '@azure/msal-react';
import { getComparator, requestSearch, setIcon } from './HcrReport.util';
import Tablepagination from '../common/Tablepagination';

/**
 *
 *
 * @return {*} 
 */
function HcrReport() {

    const { accounts } = useMsal()


    /** Date Filters */
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();

    const clearData = () => {
        setStartDate(undefined);
        setEndDate(undefined);
    }
    useEffect(() => {
        getReportDetails()
    }, [startDate, endDate])
    /** Search */


    /** Snackbar */

    const [snackMsg, setSnackMsg] = React.useState('');
    const [alertOpen, setAlertOpen] = React.useState(false);
    const handleClose = () => {
        setAlertOpen(false);
    }

    /** Download report select */
    const [downloadReport, setDownloadReport] = React.useState('');
    const handleReport = (event) => {
        setDownloadReport(event.target.value);
        (event.target?.value === "excel") && exportToExcel(tabledata, fileName);
        // if (event.target?.value === "excel") {
        //     exportToExcel(tabledata, fileName);
        // }
    };

    /** CSV Headers  */
    const headers = csv_header;

    /** Excel Headers */
    const heading = excel_report_header;

    /** File Extension */
    const fileExtension = '.xlsx';

    /**
     *
     * Excel Report
     * @param {*} excelData
     * @param {*} fileName
     */
    const exportToExcel = (excelData, fileName) => {
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet([]);
        XLSX.utils.sheet_add_aoa(ws, heading);

        for (let i = 0; i < heading[0].length; i++) {
            const cell = ws[XLSX.utils.encode_cell({ r: 0, c: i })];
            !cell.s && (() => { cell.s = {}; })()
            // if (!cell.s) { cell.s = {}; }
            !cell.s.font && (() => { cell.s.font = {} })()
            // if (!cell.s.font) { cell.s.font = {}; }
            cell.s.font.bold = true;
        }
        //Starting in the second row to avoid overriding and skipping headers

        XLSX.utils.sheet_add_json(ws, excelData, { origin: 'A2', skipHeader: true });
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        XLSX.writeFile(wb, fileName + fileExtension);

    }

    /** Loader Implementation */
    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(true);

    /** Tab implemenation */
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        setTabledata(tabledataCopy)
    };

    const setIds = (index) => {
        return {
            id: `hcrReport-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    /** Table Header cells*/
    const headCells = report_table_header;

    /** Table data */
    const [tabledata, setTabledata] = React.useState([]);
    const [tabledataCopy, setTabledataCopy] = React.useState([]);

    /** Pagination */
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    /** Table Sorting */
    const order = 'asc';
    const orderBy = '';





    /**
     * axios call for report details
     *
     */
    const getReportDetails = () => {
        let params = {
            logged_user: accounts[0]?.idTokenClaims.EIN,
            from_date: startDate ? startDate?.getFullYear() + '-' + (startDate?.getMonth() + 1) + '-' + startDate?.getDate() : null,
            to_date: endDate ? endDate?.getFullYear() + '-' + (endDate?.getMonth() + 1) + '-' + endDate?.getDate() : null
        }

        setLoading(true);
        axios.get(`${BASE_URL}${HCR}${HCR_REPORT}`, { params: params }).then((tableResponse) => {
            setOpen(true);
            (tableResponse.data && tableResponse.data?.results) && (() => {
                setTabledata(tableResponse?.data?.results);
                setTabledataCopy(tableResponse?.data?.results);
                setLoading(false);
                setOpen(false);
            })()
        }).catch((error) => {
            setLoading(false);
            setOpen(false);
            setAlertOpen(true);

            setSnackMsg('Something went wrong!.Please try again!');
        });

    };

    /**
     *
     * Current data and time
     * @return {*} 
     */
    const setTimeout = () => {
        let current = new Date();
        let cDate = current.getFullYear() + '-' + (current.getMonth() + 1) + '-' + current.getDate();
        let cTime = current.getHours() + ":" + current.getMinutes() + ":" + current.getSeconds();
        let dateTime = cDate + ' ' + cTime;
        return dateTime
    }




    return (
        <>
            {loading &&
                <Box sx={{ width: '100%' }}>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={open}
                        onClick={handleClose}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </Box>
            }
            <Snackbar open={alertOpen} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                    {snackMsg}
                </Alert>
            </Snackbar>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ ml: '2%' }}>
                    <Tabs value={value} onChange={handleChange}>
                        <Tab label="Reporting" data-testid="reporting-tab" {...setIds(0)} />
                        <Tab label="RD" data-testid="rd-tab" {...setIds(1)} />
                    </Tabs>
                </Box>
                <Box>
                    <CustomTabPanel value={value} index={0}>
                        <Grid container  >
                            <Grid item xs md >
                                <Card variant="outlined" sx={{ marginTop: "-10px", marginLeft: "-13px", width: "500px", height: "146px" }} >

                                    <CardContent>
                                        <Stack direction="row" spacing={2}>
                                            <div style={{ marginTop: '50px' }}>
                                                <CheckSharpIcon></CheckSharpIcon>
                                            </div>
                                            <div>
                                                <Typography sx={{ fontSize: 23, marginTop: "-8px", marginLeft: "-10px" }}>
                                                    Info
                                                </Typography>
                                                <Typography sx={{ fontSize: 15, marginLeft: "-10px" }}>
                                                    NJR Estimate <span style={{ backgroundColor: 'yellow', fontWeight: "bold" }}>OBERC/FFA6088M</span>
                                                </Typography><br />
                                                <Typography sx={{ fontSize: 16, fontWeight: 'bold', marginLeft: "-10px", marginTop: "-5px" }}>
                                                    How to Guide
                                                </Typography>
                                                <Typography variant="body2" sx={{ fontSize: 15, fontWeight: 500, marginLeft: "-10px" }}>
                                                    <Link href="#" underline="none" color="blue">
                                                        {'Heavy Cable Recovery engineering guide'}
                                                    </Link>
                                                </Typography>
                                            </div>


                                        </Stack>
                                    </CardContent>
                                </Card>

                            </Grid>
                            <Grid item xs md >
                                <Card variant="outlined" sx={{ marginLeft: '6px', marginTop: "-10px", height: "146px", width: "458px" }}>
                                    <CardContent>
                                        <Stack direction="row" spacing={2}>
                                            <div style={{ marginTop: '50px' }}>
                                                <CheckSharpIcon></CheckSharpIcon>
                                            </div>
                                            <div>
                                                <Typography sx={{ fontSize: 23, marginLeft: "-10px" }}>
                                                    Data
                                                </Typography>
                                                <Typography sx={{ fontSize: 15, marginLeft: "-10px" }}>
                                                    Last Updated: <b>{setTimeout()}</b>
                                                </Typography>
                                                <br />
                                            </div>


                                        </Stack>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                        <Box sx={{ mt: "2%" }}>
                            <Typography sx={{ fontSize: "11px", fontWeight: "bold", marginTop: "-10px", marginLeft: "0px" }}>Select Date Range</Typography>

                            <div style={{ display: "flex", justifyContent: 'center', gap: 12, alignItems: 'flex-end', marginLeft: "-272px", marginTop: "6px" }}>
                                <FormLabel htmlFor='start-date' sx={{ fontSize: "12px" }} >Start Date:</FormLabel>
                                <DatePicker
                                    id='start-date'
                                    dropdownMode='datetime'
                                    selected={startDate}
                                    onChange={(date) => setStartDate(date)}
                                    selectsStart
                                    startDate={startDate}
                                    endDate={endDate} dateFormat='dd/MM/yyyy' popperPlacement="top"
                                    popperModifiers={{ flip: { behavior: ["top"] }, preventOverflow: { enabled: false }, hide: { enabled: true } }} label="Date" />
                                <FormLabel htmlFor='to-date' sx={{ fontSize: "12px" }} >to:</FormLabel>
                                <DatePicker
                                    id='to-date'
                                    selected={endDate}
                                    onChange={(date) => setEndDate(date)}
                                    selectsEnd
                                    endDate={endDate}
                                    minDate={startDate} dateFormat='dd/MM/yyyy'
                                    popperPlacement="top"
                                    popperModifiers={{ flip: { behavior: ["top"] }, preventOverflow: { enabled: false }, hide: { enabled: true } }} />

                                <Button size="small" sx={{ background: 'linear-gradient(to bottom,  #142032 ,#2e3a4c)', color: 'white', textTransform: 'none', marginLeft: "43px" }} dasta-testid="clear-data" onClick={clearData}>
                                    Clear
                                </Button>
                            </div>

                        </Box>
                        <div style={{ display: "flex", justifyContent: 'space-between' }}>
                            <div>
                                <FormControl sx={{ m: 1, width: '25ch', marginTop: "27px" }} variant="standard">
                                    <InputLabel >Search</InputLabel>
                                    <Input
                                        id="search"
                                        type='text'
                                        size="small"
                                        data-testid="input-search"
                                        onChange={(searchVal) => requestSearch(searchVal, tabledataCopy, setTabledata)}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <InputAdornment position="start">
                                                    <SearchOutlinedIcon />
                                                </InputAdornment>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            </div>
                            <div style={{ marginTop: "23px", marginRight: "692px" }}>
                                <FormControl sx={{ mt: 2, minWidth: 135, height: 10 }} size="small">
                                    <InputLabel id="demo-select-small-label" sx={{ fontSize: 12, fontWeight: 'bold' }} >Primary Report</InputLabel>
                                    <Select
                                        labelId="demo-select-small-label"
                                        id="demo-select-small"
                                        sx={{ fontSize: 12 }}
                                        value={downloadReport}
                                        label="PrimaryReport"
                                        onChange={handleReport}
                                    >
                                        <MenuItem value='csv'><CSVLink style={{ fontSize: 12, textDecoration: 'none', color: 'black' }} data={tabledata} headers={headers} filename={fileName}>
                                            CSV Report
                                        </CSVLink></MenuItem>
                                        <MenuItem sx={{ fontSize: 12 }} value='excel'> Excel Report</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>


                        </div>


                        <Grid container >
                            <Grid item xs={12}>
                                <TableContainer component={Paper} sx={{ marginTop: "16px" }}>
                                    <Table stickyHeader size="small">
                                        <TableHead>
                                            <TableRow sx={{
                                                "& th": {
                                                    fontSize: "12px",
                                                    fontWeight: "bold",
                                                    backgroundColor: "#5488c7",
                                                    color: "white",
                                                }
                                            }}>
                                                {headCells?.map((headcell) => (
                                                    <TableCell key={headcell.id} sx={{ color: "white", minWidth: "80px", maxWidth: headcell.width, border: "1px solid rgba(224, 224, 224, 1)", fontSize: "12px", fontWeight: "bold" }} align="center">

                                                        {headcell.label}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {(tabledata?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).sort(getComparator(order, orderBy)))?.map((row) => (
                                                <TableRow
                                                    data-testid="table-row-id"
                                                    key={row.id}
                                                >
                                                    <TableCell sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "2px" }} align="center">{row.md}</TableCell>
                                                    <TableCell sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "2px" }} align="center">{row.rd}</TableCell>
                                                    <TableCell sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "2px" }} align="center">{row.remaining_pcps}</TableCell>
                                                    <TableCell sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "2px" }} align="center">{row.completed_pcps}</TableCell>
                                                    <TableCell sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "2px" }} align="center">{row.issued_pcps}</TableCell>
                                                    <TableCell sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "2px" }} align="center">{row.total_pcps}</TableCell>
                                                    <TableCell sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "2px" }} align="center">{setIcon(row.wd_jumper)}{row.wd_1_coms}</TableCell>
                                                    <TableCell sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "2px" }} align="center">{row.todays_coms}</TableCell>
                                                    <TableCell sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "2px" }} align="center">{row.assigned}</TableCell>
                                                    <TableCell sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "2px" }} align="center">{row['%assigned']}</TableCell>
                                                    <TableCell sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "2px" }} align="center">{row.remaining_jumpers}</TableCell>
                                                    <TableCell sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "2px" }} align="center">{row.completed_jumpers}</TableCell>
                                                    <TableCell sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "2px" }} align="center">{row.issued_jumpers}</TableCell>
                                                    <TableCell sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "2px" }} align="center">{row.total_jumpers}</TableCell>
                                                    <TableCell sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "2px" }} align="center">{row['%hopper']}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>


                                    <Tablepagination
                                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                        setPage={setPage}
                                        page={page}
                                        count={tabledata?.length}
                                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                                        rowsPerPage={rowsPerPage}
                                    />
                                </TableContainer>

                            </Grid>
                        </Grid>
                    </CustomTabPanel>
                </Box>

                <CustomTabPanel value={value} index={1}>
                    <HcrRd />
                </CustomTabPanel>

            </Box >
        </>
    );



}

export default HcrReport

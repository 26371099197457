import { Box, Button, Snackbar, Alert, Card, CardContent, CardHeader, Popover, Link, Paper, Step, StepLabel, Stepper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React, { useState, useContext, useEffect } from 'react';
import '../../container/App.css';
import axios from 'axios';
import { BreadCrumbContext } from '../common/BreadCrumbContext';
import { BASE_URL, ACR, STAGES, NEXT_STAGE, VIEW_JOB, UNVIABLE, HOLD, ROLL_BACK, ADD_REMARKS } from '../../constant/urls';
import ChangeOwnershipDialog from '../Data-Freeze/ChangeOwnershipDialog';
import { useMsal } from '@azure/msal-react';
import { handleMoveNextdisable, handleOpenJob, handleRemarks, handleRollbackdisable, handleexchsau, handlesnackCloseFail, handlesnackCloseSuccess, holddisable, viabledisable } from './Jobdetails.util';

// table cells
const columns = ["Job ID", "Planner Name", "Modified On", "Recoverable Cable", "Total PCPs to Offload", "Offloaded PCPs", "Marked as Unviable", "Marked as Onhold", "Overall Status", "Remarks"]
const remarkscolumn = ["EIN", "Created At", "Remarks"]

const Jobdetails = ({ exchange }) => {
    const { accounts } = useMsal()

    const USER_EIN = JSON.parse(localStorage.getItem("USER_EIN"))
    const FIRST_NAME = JSON.parse(localStorage.getItem("FIRST_NAME"))
    const LAST_NAME = JSON.parse(localStorage.getItem("LAST_NAME"))


    let params = {
        logged_user: accounts[0]?.idTokenClaims.EIN
    }
    const { jobdetailstable, jobid, jobdetailsdisable, setJobdetailstable, setJobdetailsdisable, viewjobid, exchsau, setExchsau, setViewjobid } = useContext(BreadCrumbContext);
    const [snackopenfail, setsnackOpenfail] = useState(false);
    const [snackmsg, setSnackmsg] = useState("");
    const [snackopensucess, setsnackopensucess] = useState(false);


    useEffect(() => {
        handleexchsau(viewjobid, exchsau, exchange, params, setJobdetailstable)
        // eslint-disable-next-line
    }, [])

    // using axios api calls for steps label
    const [steps, setSteps] = useState([]);
    useEffect(() => {
        const stepsurl = `${BASE_URL}/${ACR}/${STAGES}`
        axios.get(stepsurl, { params: params })
            .then((res) => {

                const step = res?.data ? res?.data?.Data : null;
                step.push({ id: 8, stage: "UNVIABLE" })
                setSteps(step);
            })
            .catch((error) => {
                console.error(error);
                setsnackOpenfail(true);
                setSnackmsg("Something went wrong!.Please try again!")
            })
    }, [])

    const overall_status = steps?.find((item) => item.stage === jobdetailstable?.overall_status);
    const [disable, setDisable] = useState(true);
    const [tabledata, setTabledata] = useState([]);
    const [activeStep, setActiveStep] = useState(overall_status ? overall_status.id : 0);
    const [rollbackdisable, setRollbackdisable] = useState(false)
    // useffect to set the table data, active step state initially
    useEffect(() => {
        setTabledata([jobdetailstable]);
        (jobdetailstable?.length !== 0) && setDisable(false);
        // if (jobdetailstable?.length !== 0) {
        //     setDisable(false)
        // }
        (overall_status?.id >= 4) && setRollbackdisable(true);
        // if (overall_status?.id >= 4) {
        //     setRollbackdisable(true)
        // }
        (jobdetailstable?.marked_as_unviable === true) && setActiveStep(8);
        // if (jobdetailstable?.marked_as_unviable === true) {
        //     setActiveStep(8)
        // }
        setActiveStep(overall_status ? overall_status.id : 0)
        //         else {
        //     setActiveStep(overall_status ? overall_status.id : 0)
        // }
        // eslint-disable-next-line
    }, [jobdetailstable, setJobdetailstable, jobdetailsdisable])

    // useffect to set the viable and hold state initially
    const [viable, setViable] = useState(jobdetailstable?.marked_as_unviable);
    const [hold, setHold] = useState(jobdetailstable?.marked_as_onhold);

    useEffect(() => {
        (jobdetailstable?.marked_as_unviable) && (() => {
            const mark = jobdetailstable.marked_as_unviable;
            setViable(mark);
        })();
        // if (jobdetailstable?.marked_as_unviable) {
        //     const mark = jobdetailstable.marked_as_unviable;
        //     setViable(mark);
        // }
        (jobdetailstable?.marked_as_unviable) && (() => {
            const hold = jobdetailstable.marked_as_onhold;
            setHold(hold);
        })();
        // if (jobdetailstable?.marked_as_unviable) {
        //     const hold = jobdetailstable.marked_as_onhold;
        //     setHold(hold);
        // }
        // eslint-disable-next-line
    }, [tabledata])

    // to move next stage
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        const status = steps.find((item) => item.id === activeStep + 1);
        status && (() => {
            const next_stage = status.stage;
            const nextstage = {
                "job_id": jobid || viewjobid,
                "overall_status": next_stage,
                "user_ein": USER_EIN
            };
            const nextstageurl = `${BASE_URL}/${ACR}/${NEXT_STAGE}`;
            (overall_status?.id >= 4) && setRollbackdisable(true)
            axios.patch(nextstageurl, nextstage,)
                .then((res) => {
                    (res.data.status).toLowerCase() === "fail" && (() => {
                        setsnackOpenfail(true);
                        setSnackmsg(res.data.MESSAGE)
                    })()
                    // if ((res.data.status).toLowerCase() === "fail") {
                    //     setsnackOpenfail(true);
                    //     setSnackmsg(res.data.MESSAGE)
                    // }
                    handleTable();
                })
                .catch((error) => {
                    console.error(error);
                    setsnackOpenfail(true);
                    setSnackmsg("Something went wrong!.Please try again!")
                })
        })()
    };

    // axios call to recall the table data
    const handleTable = () => {
        const url = `${BASE_URL}/${ACR}/${VIEW_JOB}?job_id=${jobid || viewjobid}`
        axios.get(url, { params: params })
            .then((res) => {
                setJobdetailstable(res.data.Data);
                setDisable(false);
                const username = (FIRST_NAME + "_" + LAST_NAME).toLowerCase();
                const name = res.data.Data.planner_name.toLowerCase();
                username !== name && setJobdetailsdisable(true)
                // if (username !== name) {
                //     setJobdetailsdisable(true)
                // }
            })

    };

    // Rollback
    const handleRollback = () => {
        const rollbackurl = `${BASE_URL}/${ACR}/${ROLL_BACK}?job_id=${jobid || viewjobid}&user_ein=${USER_EIN}`
        axios.delete(rollbackurl,)
            .then((res) => {
                (res.data.status).toLowerCase() === "fail" && (() => {
                    setsnackOpenfail(true);
                    setSnackmsg('Something went wrong!.Please try again!')
                })();
                // if ((res.data.status).toLowerCase() === "fail") {
                //     setsnackOpenfail(true);
                //     setSnackmsg('Something went wrong!.Please try again!')
                // }
                (res.data.status).toLowerCase() === "success" && (() => {
                    setsnackopensucess(true);
                    setSnackmsg("Rollback successfully")
                })();
                // else if ((res.data.status).toLowerCase() === "success") {
                //     setsnackopensucess(true);
                //     setSnackmsg("Rollback successfully")
                // }
                handleTable();
            })
            .catch((error) => {
                console.error(error);
                setsnackOpenfail(true);
                setSnackmsg("Something went wrong!.Please try again!")
            })
    };




    // label css
    const steplabel = {
        fontSize: '11px',
        '& .MuiStepLabel-label': {
            fontSize: '11px !important',
            fontWeight: 700
        }
    }

    // remarks
    const [remarksData, setRemarksData] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (job_id) => (event) => {
        setAnchorEl(event.currentTarget);
        handleRemarks(job_id, params, setRemarksData, handleTable, setsnackOpenfail, setSnackmsg);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;



    const [remarks, setRemarks] = useState([]);
    const handleremarkschange = (event) => {
        setRemarks(event.target.value)
    }

    // axios call to add remarks
    const handleAddRemarks = (user_ein) => {
        const newremark = {
            "job_id": jobid || viewjobid,
            "remarks": remarks,
            "user_ein": USER_EIN
        }
        const postremarksurl = `${BASE_URL}/${ACR}/${ADD_REMARKS}`
        axios.post(postremarksurl, newremark,)
            .then((res) => {
                setRemarks('');
                (res.data.status).toLowerCase() === "fail" && (() => {
                    setsnackOpenfail(true);
                    setSnackmsg(res.data.MESSAGE);
                })();
                // if ((res.data.status).toLowerCase() === "fail") {
                //     setsnackOpenfail(true);
                //     setSnackmsg(res.data.MESSAGE);
                // }
                (res.data.status).toLowerCase() === "success" && (() => {
                    setsnackopensucess(true);
                    setSnackmsg("Remarks added successfully")
                })();
                //                 else if ((res.data.status).toLowerCase() === "success") {
                //     setsnackopensucess(true);
                //     setSnackmsg("Remarks added successfully")
                // }
            })
            .catch((error) => {
                console.error(error);
                setsnackOpenfail(true);
                setSnackmsg("Something went wrong!.Please try again!")
            })

    }

    // handle viable and unviable
    const handleviable = (viable) => {
        setViable(viable);
        const newviable =
        {
            "job_id": jobid || viewjobid,
            "marked_as_unviable": viable,
            "user_ein": USER_EIN
        }
        const viableurl = `${BASE_URL}/${ACR}/${UNVIABLE}`;
        axios.patch(viableurl, newviable,)
            .then((res) => {
                if ((res.data.status).toLowerCase() === "fail") {
                    setsnackOpenfail(true);
                    setSnackmsg(res.data.MESSAGE)
                }
                handleTable()
            })
            .catch((error) => {
                console.error(error);
                setsnackOpenfail(true);
                setSnackmsg("Something went wrong!.Please try again!")
            })
    }

    // handle hold and resume
    const handlehold = (holds) => {
        setHold(holds)
        const newhold =
        {
            "job_id": jobid || viewjobid,
            "marked_as_onhold": holds,
            "user_ein": USER_EIN
        }

        const holdurl = `${BASE_URL}/${ACR}/${HOLD}`;
        axios.patch(holdurl, newhold,)
            .then((res) => {
                if ((res.data.status)?.toLowerCase() === "fail") {
                    setsnackOpenfail(true);
                    setSnackmsg(res.data.MESSAGE)
                }
                handleTable();
            })
            .catch((error) => {
                console.error(error);
                setsnackOpenfail(true);
                setSnackmsg("Something went wrong!.Please try again!")
            })
    }

    return (
        <>
            <Snackbar data-testid="backdrop_closebtn" open={snackopenfail} autoHideDuration={6000} onClose={() => handlesnackCloseFail(setsnackOpenfail)} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert onClose={() => handlesnackCloseFail(setsnackOpenfail)} severity="error" sx={{ width: '100%' }}>
                    {snackmsg}
                </Alert>
            </Snackbar>

            <Snackbar open={snackopensucess} data-testid="snake_close" autoHideDuration={6000} onClose={() => handlesnackCloseSuccess(setsnackopensucess)} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert onClose={() => handlesnackCloseSuccess} severity="success" sx={{ width: '100%' }}>
                    {snackmsg}
                </Alert>
            </Snackbar>

            <Card id="job-details" variant="outlined" sx={{ minWidth: 275, marginTop: "10px" }}>

                <CardHeader id="job-details-header" sx={{ background: 'linear-gradient(to bottom,  #142032 ,#2e3a4c)', padding: 0.5 }}
                    titleTypographyProps={{ fontSize: 16, fontWeight: 700, color: 'white' }}
                    title="JOB DETAILS"
                />

                <CardContent id="jobdetails-content">
                    <TableContainer id="jobdetails-tablecontainer" component={Paper}>
                        <Table id=" jobdetails-table">
                            <TableHead id="jobdetails-tablehead">
                                <TableRow id="jobdetails-tablerow" sx={{ backgroundColor: "#5488c7", color: "white" }}>
                                    {columns.map((headcell) => (
                                        <TableCell id={"jobdetails-" + headcell} align='center' key={headcell} sx={{ color: "white", border: "1px solid rgba(224, 224, 224, 1)", fontSize: "12px", fontWeight: "bold", padding: "2px" }}>
                                            {headcell}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody >
                                {
                                    jobdetailstable?.length === undefined && tabledata.map((row) => (
                                        <TableRow key={row?.job_id} data-testid="tablebody">
                                            <TableCell id={"jobdetails-tablecell-Exch1141" + row?.job_id} sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "1px" }} align='center'>{row?.job_id}</TableCell>
                                            <TableCell id={"jobdetails-tablecell-Exch1141" + row?.job_id} sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "1px" }} align='center'>{row?.planner_name}</TableCell>
                                            <TableCell id={"jobdetails-tablecell-Exch1141" + row?.job_id} sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "1px" }} align='center'>{row?.modified_on}</TableCell>
                                            <TableCell id={"jobdetails-tablecell-Exch1141" + row?.job_id} sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "1px" }} align='center'>{row?.recoverable_cable}</TableCell>
                                            <TableCell id={"jobdetails-tablecell-Exch1141" + row?.job_id} sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "1px" }} align='center'>{row?.total_pcps_to_offload}</TableCell>
                                            <TableCell id={"jobdetails-tablecell-Exch1141" + row?.job_id} sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "1px" }} align='center'>{row?.offloaded_pcps}</TableCell>
                                            <TableCell id={"jobdetails-tablecell-Exch1141" + row?.job_id} sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "1px" }} align='center'>{row?.marked_as_unviable === true ? "true" : "false"}</TableCell>
                                            <TableCell id={"jobdetails-tablecell-Exch1141" + row?.job_id} sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "1px" }} align='center'>{row?.marked_as_onhold === true ? "true" : "false"}</TableCell>
                                            <TableCell id={"jobdetails-tablecell-Exch1141" + row?.job_id} sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "1px" }} align='center'>{row?.marked_as_unviable === true ? "UNVIABLE" : row?.overall_status}</TableCell>
                                            <TableCell id={"jobdetails-tablecell-Exch1141" + row?.job_id} sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "1px" }} align='center'>
                                                <Link aria-describedby={id} variant="contained" data-testid="remarks-link" onClick={handleClick(row?.job_id)}>Remarks</Link>
                                                <Popover
                                                    id={id}
                                                    open={open}
                                                    anchorEl={anchorEl}
                                                    onClose={handleClose}
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'left',
                                                    }}
                                                    sx={{ width: "600px", height: "200px" }}
                                                >
                                                    <TableContainer>
                                                        <Table>
                                                            <TableHead id="jobdetails-tablehead">
                                                                <TableRow id="jobdetails-tablerow" sx={{ backgroundColor: "#5488c7", color: "white" }}>
                                                                    {remarkscolumn.map((headcell) => (
                                                                        <TableCell id={"jobdetails-" + headcell} align='center' key={headcell} sx={{ color: "white", border: "1px solid rgba(224, 224, 224, 1)", fontSize: "12px", fontWeight: "bold", padding: "2px" }}>
                                                                            {headcell}
                                                                        </TableCell>
                                                                    ))}
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {remarksData ? remarksData.map((row) => (
                                                                    <TableRow key={row.id}>
                                                                        <TableCell id={"jobdetails-tablecell-ein" + row.ein} sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "1px" }} align='center'>{row.ein} </TableCell>
                                                                        <TableCell id={"jobdetails-tablecell-created_at" + row.ein} sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "1px" }} align='center'>{row.created_at} </TableCell>
                                                                        <TableCell id={"jobdetails-tablecell-remarks" + row.ein} sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "1px" }} align='center'><div id="message" dangerouslySetInnerHTML={{ __html: row.remarks }} /> </TableCell>

                                                                    </TableRow>)) : null}
                                                            </TableBody>
                                                        </Table>
                                                        {remarksData.length === 0 ? <p style={{ display: "flex", justifyContent: "center", fontSize: "12px" }}>No remarks</p> : null}

                                                    </TableContainer>
                                                </Popover>
                                            </TableCell>

                                        </TableRow>
                                    ))

                                }
                            </TableBody>
                        </Table>
                        {jobdetailstable?.length === 0 ? <p style={{ display: "flex", justifyContent: "center", fontSize: "12px" }}>No data available in table</p> : null}
                    </TableContainer>

                    <Box sx={{ width: '100%', marginTop: "60px", }}>

                        <div style={{ marginBottom: "53px" }}>
                            <Stepper id="jobdetails-stepper" activeStep={activeStep} alternativeLabel >
                                {steps.map((label) => {
                                    const stepProps = {};
                                    const labelProps = {};
                                    return (
                                        <Step id="jobdetails-step" className='step' key={label.stage} {...stepProps}>
                                            <StepLabel id={"jobdetails-step-" + label.stage} sx={steplabel}  {...labelProps}>{label.stage}</StepLabel>
                                        </Step>
                                    );
                                })}
                            </Stepper>
                        </div>

                        <div style={{ display: "flex", justifyContent: "space-between", marginTop: "15px" }}>

                            <div>
                                <input id="jobdetails-remarks" type="text" name="remarks" value={remarks} data-testid="remarks" onChange={handleremarkschange} />
                                <Button variant="contained" id="jobdetails-button-remarks" data-testid="jobdetails-button-remarks" sx={{ mr: 1, fontSize: "10px", fontWeight: 700, backgroundColor: "#142032", color: "white", mt: 1 }} onClick={handleAddRemarks} disabled={!disable ? jobdetailsdisable : disable} >Add Remarks</Button>
                            </div>

                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <Button variant="contained" id="jobdetails-button-rollback" data-testid="jobdetails-button-rollback" onClick={handleRollback} sx={{ fontSize: "10px", fontWeight: 700, backgroundColor: "#142032", color: "white", width: "100px", mr: 1, height: "32px" }} disabled={handleRollbackdisable(jobdetailsdisable, disable, activeStep, viable, rollbackdisable)} >
                                    Rollback
                                </Button>
                                {
                                    activeStep === steps.length ?
                                        <Button id="jobdetails-button-nextStage" data-testid="jobdetails-button-nextStage-1" variant="contained" sx={{ fontSize: "10px", fontWeight: 700, backgroundColor: "#142032", color: "white", width: "130px", mr: 1, height: "32px" }} disabled={handleMoveNextdisable(jobdetailsdisable, disable, viable, activeStep)}  >Move to Next Stage</Button>
                                        :
                                        <Button id="jobdetails-button-nextStage" data-testid="jobdetails-button-nextStage" onClick={handleNext} sx={{ fontSize: "10px", fontWeight: 700, backgroundColor: "#142032", color: "white", width: "130px", mr: 1, height: "32px" }} variant="contained" disabled={handleMoveNextdisable(jobdetailsdisable, disable, viable, activeStep)} >Move to NextStage</Button>
                                }

                                <ChangeOwnershipDialog
                                    open={open}
                                    jobid={jobid || viewjobid}
                                    handleTable={handleTable}
                                    disable={disable}
                                    setsnackOpenfail={setsnackOpenfail}
                                    setSnackmsg={setSnackmsg}
                                    setsnackopensucess={setsnackopensucess}
                                    jobdetailsdisable={jobdetailsdisable}
                                />
                                <Button id="jobdetails-button-unviable" data-testid="jobdetails-button-unviable" variant="contained" sx={{ mr: 1, fontSize: "10px", fontWeight: 700, backgroundColor: "#142032", color: "white", width: "130px", height: "32px" }} onClick={() => { handleviable(!viable); }} disabled={viabledisable(jobdetailsdisable, jobdetailstable, tabledata)}>{tabledata[0]?.marked_as_unviable ? "Mark as Viable" : "Mark as UnViable"}</Button>
                                <Button id="jobdetails-button-onhold" data-testid="jobdetails-button-onhold" variant="contained" sx={{ mr: 1, fontSize: "10px", fontWeight: 700, backgroundColor: "#142032", color: "white", width: "130px", height: "32px" }} onClick={() => { handlehold(!hold); }} disabled={holddisable(jobdetailsdisable, jobdetailstable, tabledata)}>{tabledata[0]?.marked_as_onhold ? "Resume" : "Mark as  Onhold"}</Button>
                                <Button id="jobdetails-button-openJob" data-testid="jobdetails-button-openJob" variant="contained" sx={{ mr: 1, fontSize: "10px", fontWeight: 700, backgroundColor: "#142032", color: "white", width: "130px", height: "32px" }} disabled={!disable ? jobdetailsdisable : disable} onClick={() => handleOpenJob(setExchsau, setViewjobid, exchange, jobid)}>Open Job</Button>
                            </div>
                        </div>
                    </Box>
                </CardContent>
            </Card >
        </>
    )
}

export default Jobdetails
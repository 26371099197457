import axios from "axios";
import { ASSIGN_UNASSIGN, BASE_URL, HCR } from "../../constant/urls";

export const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}
export const putAssignData = (ViewPcpFlag,setLoading,setOpenLoader,setAlertOpen,setSnackMsg,cables,exchanges,jobId,selectedCheckboxes,getAssignHomeData,pcp,ASSIGN_PCP,chooseEin) => {
  selectedCheckboxes.forEach((item) => {
      item.status === 'Required' && (() => {
          pcp.push(item.pcp)
          cables.push(item.cable)
          exchanges.push(item.exchange_name)
          jobId.push(item.job_id)
      })()

  })
  const url = `${BASE_URL}${HCR}/${ASSIGN_PCP}`;

  let params = {
      assigned_user: chooseEin,
      pcps: pcps_value(pcp),
      cables: cables_value(cables),
      exchanges: exchanges_value(exchanges),
      job_id: jobId_value(jobId)
  }

  axios.put(url, { table_data: selectedCheckboxes }, { params: params })

      .then((res) => {
          res.data.status === "Fail" && res.data.Status_code === 200 && setAlertOpen(true);
          res.data.status === "Fail" && res.data.Status_code === 200 && setSnackMsg(res.data.result);
          // if (res.data.status === "Fail" && res.data.Status_code === 200) {
          //     setAlertOpen(true);
          //     setSnackMsg(res.data.result);
          // }
          res.data && getAssignHomeData(ViewPcpFlag);
          // else if (res.data) {
          //     getAssignHomeData(ViewPcpFlag);
          // }

      })
      .catch((error) => {
          console.error(error)
          setLoading(false);
          setOpenLoader(false);
          setAlertOpen(true);
          setSnackMsg('Something went wrong!.Please try again!');

      })

}
export const requestSearch = (event, setSearch, tabledataTemp, setTabledata) => {

  let searchedVal = event?.target?.value;
  setSearch(searchedVal);
  const filteredRows = tabledataTemp.filter((row) => {
    return (`${row.cst_name} ${row.lcm_bal} ${row.cable} ${row.exchange_name} ${row.pcp} ${row.file_name} ${row.planner_name}`.toLowerCase()?.includes(searchedVal.toLowerCase()))

  });
  setTabledata(filteredRows);
};

export const handleReportExcel = (currentState, ViewPcpFlag, getExcelData) => {
  if (currentState === "allPCP") {
    getExcelData(!ViewPcpFlag);
  } else {
    getExcelData(ViewPcpFlag);
  }

}
export const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

export const handleUnassignPcp = (putUnassignData, setSnackbarsOpen, setSelectedCheckboxes) => {
  putUnassignData();
  setSnackbarsOpen(true);
  setSelectedCheckboxes([]);
  return null

}


export const pcps_value = (pcp) => {
  if (pcp.length) {
    return pcp.join()
  }
  else {
    return ''
  }
}

export const cables_value = (cables) => {
  if (cables.length) {
    return cables.join()
  }
  else {
    return ''
  }
}

export const exchanges_value = (exchanges) => {
  if (exchanges.length) {
    return exchanges.join()
  }
  else {
    return ''
  }
}

export const jobId_value = (jobId) => {
  if (jobId.length) {
    return jobId.join()
  }
  else {
    return ''
  }
}

export const handlecolor = (status) => {
  if (status === "Required") {
    return "red"
  }
  else if (status === "Assigned") {
    return "rgb(237, 180, 7)"
  }
  else {
    return "black"
  }
}

/**
Primary Report code for prod env
*/
export const getCsvData = (isAllPcp, params, search, setLoading, setOpenLoader, setCsvResp, setCsvShowLink, setAlertOpen, setExcelLink, setFileName, setSnackMsg) => {
  const searchKey = search.toUpperCase()
  const primarykey = ['True', 'csv'].join(",")
  const userId = ''
  const columns = ''
  setLoading(true);
  setOpenLoader(true)
  let homeurl = `${BASE_URL}${HCR}/${ASSIGN_UNASSIGN}?user_id=${userId}&columns=${columns}&view_pcps=${false}&search_key=${searchKey}&is_primary_report=${primarykey}`;
  let allurl = `${BASE_URL}${HCR}/${ASSIGN_UNASSIGN}?user_id=${userId}&columns=${columns}&view_pcps=${true}&search_key=${searchKey}&is_primary_report=${primarykey}`;
  const reporturl = isAllPcp ? allurl : homeurl;
  const filename = isAllPcp ? "all_pcp_workstack.csv" : "workstack.csv"
  axios.get(reporturl, { params: params }).then((response) => {
    response.data && (() => {
      let url = response.data['presigned_url'];
      setFileName(filename);
      setCsvResp(url);
      setCsvShowLink(true);
      setLoading(false);
      setExcelLink(false);
    })()
  }).catch((error) => {
    setAlertOpen(true);
    console.error(error);
    setLoading(false);
    setSnackMsg('Something went wrong!.Please try again!');


  });
}

export const customstyles = {
  control: (provided) => ({
    ...provided,
    fontSize: '12px',
    width: '280px',
    minHeight: '25px'
  }),
  option: (provided) => ({
    ...provided,
    fontSize: '12px'
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 9999
  })
}

export const onCsvDownload = (setLoading, setOpenLoader, setAlertOpen, csvResp, setSnackMsg, setCsvShowLink, fileName) => {
  setLoading(true);
  setOpenLoader(true)
  axios.get(csvResp, { responseType: 'blob' }).then((response) => {
    response.data && (() => {
      const url = URL.createObjectURL(response.data) //<---- this should be data.data
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      link.click();
      setLoading(false);
    })()
  }).catch((error) => {
    setAlertOpen(true);
    setLoading(false);
    console.error(error);
    setSnackMsg('Something went wrong!.Please try again!');
    setCsvShowLink(false);

  });

};

export const isSelected = (row, selectedCheckboxes) => selectedCheckboxes.indexOf(row) !== -1;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  descendingComparator, requestSearch, getComparator, handleUnassignPcp,
  pcps_value, cables_value, exchanges_value, jobId_value, handlecolor, getCsvData, customstyles, isSelected
}
export const handledisable = (item, viewjobid, job_id, jobdetails, USER_EIN) => {
    if (item.Working === 0) {
        return true
    }
    if (viewjobid === job_id) {
        const disable = item.is_disable ? true : false;
        return disable
    }
    if (jobdetails.job_owner === USER_EIN) {
        const disable = item.is_disable ? true : false;
        return disable
    }
}

export const ChangePage = (event, newPage, setPage) => {
    setPage(newPage);
    localStorage.setItem("cabinetpage", JSON.stringify(newPage))
    return newPage
}
// eslint-disable-next-line import/no-anonymous-default-export
export default { handledisable, ChangePage }
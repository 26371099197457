import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { ACR, BASE_URL, EXCHANGE_VIEW, HAZARD_VIEW } from "../../constant/urls";
import axios from "axios";

export const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

// to download the schematics pdf
export const handleDownloadPDF = (componentRef) => {
  html2canvas(componentRef.current).then(canvas => {
    const imgData = canvas.toDataURL('image/png');
    const pdf = new jsPDF();
    const imgWidth = 170;
    const imgHeight = 290;
    pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
    pdf.save('Network_graph.pdf');
  });

};
// to check whether the recovery cable has warning or not 
export const warnings = (row,setHazardCount,setLoading,setWarningOpen,setSnackMsg,setAlertOpen,Link,exch_sau,setHazardData,params) => {
    if (row.Warnings === true) {
        return <Link style={{ textDecoration: "underline", color: "blue", margin: 0, cursor: "pointer" }}
            data-testid={`view-hazards-${row.RecoveryCable}`}
            id={`view-hazards-${row.RecoveryCable}`}
            onClick={() => { handleWarnings(row.RecoveryCable, exch_sau, setHazardData, params, setHazardCount, setLoading, setWarningOpen, setSnackMsg, setAlertOpen) }}>View Hazards</Link>
    }
    else {
        return <p style={{ margin: 0 }}>No hazards found</p>
    }
}

export const data = (displayall, TableData, filteredRows) => {
  if (displayall) {
    return TableData
  }
  else {
    return (filteredRows || [])
  }
}

export const displayLength = (displayall, TableData, filteredRows) => {
  if (displayall.length) {
    return TableData.length
  }
  else {
    return filteredRows?.length
  }
}

// to handle row color based on the solution value
export const handleExchangeRowColor = (RecoveryCable, Solution, job_id, viewjobid, selectedrows, exch_sau, solutionColor, rowjob_id) => {
  if (viewjobid === job_id) {
    return "rgb(222, 184, 135)"
  }
  else {
    const condition1 = selectedrows ? (selectedrows[0] === RecoveryCable && selectedrows[1] === exch_sau) : null;
    const condition2 = rowjobid(job_id, rowjob_id)
    return condition1 || condition2 ? 'rgb(174 174 176)' : solutionColor(Solution)
  }
}

export const jobdetail = (pick, sau, RecoveryCable, css, exchange, setTableData, params, setjobdetails, setPickassignopen, setAssignopen) => {
  const url1 = `${BASE_URL}/${ACR}/${EXCHANGE_VIEW}?district=${css}&exchname=${exchange}&sau=${sau}`;
  axios.get(url1, { params: params })
    .then((res) => {
      const data = (res.data.Data);
      setTableData(data)
      const jobdetail = data.map(({ job_id, ein, RecoveryCable }) => ({ job_id, ein, RecoveryCable })).filter((res) => res.RecoveryCable === RecoveryCable);
      const jobdetails = {
        "job_id": jobdetail[0]?.job_id,
        "job_owner": jobdetail[0]?.ein,
      }
      setjobdetails(jobdetails);
      if (pick) {
        setPickassignopen(true);
      }
      else {
        setAssignopen(true);
      }
    })
  return null
}

export const ChangePage = (newPage, setPage) => {
  setPage(newPage);
  localStorage.setItem("exchangepage", JSON.stringify(newPage))
  return newPage
}

export const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

export const pages = (storagePage) => {
  if (isNaN(storagePage)) {
    return 0
  }
  else {
    return storagePage
  }
}

// axios call to create a job
export const newexchangevalues = async (sau, RecoveryCable, exchange, Uservalue, pick, css, setTableData, params, setSnackMsg, setAlertOpen, setjobdetails, setPickassignopen, setAssignopen) => {
  const newvalues = {
    ...{
      "user_ein": JSON.parse(localStorage.getItem("USER_EIN")),
      "first_name": JSON.parse(localStorage.getItem("FIRST_NAME")),
      "last_name": JSON.parse(localStorage.getItem("LAST_NAME")),
      "email_id": JSON.parse(localStorage.getItem("EMAIL_ID")),

    }, ...Uservalue
  }
  axios.post(`${BASE_URL}/${ACR}/create_job`, newvalues)

    .then((res) => {
      jobdetail(pick, sau, RecoveryCable, css, exchange, setTableData, params, setjobdetails, setPickassignopen, setAssignopen)
    })
    .catch((error) => {
      // console.error(error);
      setSnackMsg("Failed to create a job");
      setAlertOpen(true);
    })
}

export const rows = () => {
  if (localStorage.getItem('selectedrow')) {
    return JSON.parse(localStorage.getItem('selectedrow'))
  }
  else {
    return null
  }
}
export const rowjobid = (job_id, rowjob_id) => {
  if (rowjob_id.length > 0) { return rowjob_id[0].job_id === job_id } else { return null }
}
// axios call for warnings
export const handleWarnings = (recovery_cables, exch_sau, setHazardData, params, setHazardCount, setLoading, setWarningOpen, setSnackMsg, setAlertOpen) => {
  axios.get(`${BASE_URL}/${ACR}/${HAZARD_VIEW}?SAU=${exch_sau}&Owner_Id1=${recovery_cables}`, { params: params })
    .then((res) => {
      setHazardData(res.data.Data.hazard_data_list)
      setHazardCount(res.data.Data.hazard_count)

      setLoading(false);
      setWarningOpen(true)
    })

    .catch((error) => {
      console.error(error);
      setSnackMsg('Something went wrong!.Please try again!');
      setAlertOpen(true);
    })
}

/**
   *
   * filter retaining
   */
export const setLocalStorage = (eodpschecked, potentialNetchecked, selectedSolution, tonnechecked) => {
  const check = [{ excludeEODP: eodpschecked, netPotential: potentialNetchecked, selectedSolution: selectedSolution, excludeTone: tonnechecked }]
  if (localStorage.getItem('checkedList') !== null) {
    localStorage.setItem('checkedList', JSON.stringify(check));
  }
}

// schematics styling
export const options = {

  interaction: {
    selectConnectedEdges: false
  },
  edges: {

  },
  nodes: {
    shape: "dot",
    size: 11,
    font: {
      size: 10
    }
  },
  physics: {
    enabled: false

  },
  layout: {
    hierarchical: {
      enabled: true,
      levelSeparation: 150,
      nodeSpacing: 100,
      treeSpacing: 100,
      blockShifting: true,
      edgeMinimization: true,
      parentCentralization: true,
      direction: "LR", // UD, DU, LR, RL
      sortMethod: "hubsize", // hubsize, directed
      shakeTowards: "leaves" // roots, leaves
    }
  },
  height: "300px"

};
export const selectedRowsColor = (selectedRows) => {
  if (selectedRows.length) { return '#142032' }
  else { return 'rgb(150, 150, 180)' }
}

  // to get the selected solution values from home
 export function removeDuplicates(arr) {
    return arr.filter((item,
        index) => arr.indexOf(item) === index);
}

// handle row selection to set the selected row to usestate vatiable selectedRows
export const handleRowSelection = (row,selectedRows,setSelectedRows,setOpenschematics) => {
    const selectedIndex = selectedRows.indexOf(row);
    const newSelected = [...selectedRows];
    if (selectedIndex === -1) {
        newSelected.push(row);
    }
    else {
        newSelected.splice(selectedIndex, 1);
    }

    setSelectedRows(newSelected)
    setOpenschematics(false);
}

export  const handleSolutionchange = (value,selectedSolution,setSelectedSolution,setPage) => {
  if (selectedSolution?.includes(value)) {
      setSelectedSolution(selectedSolution?.filter((item) => item !== value));

  } else {
      setSelectedSolution([...selectedSolution, value])
  }
  setPage(0);

}
 /** Table Sorting */
 export const handleSort = (property,orderBy,order,setOrder,setOrderBy) => (event) => {
  const isAsc = orderBy === property && order === 'asc'
  setOrder(isAsc ? 'desc' : 'asc')
  setOrderBy(property)

}

export const handleMucjCableData = async (params,url2,setMUCJTableData,viewjobid,setSelectedMUCJRows,setLoading,setSnackMsg,setAlertOpen) => {
      
  axios.get(url2, { params: params})
      .then((res) => {
          if (res.body.statusCode === 200 && res.body.status.toLowerCase() === "success") {
              const successData = res.body.result;
              setMUCJTableData(successData);
              //const arr = successData.map((q) => q.Solution);
              const row = successData.filter((item) => item.job_id === viewjobid);
             // setMUCJRowjob_id(row);
              setSelectedMUCJRows(row);
              //setMUCJNoSolution(removeDuplicates(arr));
              setLoading(false);
          }
          // else {
          //     throw new Error("Invalid API response");
          // }
      })
      .catch((error) => {
          console.error(error,'===>>');
          setSnackMsg('Something went wrong!.Please try again!');
          setAlertOpen(true);
          setLoading(false)
      });
}
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  descendingComparator, rows, setLocalStorage, handleDownloadPDF, handleWarnings, data,
  displayLength, handleExchangeRowColor, ChangePage, getComparator, pages, newexchangevalues,
  handleRowSelection, handleSolutionchange,handleSort, handleMucjCableData
}
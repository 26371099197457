import axios from "axios";
import { BASE_URL, CHANGE_STATUS, HCR, SUB_JS_ID } from "../../constant/urls";
import { Checkbox, TableCell } from "@mui/material";
import ViewDialog from "./HcrWorkstackDialogbox";

export const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}
/** Search */

export const requestSearch = (event, setSearch, tabledataTemp, setTabledata) => {
  let searchedVal = event?.target?.value;
  setSearch(searchedVal);

  const filteredRows = tabledataTemp.filter((row) => {
    return (`${row.cst_name} ${row.ouc} ${row.cable_id} ${row.fn} ${row.exchange_name} ${row.pcp} ${row.planner_name} `.toLowerCase()?.includes(searchedVal.toLowerCase()))
  });
  setTabledata(filteredRows);
};

/** table checkbox row selection  */

export const handleRowSelection = (row, selectedCheckboxes, setSelectedCheckboxes) => {
  const selectedIndex = selectedCheckboxes.indexOf(row);
  const newSelected = [...selectedCheckboxes];
  if (selectedIndex === -1) {
    newSelected.push(row);
  }
  else {
    newSelected.splice(selectedIndex, 1);
  }

  setSelectedCheckboxes(newSelected)
}

/** put api for assign to me */
export const putAssignData = (selectedCheckboxes, FIRST_NAME, LAST_NAME, setLoading, setSnackMsg, setOpen, getWorkstackHomeData, ViewPcpFlag) => {

  const url = `${BASE_URL}${HCR}/${CHANGE_STATUS}`;

  const params = selectedCheckboxes?.map((item, index) => {
    return {
      "pcp": item.pcp,
      "cable": item.cable_id,
      "job_id": item.job_id,
      "sau": item.exchange_name,
      "assignee_name": FIRST_NAME + " " + LAST_NAME + `[${JSON.parse(localStorage.getItem("USER_EIN"))}]`,
      "is_assign": true,
      "logged_user": JSON.parse(localStorage.getItem("USER_EIN"))
    }

  })
  setLoading(true);
  setOpen(true);
  axios.put(url, params,)
    .then((res) => {
      setOpen(true);
      getWorkstackHomeData(ViewPcpFlag);
    })

    .catch((error) => {
      setLoading(false);
      setSnackMsg('Something went wrong!.Please try again!');
      setOpen(true);
    })

  return null
}

/**put api to unassign*/

export const putUnassignData = (selectedCheckboxes, FIRST_NAME, LAST_NAME, getAssignedPCPs, setOpen, setLoading, setAlertOpen, setSnackMsg) => {

  const url = `${BASE_URL}${HCR}/${CHANGE_STATUS}`;

  const params = selectedCheckboxes?.map((item, index) => {
    return {
      "pcp": item.pcp,
      "cable": item.cable_id,
      "job_id": item.job_id,
      "sau": item.exchange_name,
      "assignee_name": FIRST_NAME + " " + LAST_NAME + `[${JSON.parse(localStorage.getItem("USER_EIN"))}]`,
      "is_assign": false,
      "logged_user": JSON.parse(localStorage.getItem("USER_EIN"))
    }

  })
  axios.put(url, params)
    .then((res) => {
      getAssignedPCPs();
      setOpen(true);
    })
    .catch((error) => {
      setLoading(false);
      setAlertOpen(true);
      setSnackMsg('Something went wrong!.Please try again!');
      setAlertOpen(true);
    })
  return null

}

export  /**get api for jsid click */

  const getJsIdClick = (js_id, setIsDialogOpen, setLoading, setOpen, setResponseData, setAlertOpen, setSnackMsg) => {
    setIsDialogOpen(true);
    const url = `${BASE_URL}${HCR}/${SUB_JS_ID}`;
    let params = {
      js_id: js_id,
      logged_user: JSON.parse(localStorage.getItem("USER_EIN"))
    }
    setLoading(true);
    setOpen(true);
    axios.get(url, { params: params })
      .then((res) => {

        const subJsIds = res.data.results?.map(item => item.sub_js_id);
        const formattedResponse = subJsIds?.map((subJsId, index) => {
          return `${subJsId}`;
        });

        setResponseData(formattedResponse);
        setLoading(false);
        setOpen(false);
      })
      .catch((error) => {
        setLoading(false);
        setAlertOpen(true);

        setResponseData({ error: error.message });
        setOpen(false);
        setSnackMsg('Something went wrong!.Please try again!');
      })
  }

export const handleReportExcel = (currentState, getExcelReport, ViewPcpFlag, getExcelAssignReport) => {
  if (currentState === "allPCP") {
    getExcelReport(!ViewPcpFlag);
  }
  else if (currentState === "viewAssigned") {
    getExcelAssignReport();
  }
  else {
    getExcelReport(ViewPcpFlag);
  }

}

export const rowjsid = (row, setIsDialogOpen, setLoading, setOpen, setResponseData, setAlertOpen, setSnackMsg) => {
  if (!row.js_id) { return () => undefined } else { return () => getJsIdClick(row.js_id, setIsDialogOpen, setLoading, setOpen, setResponseData, setAlertOpen, setSnackMsg) }
}

export const isSelected = (row,selectedCheckboxes) => selectedCheckboxes.indexOf(row) !== -1;

export const rowStatus = (row, selectedCheckboxes, setSelectedCheckboxes) => {
  if (row.status === 'Completed') {
    return <Checkbox sx={{ margin: "-10px" }} disabled />
  } else {
    return <Checkbox id={"workstack-checkbox-row-" + row.exchange_name + "-" + row.cable_id + "-" + row.pcp} sx={{ margin: "-10px" }} data-testid="row-selection" checked={isSelected(row,selectedCheckboxes)} onClick={() => handleRowSelection(row, selectedCheckboxes, setSelectedCheckboxes)} />
  }
}

export const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

export const handleAssignCheckbox = (setSelectedCheckboxes, selectedCheckboxes, FIRST_NAME, LAST_NAME, setLoading, setSnackMsg, setOpen, getWorkstackHomeData, ViewPcpFlag, setExcelLink, setCsvLink, setSnackbarOpen) => {
  setSelectedCheckboxes([]);
  putAssignData(selectedCheckboxes, FIRST_NAME, LAST_NAME, setLoading, setSnackMsg, setOpen, getWorkstackHomeData, ViewPcpFlag);
  setExcelLink(false);
  setCsvLink(false);
  setSnackbarOpen(true);

};

export const handleUnassign = (setSelectedCheckboxes, setSnackbarsOpen, setExcelLink, setCsvLink, selectedCheckboxes, FIRST_NAME, LAST_NAME, getAssignedPCPs, setOpen, setLoading, setAlertOpen, setSnackMsg) => {
  setSelectedCheckboxes([]);
  putUnassignData(selectedCheckboxes, FIRST_NAME, LAST_NAME, getAssignedPCPs, setOpen, setLoading, setAlertOpen, setSnackMsg);
  setSnackbarsOpen(true);
  setExcelLink(false);
  setCsvLink(false);
};

export const headcelljs_id = (headcell, row, headcellWorkstack_select, setIsDialogOpen, setLoading, setOpen, setResponseData, setAlertOpen, setSnackMsg) => {
  if (headcell.id === "js_id") {

    return <TableCell
      sx={{
        border: "1px solid #c2c2c2",
        fontSize: "12px",
        textDecoration: "underline",
        color: "blue",
        padding: "0 1px",
        margin: 0,
        cursor: "pointer"
      }}
      align="center"
      data-testid={`js-id-${row.js_id}`}
      onClick={rowjsid(row, setIsDialogOpen, setLoading, setOpen, setResponseData, setAlertOpen, setSnackMsg)}
    >
      {row.js_id}
    </TableCell>
  }
  else {

    return headcellWorkstack_select(headcell, row)
  }
}
export const handleColor = (row) => {
  if (row.status === "Required") { return "red" } else { return "black" }
}

export const onCsvAssignDownload = (csvResp, setSnackMsg, setLoading, setOpen, setCsvLink, setAlertOpen) => {
  setLoading(true);
  setOpen(true)
  axios.get(csvResp, { responseType: 'blob' }).then((res) => {
    const url = URL.createObjectURL(res.data) //<---- this should be data.data
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'assigned_workstack.csv');
    link.click();
    setLoading(false);
    setCsvLink(false);
  }
  ).catch((error) => {
    setAlertOpen(true);

    setLoading(false);
    console.error(error);
    setSnackMsg('Something went wrong!.Please try again!');
    setCsvLink(false);

  });

};

export const onCsvDownload = (csvResp, fileName, setSnackMsg, setLoading, setCsvLink, setAlertOpen) => {
  setLoading(true);
  axios.get(csvResp, { responseType: 'blob' }).then((response) => {
    const url = URL.createObjectURL(response.data) //<---- this should be data.data
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    link.click();
    setLoading(false);
  }).catch((error) => {
    setAlertOpen(true);
    setLoading(false);
    console.error(error);
    setSnackMsg('Something went wrong!.Please try again!');
    setCsvLink(false);

  });

};
export const onExcelAssignDownload = (excelResp, setSnackMsg, setLoading, setOpen, setExcelLink, setAlertOpen) => {
  setLoading(true);
  setOpen(true)
  axios.get(excelResp, { responseType: 'blob' }).then((res) => {
    const url = URL.createObjectURL(res.data) //<---- this should be data.data
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'assigned_workstack.xlsx');
    link.click();
    setLoading(false);
    setExcelLink(false);

  }).catch((error) => {
    setAlertOpen(true);
    setLoading(false);
    console.error(error);
    setSnackMsg('Something went wrong!.Please try again!');
    setExcelLink(false);

  });

};

export const onExcelDownload = (excelResp, setSnackMsg, setLoading, setExcelLink, setAlertOpen, fileName) => {
  setLoading(true);
  axios.get(excelResp, { responseType: 'blob' }).then((response) => {
    const url = URL.createObjectURL(response.data) //<---- this should be data.data
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    link.click();
    setLoading(false);
  }).catch((error) => {
    setAlertOpen(true);
    setLoading(false);
    console.error(error);
    setSnackMsg('Something went wrong!.Please try again!');
    setExcelLink(true);

  });

};

export const handleSort = (property, orderBy, order, setOrder, setOrderBy) => () => {
  const isAsc = orderBy === property && order === 'asc'
  setOrder(isAsc ? 'desc' : 'asc')
  setOrderBy(property)

}

/**  checkbox click */
export const handleCheckboxChange = (event, checkboxInfo, setCheckboxInfo) => {
  const { id, checked } = event.target;
  const updatedworksatckcheckboxInfo = checkboxInfo?.map((item) => {
    if (item.id === id) {
      return {
        ...item,
        checked,
      };
    }
    return item;
  });
  setCheckboxInfo(updatedworksatckcheckboxInfo);

};

export const headcellfn = (headcell, row, getJumperSchedule,onDownload) => {
  if (headcell.id === "fn") {
    return <ViewDialog jumper={row[headcell.id]} jobId={row.job_id} getJumperSchedule={getJumperSchedule} onDownload={onDownload} />
  }
  else { return row[headcell.id] }
}
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  descendingComparator, requestSearch, handleRowSelection, putAssignData, putUnassignData,
  getJsIdClick, handleReportExcel, rowjsid, getComparator, onExcelAssignDownload, onCsvAssignDownload,
  handleColor, onCsvDownload, handleSort, handleCheckboxChange, headcellfn, isSelected
}
export const USER_EIN = JSON.parse(localStorage.getItem("USER_EIN"))
export const FIRST_NAME = JSON.parse(localStorage.getItem("FIRST_NAME"))
export const LAST_NAME = JSON.parse(localStorage.getItem("LAST_NAME"))
export const EMAIL_ID = JSON.parse(localStorage.getItem("EMAIL_ID"))
export const ROLE = JSON.parse(localStorage.getItem("ROLE"))

export const ADMIN =  "APP21744_NOS_IntProd_Admin"
export const PLANNER =  "APP21744_NOS_IntProd_Planner"
export const ENGINEER =  "APP21744_NOS_IntProd_Engineer"
export const ENGINEER_MANAGER =  "APP21744_NOS_IntProd_EngineerManager"

export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID
export const TENANT_ID = process.env.REACT_APP_TENANT_ID
export const AUTHORITY = `https://login.microsoftonline.com/${TENANT_ID}`
export const REDIRECTURI = "http://localhost:5433/home"


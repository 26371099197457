import { ACR, BASE_URL} from "../../constant/urls";
import axios from "axios";
import handleMucjCableData from '../Exchange-View/Exchangecabledetails.util';
// axios call to integrate add cable
export const addCable = async (addField, setSnackMsg, setAlertOpen, setOpenCreateModal, params,url2,setMUCJTableData,viewjobid,setSelectedMUCJRows,setLoading) => {
    try{
      const cableData = {
        sau: addField.sau,
        exchange: addField.exchange,
        cable: addField.cable,
        cable_weight: addField.cable_weight,
        cable_length: addField.cable_length,
        cable_gauge: addField.cable_gauge,
        ein: JSON.parse(localStorage.getItem("USER_EIN")),
        first_name: JSON.parse(localStorage.getItem("FIRST_NAME")),
        last_name: JSON.parse(localStorage.getItem("LAST_NAME")),
        email_id: JSON.parse(localStorage.getItem("EMAIL_ID")),
        }
      console.log('params',params)
      const response = await axios.post(
        `${BASE_URL}/${ACR}/create_mucj_cable`, 
        cableData, 
        {
          headers: {
            'Content-Type': 'application/json',
          },
          params: params,  
        }
      );
      const responseBody = response.body;
      if (responseBody.status.toLowerCase() === "success" && responseBody.status_code === 200) {
        setSnackMsg(responseBody.MESSAGE);
        setAlertOpen(true);
        setOpenCreateModal(false);
        handleMucjCableData(params,url2,setMUCJTableData,viewjobid,setSelectedMUCJRows,setLoading,setSnackMsg,setAlertOpen); // Calling the GET API function here
      } else if (responseBody.status.toLowerCase() === "fail" && responseBody.status_code === 200) {
        setSnackMsg(responseBody.MESSAGE);
        setAlertOpen(true);
      }
    } catch (error) {    
          setSnackMsg("Something went wrong!.Please try again!");
          setAlertOpen(true);
    }
  };
import React, { useState, useEffect, useContext } from 'react';
import { useSearchParams, useLocation } from 'react-router-dom';
import { BreadCrumbContext } from "../common/BreadCrumbContext";
import JumperScheduleTabs from "./JumperSchedule";
import { BASE_URL, ACR, UPDATE_JUMPER } from '../../constant/urls';
import axios from 'axios';
import { Alert, Snackbar } from '@mui/material';
import handleClose from './JumperScheduleWrapper.util';

function JumperScheduleWrapper() {


    const USER_EIN = JSON.parse(localStorage.getItem("USER_EIN"))
    const { pathList, setPathList, setSelectedviewrow, jobdetails } = useContext(BreadCrumbContext);
    const location = useLocation();
    useEffect(() => {
        let updatedList = pathList.map(item => {
            item.pathname === 'jumper_schedule' && (item.search = location.search)
            return item;
        });
        setPathList(updatedList)
    }, [])

    const [searchParams] = useSearchParams();

    const sau = searchParams.get('sau');
    const schedule = searchParams.get('schedule');
    const PCPs = searchParams.get('pcp_ids');
    const donor_cables = searchParams.get('donor_cables');
    const cable_ids = searchParams.get('cable');
    const actual_donor_cable = searchParams.get('actual_donor_cables');

    const job_owner = jobdetails?.job_owner !== null ? jobdetails?.job_owner : "";
    const job_id = jobdetails?.job_id !== null ? jobdetails?.job_id : "";


    const pcpNames = PCPs?.split(',');
    const schedules = schedule?.split(',');
    const donorCables = donor_cables?.split(',')
    const actual_donor_cables = actual_donor_cable?.split(',')

    const [loading, setLoading] = useState(true);
    const [tableData, setTableData] = useState([]);
    const [sucesss, setSucesss] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [snackMsg, setSnackMsg] = useState('');

    useEffect(() => {
        const rows = [schedule, PCPs, donor_cables, cable_ids]
        setSelectedviewrow(rows)
    }, [PCPs]);

    // axios call for table data
    const tableurl = `${BASE_URL}/${ACR}/${UPDATE_JUMPER}?sau=${sau}&schedule=${schedule}&pcp_ids=${PCPs}&donor_cables=${donor_cables}&cable=${cable_ids}&logged_user=${USER_EIN}&job_owner=${job_owner}&job_id=${job_id}`
    const [tabNames, setTabname] = useState();
    const [hostNames, sethostname] = useState();
    const [scheduleNames, setschedulename] = useState();
    const [selectedtabname, setselectedtabname] = useState(tabNames?.[selectedIndex]);
    const [message, setMessage] = useState("")

    const tabledata = () => {
        axios.get(tableurl,)
            .then((res) => {
                const message = res.data.message;
                const key = res.data.key;
                const Status_code = res.data.Status_code;
                const status = res.data.status;

                if (Status_code === 200 && key === "message" && status === "Fail") {
                    setTableData([]);
                    setTabname([]);
                    sethostname([]);
                    setschedulename([])
                    setMessage(message)
                    setLoading(false);
                }
                else if (Status_code === 200 && key === "validate" && status === "Fail") {
                    setTableData([]);
                    setTabname([])
                    sethostname([]);
                    setschedulename([])
                    setMessage(message)
                    setLoading(false);
                }
                else {
                    setTableData(res.data.result);
                    setTabname(res.data.pcp_name)
                    sethostname(res.data.host_cable_list)
                    setschedulename(res.data.schedule_list)
                    setLoading(false);
                    setMessage("")
                }

                setCount(difference);
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
                setSnackMsg('Something went wrong!.Please try again!');
                setSucesss(false)
                setAlertOpen(true);
            })
    }
    const storedPcp = JSON.parse(localStorage.getItem('selectedPCP')) || {};
    const difference = (storedPcp[selectedtabname]?.Available - storedPcp[selectedtabname]?.Working);

    const [count, setCount] = useState(difference);
    useEffect(() => {
        handlecount();
        setselectedtabname(tabNames?.[selectedIndex])
    }, [selectedIndex, selectedtabname, tabNames]);
    const handlecount = () => {
        setCount(difference);
    }
    useEffect(() => {
        tabledata();
    }, []);



    return (
        <>
            <Snackbar data-testid="jumper-snakebar" open={alertOpen} autoHideDuration={6000} onClose={() => handleClose(setAlertOpen)} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert onClose={() => handleClose(setAlertOpen)} severity="error" sx={{ width: '100%' }}>
                    {snackMsg}
                </Alert>
            </Snackbar>
            <JumperScheduleTabs
                message={message}
                loading={loading}
                tableData={tableData}
                count={count}
                sucesss={sucesss}
                alertOpen={alertOpen}
                setSelectedIndex={setSelectedIndex}
                setselectedtabname={setselectedtabname}
                donorCables={donorCables}
                schedules={schedules}
                selectedIndex={selectedIndex}
                setSucesss={setSucesss}
                setAlertOpen={setAlertOpen}
                pcpNames={pcpNames}
                setLoading={setLoading}
                sau={sau}
                setTableData={setTableData}
                selectedtabname={selectedtabname}
                setCount={setCount}
                donor_cables={donor_cables}
                cable_ids={cable_ids}
                job_owner={job_owner}
                job_id={job_id}
                schedule={schedule}
                PCPs={PCPs}
                snackMsg={snackMsg}
                setSnackMsg={setSnackMsg}
                handlecount={handlecount}
                tabNames={tabNames}
                hostNames={hostNames}
                scheduleNames={scheduleNames}
                actual_donor_cables={actual_donor_cables}
            />
        </>
    )
}

export default JumperScheduleWrapper;
const mucjHeadCells = [
    {
   id: 'sau',
   label: 'SAU',
   padding: "0 0 0 6px",
   width: 75
   },
{
   id: 'exchange',
   label: 'Exchange',
   padding: "0 0 0 8px",
   width: 80

},
{
   id: 'cable-id',
   label: 'Cable ID',
   padding: "0 0 0 8px",
   width: 75
   },
{
   id: 'weight',
   label: 'Weight',
   padding: "0 0 0 8px",
   width: 60
   },
{
   id: 'length',
   label: 'Length',
   padding: "0 13px",
   width: 60
   },
{
   id: 'cable-gauge',
   label: 'Cable Gauge',
   padding: "0 0 0 16px",
   width: 70
   },
{
   id: 'solution',
   label: 'Solution',
   padding: "0 6px",
   width: 40

},
{
   id: 'cr-ref',
   label: 'CR Ref',
   padding: "0 10px",
   width: 75
   },
{
   id: 'created-date',
   label: 'Created date',
   padding: "0 0 0 12px",
   width: 75
   },
{
   id: 'creator-name',
   label: 'Creator name',
   padding: "0 0 0 12px",
   width: 70
   },
{
   id: 'creator-ein',
   label: 'Creator EIN',
   padding: "0 0 0 10px",
   width: 70
   },
];
export default mucjHeadCells;
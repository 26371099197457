import React, { useEffect, useContext } from 'react';
import { useSearchParams, useLocation } from 'react-router-dom';
import { BreadCrumbContext } from '../common/BreadCrumbContext';
import View from './View';

export default function Viewcable() {
    const [searchParams] = useSearchParams();
    const sau = (searchParams.get('sau'));
    const css = (searchParams.get('css'));
    const cable_id = (searchParams.get('RecoveryCable'));
    const result = (searchParams.get('result'));
    const exchange = (searchParams.get('exchange'));

    const { pathList, setPathList } = useContext(BreadCrumbContext);
    const location = useLocation();
    useEffect(() => {
        let updatedList = pathList.map(item => {
            (item.pathname === 'cable') && (item.search = location.search)
            return item;
        });
        setPathList(updatedList)
        // eslint-disable-next-line
    }, [])
    return (
        <View sau={sau} css={css} cable_id={cable_id} result={result} exchange={exchange} />
    )
}



import React, { useState, useEffect } from "react";
import { Modal, Box, Button, TextField, Typography } from "@mui/material";
import { addCable } from "./CreateNewAddApiCall.util";
import { useMsal } from '@azure/msal-react';

const CreateNewModal = ({
  setSnackMsg,
  setAlertOpen,

  sau,
  exchange,
}) => {

  const { accounts } = useMsal();
  let params = {
    logged_user: accounts[0]?.idTokenClaims.EIN
}

  const initialFormData = {
    ein: "",
    first_name: "",
    last_name: "",
    email_id: "",
    cable: "",
    sau: "",
    exchange: "",
    cable_weight: "",
    cable_length: "",
    cable_gauge: "",
  };
 
  const [formData, setFormData] = useState(initialFormData);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const handleOpenCreateModal = () => {
    setOpenCreateModal(true);  
  };

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      sau: sau,
      exchange: exchange,
    }));
  }, [sau, exchange]);

  //Handle input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
 
  
  // Function to reset form data
  const resetFormData = () => {
    setFormData(initialFormData);
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addCable(formData, setSnackMsg, setAlertOpen, setOpenCreateModal, params);
    } catch (error) {
      console.error(error);
    }
    resetFormData();
    setOpenCreateModal(false);
  };

  const handleClose = () => {
    resetFormData(); 
    setOpenCreateModal(false);
  };

  return (
    <div>
      <Button variant="outlined" style={{ color: "white", backgroundColor: "#142032", marginBottom: "10px" ,fontSize:"12",height:"30px"}} onClick={handleOpenCreateModal}>CREATE NEW</Button>

      <Modal open={openCreateModal} onClose={() => setOpenCreateModal(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            pt: 2,
            pb: 3,
            pl: 3,
            pr: 3,
            borderRadius: 2,
          }}
        >
          <Typography variant="h6" sx={{ pb: 1 }}>Create New Cable</Typography>
          <form
            onSubmit={handleSubmit}
            style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
          >
            <TextField
              label="Cable ID"
              name="cable"
              value={formData.cable}
              onChange={handleChange}
               size="small"
            />
            <TextField
              label="Weight"
              name="cable_weight"
              value={formData.cable_weight}
              onChange={handleChange}
               size="small"
            />
            <TextField
              label="Length"
              name="cable_length"
              value={formData.cable_length}
              onChange={handleChange}
               size="small"
            />
            <TextField
              label="Cable Gauge"
              name="cable_gauge"
              value={formData.cable_gauge}
              onChange={handleChange}
               size="small"
            />
            <Box sx={{ display: "flex", justifyContent: "flex-start", mt: 1 }}>
              <Button
                style={{
                  color: "white",
                  backgroundColor: "#142032",
                  fontSize: "12",
                  height: "30px",
                }}
                onClick={handleClose}
                sx={{ mr: 1 }}
              >
                CANCEL
              </Button>
              <Button
                type="submit"
                variant="contained"
                style={{
                  color: "white",
                  backgroundColor: "#142032",
                  marginRight: "15px",
                  fontSize: "12",
                  height: "30px",
                }}
              >SUBMIT
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export default CreateNewModal;

import React from 'react'
import { Button, Modal, Box, TextField } from '@mui/material'
import { handleMucjCableUpdate } from './MucjCableUpdateModal.util';
const MucjCableUpdateModal = ({ setAlertOpen, setSnackMsg, openModal, handleCloseUpdateModal,updateFields,setUpdateFields,params,setOpenModal}) => {
   
    const handleUpdateFieldChange = (e) => {
        const { id, value } = e.target;
        setUpdateFields((prevData) => ({
            ...prevData,
            [id]: value
        }));
    }
    return (
        <div>
            <Modal
                open={openModal}
                onClose={handleCloseUpdateModal}
               
            >
                <Box sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
            width: 291,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            pt:0,
            pr:0,
            borderRadius: 2,
          }}>
                    <h2 id="modal-title" >Update Cable Details</h2>
                    <TextField
                        size='small'
                        margin='normal'
                        label="Cable ID"
                        variant="outlined"
                        id="CABLE"
                        value={updateFields.CABLE}
                        onChange={handleUpdateFieldChange}
                    />
                    <TextField
                        size='small'
                        margin="normal"
                        label="Weight"
                        variant="outlined"
                        id="CABLE_WEIGHT"
                        value={updateFields.CABLE_WEIGHT}
                        onChange={handleUpdateFieldChange}
                    />
                    <TextField
                        size='small'
                        margin="normal"
                        label="Length(m)"
                        variant="outlined"
                        id="CABLE_LENGTH"
                        value={updateFields.CABLE_LENGTH}
                        onChange={handleUpdateFieldChange}
                    />
                    <TextField
                        size='small'
                        margin="normal"
                        label="Cable Gauge"
                        variant="outlined"
                        id="CABLE_GAUGE"
                        value={updateFields.CABLE_GAUGE}
                        onChange={handleUpdateFieldChange}
                    />
                    <Box sx={{ display: 'flex',justifyContent:'flex-end',mr:3.5, mt: 2 }}>
                        <Button variant="contained"onClick={() => handleMucjCableUpdate(updateFields, setSnackMsg, setAlertOpen,params,setOpenModal)} style={{ color: "white", backgroundColor: "#142032", marginRight: "10px", fontSize: "12", height: "26px" }}>SUBMIT</Button>
                        <Button style={{ color: "white", backgroundColor: "#142032", fontSize: "12", height: "26px" }} onClick={handleCloseUpdateModal} sx={{ mr: 1 }}>CANCEL</Button>
                    </Box>
                </Box>
            </Modal>

        </div>
    )
}

export default MucjCableUpdateModal

import React, { useEffect, useState } from "react";
import {
    Card, CardContent, Typography, Box, Grid, Button, TableSortLabel, Link,
    Paper, Table, TableBody, Input, InputAdornment, TableCell, TableContainer, TableHead, TableRow, Snackbar, Alert, CircularProgress, Backdrop, TextField
} from "@mui/material";
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import axios from 'axios';
import { BASE_URL, HCR, ASSIGN_UNASSIGN, DOWNLOAD_JUMPER, ASSIGN_PCP, UNASSIGN_PCP } from "../../constant/urls";
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import ViewDialog from "./HcrDialogAssign";
import './HcrAssignUnassign.css';
import { assignCheckbox, newCheckboxes, assignHeader, selectHeader, newHeaders } from './HcrAssignUnassignConst';
import { useMsal } from "@azure/msal-react";
import Select from 'react-select';
import { cables_value, customstyles, exchanges_value, getComparator, getCsvData, handleReportExcel, handleUnassignPcp, handlecolor, isSelected, jobId_value, onCsvDownload, pcps_value, putAssignData, requestSearch } from "./HcrAssignUnassign.util";
import Tablepagination from "../common/Tablepagination";

export default function HcrAssignUnassign() {
    /** Loader Implementation */
    const [loading, setLoading] = React.useState(false);
    const [openLoader, setOpenLoader] = React.useState(true);
    const { accounts } = useMsal()


    const params = {
        logged_user: accounts[0]?.idTokenClaims.EIN
    }
    /** Snackbar */
    const [snackMsg, setSnackMsg] = React.useState('');
    const [alertOpen, setAlertOpen] = React.useState(false);
    const handleCloseSnackbar = () => {
        setAlertOpen(false);
    };


    /** select ein dropdown */

    const [plannerName, setPlannerName] = useState([]);
    const [chooseEin, setChooseEin] = useState('');
    const handleChange = (chooseEin) => {
        const value = chooseEin.value
        setChooseEin(value)
    };

    const [search, setSearch] = useState('');


    /** checkbox  */

    const [assignCheckboxInfo, setAssignCheckboxInfo] = useState(assignCheckbox);
    const [newCheckboxesAdded, setNewCheckboxesAdded] = useState(true);
    const resetCheckboxInfo = assignCheckboxInfo.map(item => ({ ...item, checked: false }));

    const viewAllCheckbox = [...resetCheckboxInfo, ...newCheckboxes];

    const ViewPcpFlag = false;

    /** Table header */

    const [assignTableHeader, setAssignTableHeader] = useState([...assignHeader, ...selectHeader]);
    const [viewTableHeader, setViewTableHeader] = useState([...assignHeader, ...selectHeader])
    const assignUpdateHeader = [...assignHeader, ...selectHeader]
    const [newHeaderAdded, setNewHeaderAdded] = useState(false);
    const viewAllHeader = [...assignHeader, ...newHeaders];



    /**  checkbox click */

    const handleCheckboxChange = (event) => {
        const { id, checked } = event.target;
        const updatedassigncheckboxInfo = assignCheckboxInfo?.map((item) => {
            if (item.id === id) {
                return {
                    ...item,
                    checked,
                };
            }
            return item;
        });
        setAssignCheckboxInfo(updatedassigncheckboxInfo);

    };
    /**  snackbar*/

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarsOpen, setSnackbarsOpen] = useState(false);

    /**  checkbox-header select button*/

    const handleSelectButton = () => {

        const selectedCheckboxIDs = assignCheckboxInfo.filter((item) => item.checked)?.map((item) => item.id);
        if (selectedCheckboxIDs.length === 0) {
            if (currentState === "home") {
                setAssignTableHeader([...assignUpdateHeader]);
                setViewTableHeader([...assignUpdateHeader]);
            }
            else {
                setAssignTableHeader([...viewAllHeader]);
                setViewTableHeader([...viewAllHeader]);
            }

        } else {

            const tempHeader = currentState === "home" ? assignUpdateHeader : viewAllHeader
            const checkedHeaders = tempHeader.filter((headcell) =>
                selectedCheckboxIDs.includes(headcell.id)
            );
            if (currentState === "home") {
                setAssignTableHeader([...checkedHeaders, ...selectHeader]);
                setViewTableHeader([...checkedHeaders, ...selectHeader]);

            } else {

                setAssignTableHeader(checkedHeaders);
                setViewTableHeader(checkedHeaders);

            }


        }

    };



    /** view all checkbox */
    const handleAddCheckbox = () => {
        setSearch('');

        setAssignCheckboxInfo(viewAllCheckbox);

        setAssignTableHeader(viewAllHeader);
        setViewTableHeader(viewAllHeader);
        setNewHeaderAdded(!newHeaderAdded);
        setNewCheckboxesAdded(!newCheckboxesAdded)
        setCurrentState('allPCP');
        getAssignHomeData(!ViewPcpFlag);

        setExcelLink(false);
        setCsvShowLink(false);

    };

    /**  handle click for back button*/

    const handleAssignBack = () => {
        setSearch('');
        getAssignHomeData(ViewPcpFlag);
        setAssignCheckboxInfo(assignCheckbox);

        setAssignTableHeader(assignUpdateHeader);
        setViewTableHeader(assignUpdateHeader);
        setNewHeaderAdded(false);
        setNewCheckboxesAdded(!newCheckboxesAdded)

        setCurrentState('home')
        setExcelLink(false);
        setCsvShowLink(false);

    }
    /**  Table data */

    const [tabledata, setTabledata] = React.useState([]);
    const [tabledataTemp, setTabledataTemp] = React.useState([]);


    // State to track selected checkboxes in table
    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);

    /** current page */

    const [currentState, setCurrentState] = useState('home');

    /** for table checkbox */

    const handleCheckboxChangeTable = (row) => {
        const selectedIndex = selectedCheckboxes.indexOf(row);
        const newSelected = [...selectedCheckboxes];
        if (selectedIndex === -1) {
            newSelected.push(row);
        }
        else {
            newSelected.splice(selectedIndex, 1);
        }

        setSelectedCheckboxes(newSelected)
    }


    const isViewAssignDisabled =
        selectedCheckboxes.length === 0 ||
        !selectedCheckboxes.some((checkbox) => checkbox.status !== 'Assigned' && chooseEin);

    const isViewUnassignDisabled =
        selectedCheckboxes.length === 0 ||
        !selectedCheckboxes.some((checkbox) => checkbox.status !== 'Required');

    /** Pagination */
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };




    /** Table Sorting */
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('exch_sau');

    const handleSort = (property) => () => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)

    }



    /** Assign button handle click */
    const handleAssignPcp = () => {
        putAssignData(ViewPcpFlag,setLoading,setOpenLoader,setAlertOpen,setSnackMsg,cables,exchanges,jobId,selectedCheckboxes,getAssignHomeData,pcp,ASSIGN_PCP,chooseEin);
        setSnackbarOpen(true);
        setSelectedCheckboxes([]);
        setChooseEin('');


    }
    /** primary report handle click */

    const handleReportCsv = () => {
        if (currentState === "allPCP") {
            getCsvData(!ViewPcpFlag, params, search, setLoading, setOpenLoader, setCsvResp, setCsvShowLink, setAlertOpen, setExcelLink, setFileName, setSnackMsg);

        } else {
            getCsvData(ViewPcpFlag, params, search, setLoading, setOpenLoader, setCsvResp, setCsvShowLink, setAlertOpen, setExcelLink, setFileName, setSnackMsg);
        }
    }

    /**
    *
    * GET API FOR TABLE DATA
    */

    const getAssignHomeData = (isAllPcp) => {
        const searchKey = search.toUpperCase()
        const primarykey = ['False', 'xlsx'].join(",")
        const userId = ''
        const columns = ''
        let homeurl = `${BASE_URL}${HCR}/${ASSIGN_UNASSIGN}?user_id=${userId}&columns=${columns}&view_pcps=${false}&search_key=${searchKey}&is_primary_report=${primarykey}`;
        let allurl = `${BASE_URL}${HCR}/${ASSIGN_UNASSIGN}?user_id=${userId}&columns=${columns}&view_pcps=${true}&search_key=${searchKey}&is_primary_report=${primarykey}`;
        const url = isAllPcp ? allurl : homeurl

        setLoading(true);
        setOpenLoader(true);
        axios.get(url, { params: params })
            .then((res) => {
                if (res.data.status === "Fail" && res.data.Status_code === 200) {
                    setAlertOpen(true);
                    setSnackMsg(res.data.message);
                }
                res.data && (() => {
                    setTabledata(res.data.result['assign_unassign_data']);
                    setTabledataTemp(res.data.result['assign_unassign_data'])
                    const data = res.data.result['assign_unassign_data'];
                    const sorted = [...data].sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
                    setTabledata(sorted);
                    setTabledataTemp(sorted)
                    const pName = res.data.result['user_dropdown']
                    const pnameobject = pName.map(item => ({ value: item, label: item }))
                    setPlannerName(pnameobject)
                    setLoading(false);
                    setPage(0);
                    setOpenLoader(false);
                })()

            })
            .catch((error) => {
                console.error(error)
                setLoading(false);
                setOpenLoader(false);
                setAlertOpen(true);
                setSnackMsg('Something went wrong!.Please try again!');


            })

    }



    useEffect(() => {
        getAssignHomeData(ViewPcpFlag);
    }, [])

    /**
     *
     * GET API FOR PRIMARY REPORT
     */
    const [csvResp, setCsvResp] = React.useState(null);
    const [csvShowLink, setCsvShowLink] = React.useState(false);

    const [excelResp, setExcelResp] = React.useState(null);
    const [excelLink, setExcelLink] = React.useState(false);

    const [fileName, setFileName] = useState("")

    const getExcelData = (isAllPcp) => {
        const searchKey = search.toUpperCase()
        const primarykey = ['True', 'xlsx'].join(",")
        const userId = ''
        const columns = ''
        setLoading(true);
        setOpenLoader(true)
        let homeurl = `${BASE_URL}${HCR}/${ASSIGN_UNASSIGN}?user_id=${userId}&columns=${columns}&view_pcps=${false}&search_key=${searchKey}&is_primary_report=${primarykey}`;
        let allurl = `${BASE_URL}${HCR}/${ASSIGN_UNASSIGN}?user_id=${userId}&columns=${columns}&view_pcps=${true}&search_key=${searchKey}&is_primary_report=${primarykey}`;
        const reportsurl = isAllPcp ? allurl : homeurl;
        const filename = isAllPcp ? "all_pcp_workstack.xlsx" : "workstack.xlsx"
        axios.get(reportsurl, { params: params }).then((response) => {
            response.data && (() => {
                let url = response.data['presigned_url'];
                setFileName(filename);
                setExcelResp(url);
                setExcelLink(true);
                setLoading(false);
                setCsvShowLink(false);


            })()
        }).catch((error) => {
            setAlertOpen(true);
            console.error(error);
            setLoading(false);
            setSnackMsg('Something went wrong!.Please try again!');

        });
    }

    const onExcelDownload = () => {
        setLoading(true);
        setOpenLoader(true)
        axios.get(excelResp, { responseType: 'blob' }).then((response) => {
            response.data && (() => {
                const url = URL.createObjectURL(response.data) //<---- this should be data.data
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                link.click();
                setLoading(false);
            })()
        }).catch((error) => {
            setAlertOpen(true);
            setLoading(false);
            console.error(error);
            setSnackMsg('Something went wrong!.Please try again!');
            setExcelLink(true);

        });

    };






    const pcp = [];
    const cables = [];
    const exchanges = [];
    const jobId = [];

    /**
 *
 * PUT API FOR ASSIGN PCP
 */




    /**
     *
     * PUT API FOR UNASSIGN PCP
     */
    const putUnassignData = () => {
        selectedCheckboxes.forEach((item) => {
            pcp.push(item.pcp)
            cables.push(item.cable)
            exchanges.push(item.exchange_name)
            jobId.push(item.job_id)

        })
        const url = `${BASE_URL}${HCR}/${UNASSIGN_PCP}`;
        let params = {
            pcps: pcps_value(pcp),
            cables: cables_value(cables),
            exchanges: exchanges_value(exchanges),
            job_id: jobId_value(jobId),
            logged_user: JSON.parse(localStorage.getItem("USER_EIN"))
        }
        axios.put(url, { table_data: selectedCheckboxes }, { params: params })

            .then((res) => {
                if (res.data.status === "Fail" && res.data.Status_code === 200) {
                    setAlertOpen(true);
                    setSnackMsg(res.data.result);
                }
                res.data && getAssignHomeData(ViewPcpFlag);
                // else if (res.data) {

                //     getAssignHomeData(ViewPcpFlag);
                // }

            })
            .catch((error) => {
                console.error(error)
                setLoading(false);
                setOpenLoader(false);
                setAlertOpen(true);
                setSnackMsg('Something went wrong!.Please try again!');

            })

    }

    const [jumperResp, setJumperResp] = React.useState(null);

    /**
           Jumper Report code for prod env
    */

    const getJumperSchedule = (filename, jobId) => {

        const jumperurl = `${BASE_URL}${HCR}/${DOWNLOAD_JUMPER}`;
        const params = {
            filename: filename,
            job_id: jobId,
            logged_user: JSON.parse(localStorage.getItem("USER_EIN"))
        }
        setLoading(true);
        axios.patch(jumperurl, params,)
            .then((response) => {
                response.data && (() => {
                    let url = response.data['presigned_url'];
                    setFileName(response.data.fileName);
                    setJumperResp(url);
                    setLoading(false);
                })()
            }).catch((error) => {
                setAlertOpen(true);
                console.error(error);
                setLoading(false);
                setSnackMsg('Something went wrong!.Please try again!');

            });
    }

    const onDownload = (file_name) => {
        setLoading(true);
        axios.get(jumperResp, { responseType: 'blob' }).then((response) => {
            response.data && (() => {
                const url = URL.createObjectURL(response.data) //<---- this should be data.data
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', file_name);
                link.click();
                setLoading(false);
            })()
        }).catch((error) => {
            setAlertOpen(true);
            setLoading(false);
            console.error(error);
            setSnackMsg('Something went wrong!.Please try again!');
        });

    };

    const headcellStatus = (headcell, row) => {
        if (headcell.id === "status") {
            return <TableCell
                sx={{
                    border: "1px solid #c2c2c2",
                    fontSize: "12px",
                    fontWeight: 800,
                    margin: 0,
                    padding: "2px",
                    color: handlecolor(row.status)
                }}
                align="center"
            >
                {row.status}
            </TableCell>
        }
        else {
            return (headcell.id !== 'workstack_select' ? <TableCell sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "2px" }} align="right">{headcell.id === "file_name" ?
                <ViewDialog jumper={row[headcell.id]} onDownload={onDownload} jobId={row.job_id} getJumperSchedule={getJumperSchedule} /> : row[headcell.id]}</TableCell> :
                <TableCell sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 2, padding: "2px", }} align='center'>

                    <Checkbox sx={{ margin: "-10px" }} checked={isSelected(row, selectedCheckboxes)} data-testid="table-selection-checkbox" onClick={() => handleCheckboxChangeTable(row)} />
                </TableCell>)
        }
    }

    return (

        <>
            {loading &&
                <Box sx={{ width: '100%' }}>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={openLoader}
                        onClick={handleCloseSnackbar}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </Box>
            }
            <Snackbar open={alertOpen} autoHideDuration={6000} onClose={handleCloseSnackbar} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
                    {snackMsg}
                </Alert>
            </Snackbar>
            <Box style={{
                marginLeft: "-24px", width: "auto", backgroundColor: "#d1d1d1",
                height: "57px", borderBottom: "1.5px solid whitesmoke"
            }}>
                <Typography sx={{ fontSize: "12px", color: "purple", marginLeft: "23px", paddingTop: "3px" }}>
                    Cu Recovery /
                </Typography>
                <Typography sx={{ fontSize: "23px", marginLeft: "21px" }}>
                    Assign to EIN
                </Typography>

            </Box>

            <Grid container item lg={12} >

                <Grid item lg={4} sm={4}>
                    <Card id="assign_instruction" sx={{ width: "292px", marginTop: "7px", height: "230px", background: "white", border: "3px solid whitesmoke", marginLeft: "-4px" }}>
                        <CardContent>
                            <div>
                                <Typography sx={{ fontSize: "17px", marginTop: "-12px", marginLeft: "22px" }}>
                                    Assigning PCPs to an EIN

                                </Typography>

                                <Typography sx={{ fontSize: "12px", marginTop: "4px", marginLeft: "22px", fontWeight: "500px" }}>
                                    1.Select each of the PCPs you plan by selecting the check box next to each PCP , then select EIN by clicking on the <strong> Assign to EIN </strong> button.

                                </Typography>

                                <ReportProblemOutlinedIcon style={{ fontSize: 40, color: "orange", marginTop: -8, marginLeft: -16 }} />

                                <Typography sx={{ fontSize: "12px", marginTop: "-35px", marginLeft: "22px", fontWeight: "500px" }}>
                                    Now click the <strong>Assign </strong> button.

                                </Typography>

                                <Typography sx={{ fontSize: "13px", marginTop: "7px", marginLeft: "22px", fontWeight: "bold" }}>
                                    Unassigning

                                </Typography>

                                <Typography sx={{ fontSize: "12px", marginTop: "1px", marginLeft: "22px", fontWeight: "500" }}>
                                    1.select each of the PCPs you want to unassign by selecting the check box next to each PCP,then click the Unassign button.

                                </Typography>
                            </div>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item lg={4} sm={4}>
                    <Card id="assign_filter_box" sx={{ width: "421px", marginTop: "7px", height: "230px", lineHeight: 1.05, background: "#d1d1d1", border: "1.5px solid whitesmoke", marginLeft: "-51px" }}>
                        <CardContent>
                            <div style={{ display: "flex", flexDirection: 'column', justifyContent: "center", alignContent: 'center' }}>
                                <Typography sx={{ width: "446px", marginLeft: "-16px", paddingLeft: "15px", height: "26px", paddingTop: "3px", fontSize: 16, fontWeight: 700, color: 'white', marginTop: "-19px", background: "#142032" }}>
                                    Select Column
                                </Typography>

                                <div style={{
                                    display: "grid",
                                    gridTemplateColumns: "repeat(3, 1fr)",
                                    gap: "3 15px",
                                    marginTop: "5px"
                                }}>


                                    {assignCheckboxInfo?.map((value) => {
                                        return (
                                            <div
                                                key={value.id}
                                                className={value.className}

                                            >
                                                <input

                                                    id={value.id}
                                                    checked={value.checked}
                                                    onChange={handleCheckboxChange}
                                                    data-testid={value.id}
                                                    style={{ marginLeft: "-10px", marginTop: "0px" }}
                                                    type="checkbox"
                                                    name="name"
                                                />
                                                <label style={{ fontWeight: 700, fontSize: "11px", marginLeft: "-1px", marginTop: "4px" }} htmlFor={value.id}
                                                >
                                                    {value.label}</label>

                                            </div>

                                        );
                                    })}
                                </div>

                            </div>
                            <Button id="button_viewallselect" data-testid="button_viewallselect" sx={{ padding: "2px 8px", color: "white", border: "whitesmoke", background: "#142032", marginLeft: "158px", fontSize: "14px", marginTop: "7px", textTransform: "none" }} variant="contained" onClick={handleSelectButton} >Select</Button>


                        </CardContent>
                    </Card>
                </Grid>

                <Grid item lg={4} sm={4}>
                    <Card id="assign_box" sx={{ width: 319, marginTop: "7px", height: "230px", marginLeft: "35px", background: "white", border: "3px solid whitesmoke" }}>
                        {newCheckboxesAdded ? <CardContent>
                            <div>
                                <Typography sx={{
                                    fontSize: "15px", background: "#d1d1d1", border: "3px solid whitesmoke",
                                    marginLeft: "-19px", marginTop: "-17px", height: "43px",
                                    paddingTop: "10px", paddingLeft: "10px", width: "320px"
                                }}>
                                    Assign to EIN
                                </Typography>

                                <Typography sx={{ fontSize: "13px", color: "purple", marginTop: "14px" }}>
                                    Assign to EIN
                                </Typography>

                                <Typography sx={{ fontSize: "11px", fontWeight: "bold", marginTop: "15px", marginLeft: "4px" }}>
                                    Select EIN:
                                </Typography>
                                <FormControl id="demo-controlled-open-select-label" sx={{ marginTop: "8px", marginLeft: "4px" }} size="small">
                                    <Select
                                        id="demo-select-label"
                                        inputId="select-ein"
                                        onChange={handleChange}
                                        menuPortalTarget={document.body}
                                        options={plannerName}
                                        styles={customstyles}
                                        data-testid="select-EIN" />
                                </FormControl>

                                <Button id="assign_assignunassign" sx={{ height: "26px", width: "1px", marginTop: "21px", background: "darkgreen", border: "whitesmoke", marginLeft: "-6px", fontSize: "15px", textTransform: "none" }} variant="contained" data-testid="assign_assignunassign" onClick={handleAssignPcp} disabled={isViewAssignDisabled}>Assign</Button>
                                <Snackbar
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                                    open={snackbarOpen}
                                    onClose={() => setSnackbarOpen(false)}
                                    message="PCP assigned successfully."


                                    autoHideDuration={2000}
                                />


                                <Button id="unassign_assignunassign" data-testid="unassign_assignunassign" sx={{ height: "26px", marginTop: "21px", padding: "1px 8px", marginLeft: "4px", background: "darkred", border: "whitesmoke", fontSize: "15px", textTransform: "none" }} variant="contained" onClick={() => handleUnassignPcp(putUnassignData, setSnackbarsOpen, setSelectedCheckboxes)} disabled={isViewUnassignDisabled}>Unassign</Button>
                                <Snackbar
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                                    open={snackbarsOpen}
                                    onClose={() => setSnackbarsOpen(false)}
                                    message="PCP unassigned successfully."


                                    autoHideDuration={2000}
                                />
                                <Button id="viewall_assignunassign" sx={{ height: "26px", marginTop: "-52px", background: "darkgreen", marginLeft: "148px", border: "whitesmoke", fontSize: "15px", textTransform: "none" }} variant="contained" data-testid="viewall_assignunassign" onClick={handleAddCheckbox} >View All PCPs</Button>

                            </div>

                        </CardContent> : <CardContent>
                            <Button id="assign_assignunassign" sx={{ height: "33px", width: "109px", marginTop: "21px", background: "#142032", border: "whitesmoke", marginLeft: "-6px", fontSize: "15px", textTransform: "none" }} variant="contained" data-testid="back-button" onClick={handleAssignBack}>Go Back</Button>
                        </CardContent>}
                    </Card>
                </Grid>
            </Grid>
            <div style={{ display: "flex", flexDirection: "row" }}>
                <div>
                    <FormControl sx={{ width: '25ch', marginTop: "9px" }} variant="standard">
                        <InputLabel >Search</InputLabel>
                        <Input
                            id="search"
                            type='text'
                            size="small"
                            data-testid="input-search"
                            onChange={(searchVal) => { requestSearch(searchVal, setSearch, tabledataTemp, setTabledata); setPage(0) }}
                            value={search}
                            endAdornment={
                                <InputAdornment position="end">
                                    <InputAdornment position="start">
                                        <SearchOutlinedIcon />
                                    </InputAdornment>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                </div>
                <div>
                    <TextField
                        size="small"
                        select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        label="PrimaryReport"
                        sx={{ fontSize: 10, width: "150px", height: "13px", marginTop: "15px", marginLeft: "30px", mb: 0 }}
                        data-testid="demo-select-small"
                    >
                        <MenuItem value='csv' style={{ fontSize: 12, textDecoration: 'none', color: 'black' }} data-testid="csv-report" onClick={handleReportCsv} >
                            CSV Report
                        </MenuItem>
                        <MenuItem sx={{ fontSize: 12 }} value='excel' onClick={() => handleReportExcel(currentState, ViewPcpFlag, getExcelData)}> Excel Report</MenuItem>

                    </TextField>
                </div>
            </div>
            {csvShowLink &&
                <div style={{ marginTop: '-24px' }} >
                    <Link
                        id="download-csv-Report"
                        variant="body2"
                        underline='hover'
                        data-testid="download-csv-Report"
                        onClick={() => onCsvDownload(setLoading, setOpenLoader, setAlertOpen, csvResp, setSnackMsg, setCsvShowLink, fileName)}

                        sx={{ fontSize: 14, color: 'Red', marginLeft: "450px", '&:hover': { cursor: 'pointer' } }}
                    >
                        Click here to download the CSV Report
                    </Link>
                </div>
            }
            {excelLink &&
                <div style={{ marginTop: '-24px' }} >
                    <Link
                        id="download-excel-Report"
                        variant="body2"
                        underline='hover'
                        onClick={onExcelDownload}
                        data-testid="download-link"
                        sx={{ fontSize: 14, color: 'Red', marginLeft: "450px", '&:hover': { cursor: 'pointer' } }}
                    >
                        Click here to Download Excel Report
                    </Link>
                </div>
            }


            <TableContainer sx={{ marginTop: "28px", overflowX: "scroll" }} component={Paper}>
                <Table stickyHeader sx={{ width: "100%" }} size="small">
                    <TableHead>
                        <TableRow sx={{
                            "& th": {
                                fontSize: "12px",
                                fontWeight: "bold",
                                backgroundColor: "#5488c7",
                                color: "white",

                            }
                        }}>
                            {(newHeaderAdded ? assignTableHeader : viewTableHeader)?.map((headcell) => (
                                <TableCell key={headcell.id} sx={{ color: "white", minWidth: "80px", maxWidth: headcell.width, border: "1px solid rgba(224, 224, 224, 1)", fontSize: "12px", fontWeight: "bold" }} align="center">
                                    <TableSortLabel
                                        active={orderBy === headcell.id}
                                        check direction={orderBy === headcell.id ? order : 'asc'}
                                        data-testid={`sort-${headcell.id}`}
                                        onClick={handleSort(headcell.id)}
                                    > {headcell.label}</TableSortLabel>

                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>

                        {

                            (tabledata?.sort(getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage))?.map((row) => (


                                <TableRow
                                    key={row.id}
                                >
                                    {(newHeaderAdded ? assignTableHeader : viewTableHeader)?.map((headcell) => (
                                        headcellStatus(headcell, row)
                                    ))}

                                </TableRow>
                            ))



                        }
                    </TableBody>
                </Table>

                <Tablepagination
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    setPage={setPage}
                    page={page}
                    count={tabledata?.length}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    rowsPerPage={rowsPerPage}
                />
            </TableContainer>

        </>


    )
}
import React from 'react'
import IconButton from '@mui/material/IconButton';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { Menu, MenuItem } from '@mui/material';
import { handleClose } from './Signout.util';

const Signout = ({ onsignout }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    // dropdown functionality
    const handledrop = (event) => {
        setAnchorEl(event.currentTarget);
    };



    return (
        <div>
            <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                data-testid="signout-icon"
                onClick={handledrop}
                color="inherit"
            >
                <AccountCircle sx={{ width: "30px", height: "30px" }} />
            </IconButton>
            <Menu
                id="menu-appbar"
                data-testid="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={() => { handleClose(setAnchorEl) }}
                sx={{ marginTop: "23px", }}
            >
                <MenuItem onClick={onsignout} data-testid="sign-out">Sign out</MenuItem>
            </Menu>
        </div>
    )
}

export default Signout


import React, { useState } from 'react';
import { BASE_URL, HCR, UPDATE_WORKSTACK_POPUP, SECOND_ENGINEER } from "../../constant/urls";

import Datepicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link, Alert, Snackbar, CircularProgress, Backdrop, Button, Grid, Dialog, Typography, MenuItem, Box, Paper, TextField } from '@mui/material';
import axios from 'axios';
import Select from 'react-select';
import { extractDigits } from './HcrWorkstackPopup.util';

export default function ViewPopup({ onDownload, workstackDialog, page, getAssignedPopup, row, clickEventFromChild, getAssignedPCPs, jobId }) {


    const FIRST_NAME = JSON.parse(localStorage.getItem("FIRST_NAME"))
    const LAST_NAME = JSON.parse(localStorage.getItem("LAST_NAME"))
    /** dialog box implementation*/

    const params = {
        logged_user: JSON.parse(localStorage.getItem("USER_EIN"))
    }
    const [popupOpen, setPopUpOpen] = useState(false);

    /** state that show textfields after conforming completed/not  */

    const [showAdditionalFields, setShowAdditionalFields] = useState(false);

    /** for closure note */

    const [notes, setNotes] = useState('');

    /**for datepicker */

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    /** Snackbar */
    const [snackMsg, setSnackMsg] = React.useState('');
    const [alertOpen, setAlertOpen] = React.useState(false);
    const handleClose = () => {
        setAlertOpen(false);
    };


    /** Loader Implementation */
    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(true);

    const [isComplete, setIsComplete] = React.useState(false)
    /** function to check completed/not  */

    const handleOption = (option) => {
        if (option === "yes") {
            setShowAdditionalFields(true);
            setIsComplete(true)


        } (option === "no") && (() => {
            setShowAdditionalFields(true);
            setIsComplete(false)


        })()

    }
    /** for no.incorrect routing and no.routing changed */
    const [engineers, setEngineers] = useState([]);
    const [selectedRoutingIncorrectNumber, setSelectedRoutingIncorrectNumber] = useState(null);
    const [selectedRoutingChangedNumber, setSelectedRoutingChangedNumber] = useState(null);
    const [selectedEngineer, setselectedEngineer] = useState(null);
    const [showLink, setShowLink] = React.useState(false);




    const handleRoutingIncorrect = (newNumber) => {
        setSelectedRoutingIncorrectNumber(newNumber);
    };
    const handleRoutingChanged = (newNumber) => {
        setSelectedRoutingChangedNumber(newNumber);
    };


    /** dialog box  */

    const handleClickOpen = () => {
        setPopUpOpen(true);
        getSecondEngineer();

    };

    const handleClosePopup = () => {
        setPopUpOpen(false);
        setShowAdditionalFields(false);
        setShowLink(false);
        setselectedEngineer(null);
        setSelectedRoutingIncorrectNumber(null);
        setSelectedRoutingChangedNumber(null);
        setNotes('');


    };


    /*closure note */

    const handleChange = (event) => {
        setNotes(event?.target.value);
    }

    /** datepicker time */

    const setTime = (current) => {
        let cDate = current.getFullYear() + '-' + (current.getMonth() + 1) + '-' + current.getDate();
        let cTime = current.getHours() + ":" + current.getMinutes() + ":" + current.getSeconds();
        let dateTime = cDate + ' ' + cTime;
        return dateTime
    }

    /** put api for apply button in popup  */
    const applydisable = !selectedEngineer || !(selectedRoutingIncorrectNumber === 0 ? (JSON.stringify(selectedRoutingIncorrectNumber)) : selectedRoutingIncorrectNumber) || !(selectedRoutingChangedNumber === 0 ? (JSON.stringify(selectedRoutingChangedNumber)) : selectedRoutingChangedNumber)
    const handleApply = (e) => {
        e.preventDefault();
        setLoading(true)
        let params = {
            "pcp": row['pcp'] ? row['pcp'] : '',
            "cable": row['cable_id'] ? row['cable_id'] : '',
            "sau": row['exchange_name'],
            "is_complete": isComplete,
            "s_eng": selectedEngineer,
            "start_date": setTime(startDate),
            "end_date": setTime(endDate),
            "incorrect_routings": selectedRoutingIncorrectNumber,
            "changed_routings": selectedRoutingChangedNumber,
            "note": notes,
            "assignee_name": FIRST_NAME + " " + LAST_NAME + `[${JSON.parse(localStorage.getItem("USER_EIN"))}]`,
            'logged_user': JSON.parse(localStorage.getItem("USER_EIN"))
        }
        localStorage.setItem("tablepage", JSON.stringify(page))

        const url = `${BASE_URL}${HCR}/${UPDATE_WORKSTACK_POPUP}`;
        axios.put(url, params,)
            .then((res) => {
                getAssignedPCPs();
                setLoading(false);
                setOpen(true);
                setShowAdditionalFields(false);
                setselectedEngineer(null);
                setSelectedRoutingIncorrectNumber(null);
                setSelectedRoutingChangedNumber(null);
                setNotes('');
            })
            .catch((error) => {
                setLoading(false);
                setAlertOpen(true)
                setSnackMsg('Something went wrong!.Please try again!');
            })
        clickEventFromChild('popupClose', null);
        handleClosePopup();

    }

    const getSecondEngineer = () => {
        const url = `${BASE_URL}${HCR}/${SECOND_ENGINEER}?login_user=${selectedEngineer}`;
        axios.get(url, { params: params })
            .then((res) => {
                const value = res.data.results
                const enginnersobject = value.map(item => {
                    const { first_name, ...rest } = item;
                    return { ...rest, "value": item.first_name, "label": item.first_name }
                })
                setEngineers(enginnersobject);
                setLoading(false);
                setOpen(true);

            })
            .catch((error) => {
                setLoading(false);
                setSnackMsg('Something went wrong!.Please try again!');
                setAlertOpen(true);
            })
    }


    /** jumper schedule download in popup */

    const jumperScheduleDownload = () => {
        clickEventFromChild('jumper', row['fn'], jobId);
        setShowLink(true);
    }

    const handleEngineerChange = (chooseEin) => {
        const value = chooseEin.value
        setselectedEngineer(value);
    };
    const customstyles = {
        control: (provided) => ({
            ...provided,
            fontSize: '12px',
            width: '260px',
            minHeight: '41px',
            margin: "20px"
        }),
        option: (provided, state) => ({
            ...provided,
            fontSize: '12px'
        }),
        menuPortal: (provided, state) => ({
            ...provided,
            zIndex: 9999
        })
    }


    return (
        <div>
            <Snackbar open={alertOpen} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                    {snackMsg}
                </Alert>
            </Snackbar>
            {loading &&
                <Box sx={{ width: '100%' }}>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={open}
                        onClick={handleClose}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </Box>
            }
            <Button style={{ background: "#142032", color: "white", fontSize: 11, margin: 0, cursor: "pointer" }} data-testid="assigned-button"
                onClick={handleClickOpen}>{workstackDialog}</Button>

            <Dialog
                id="workstack_popup"
                open={popupOpen}
                onClose={handleClosePopup}
                aria-labelledby="popup-dialog-title"
                aria-describedby="popup-dialog-description"
                sx={{ marginTop: "-30px", marginLeft: "-231px" }}
            >
                <Box sx={{ flexGrow: 1, width: 900, overflow: "hidden", height: "auto", display: "flex", padding: "20px", alignItems: "center", flexDirection: 'column' }} component={Paper}>
                    <Typography sx={{ paddingTop: "10px", marginLeft: "20px", width: "930px", paddingLeft: "353px", fontSize: 18, background: "#142032", color: 'white', marginTop: "-20px", height: "50px" }}>
                        HCR: Close PCP
                    </Typography>

                    <Button style={{ width: "260px", marginRight: "598px", marginTop: "17px", color: "white", backgroundColor: "#142032", height: "40px", float: "right" }} data-testid="view-jumper-schedule" onClick={jumperScheduleDownload}>View Jumper Schedule</Button>
                    {showLink &&
                        <div  >
                            <Link
                                id="download-Jumper-Report"
                                variant="body2"
                                underline='hover'
                                onClick={() => {
                                    onDownload(row['fn'], jobId);
                                    setOpen(false);
                                }}
                                data-testid="download-link"
                                sx={{ fontSize: 14, color: 'Red', marginLeft: "-429px", '&:hover': { cursor: 'pointer' } }}
                            >
                                Click here to download the Jumper Report
                            </Link>
                        </div>

                    }
                    <Box sx={{ '& .MuiTextField-root': { m: 2, width: '260px' }, }} noValidate autoComplete="off"  >

                        <form>
                            <div style={{ marginLeft: "0" }}>

                                <TextField
                                    size="small"
                                    id="postcode"
                                    label="Postcode"
                                    inputProps={{ readOnly: true }}
                                    value={row.post_code || ""}

                                />

                            </div>
                            <div style={{ marginLeft: "300px", marginTop: "-124px" }}>
                                <TextField
                                    size="small"
                                    id={"pcpName"}
                                    label="Pcp"
                                    value={row.pcp || ""}
                                    inputProps={{ readOnly: true }}
                                    autoComplete="current-Pcp"

                                />

                            </div>
                            <div style={{ marginLeft: "600px", marginTop: "-72px" }}>
                                <TextField
                                    size="small"
                                    id="no_jumpers"
                                    label="No Jumpers"
                                    inputProps={{ readOnly: true }}
                                    value={row.no_jumpers || ""}
                                />
                            </div>
                            <div style={{ marginLeft: "300px", marginTop: "-21px" }} >
                                <TextField
                                    size="small"
                                    id="wtw"
                                    label="WTW"
                                    inputProps={{ readOnly: true }}
                                />
                            </div>
                            <div style={{ marginLeft: "600px", marginTop: "-72px" }}>
                                <TextField
                                    size="small"
                                    id="plannermn"
                                    label="Planner Mobile No"
                                    inputProps={{ readOnly: true }}

                                />
                            </div>
                            <div style={{ marginLeft: "0px", marginTop: "-10px" }}>
                                <TextField
                                    id="check_yes_no"
                                    data-testid="check_yes_no"
                                    size="small"
                                    select
                                    label="Have you completed all Jumpers?"

                                >

                                    <MenuItem id="yes-menu-item" data-testid="yes-menu-item" value="1" onClick={() => handleOption('yes')}>yes </MenuItem>
                                    <MenuItem id="no-menu-item" value="2" onClick={() => handleOption('no')}>no </MenuItem>

                                </TextField>
                            </div>
                            {showAdditionalFields && (
                                <Grid container spacing={2}>

                                    <Grid item xs={6}>
                                        <TextField
                                            size="small"
                                            id="1_engineer"
                                            label="1st Engineer"
                                            inputProps={{ readOnly: true }}
                                            value={FIRST_NAME + " " + LAST_NAME + `[${JSON.parse(localStorage.getItem("USER_EIN"))}]` || ""}
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Select
                                            onChange={handleEngineerChange}
                                            menuPortalTarget={document.body}
                                            options={engineers}
                                            styles={customstyles}
                                            data-testid="2_engineer"
                                            placeholder="2nd Engineer*"
                                            id="second_engineer-workstack-label"
                                            inputId="workstack-select-second_engineer"
                                            getOPtionValue={(option) => "2nd_engineer-" + extractDigits(option.value)} />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Datepicker
                                            id="startdate_popup"
                                            selected={startDate}
                                            onChange={(date) => setStartDate(date)}
                                            timeInputLabel="Time:"
                                            dateFormat="MM/dd/yyyy h:mm aa"
                                            showTimeInput
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Datepicker
                                            id="enddate_popup"
                                            selected={endDate}
                                            onChange={(date) => setEndDate(date)}
                                            timeInputLabel="Time:"
                                            dateFormat="MM/dd/yyyy h:mm aa"
                                            showTimeInput
                                        />
                                    </Grid>
                                    <Grid item xs={6} >
                                        <TextField
                                            id="no_incorrect_routings"
                                            size="small"
                                            select
                                            label="No of Incorrect Routings*"
                                            data-testid="no-of-incorrect-routings"
                                            value={selectedRoutingIncorrectNumber}
                                            onChange={(e) => {
                                                setSelectedRoutingIncorrectNumber(e.target.value);
                                            }}

                                        >
                                            {Array.from({ length: 101 }, (_, index) => (
                                                <MenuItem id={'incorrect_routings-' + index} data-testid={`incorrect_routings-${index}`} key={index} value={index} onClick={() => handleRoutingIncorrect(index)}>
                                                    {index}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={6} sx={{ marginLeft: "301px", marginTop: "-88px" }}>
                                        <TextField
                                            id="no_routings_changed"
                                            size="small"
                                            select
                                            label="No of Routings Changed*"
                                            value={selectedRoutingChangedNumber}
                                            onChange={(e) => {
                                                setSelectedRoutingChangedNumber(e.target.value);
                                            }}

                                        >
                                            {Array.from({ length: 101 }, (_, index) => (
                                                <MenuItem id={'changed_routings-' + index} data-testid={`changed_routings-${index}`} key={index} value={index} onClick={() => handleRoutingChanged(index)}>
                                                    {index}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>

                                    <Grid item xs={6} sx={{ marginTop: "-13px" }}>
                                        <TextField
                                            size="small"
                                            id="closure_notes"
                                            label="Closure Notes"
                                            value={notes} onChange={handleChange}
                                        />
                                    </Grid>
                                </Grid>

                            )}


                            <div style={{ marginTop: "8px", display: "flex", justifyContent: "center", gap: "20px" }}>
                                <Button id="workstack-popup-dismiss" data-testid="workstack-popup-dismiss" style={{ color: "white", backgroundColor: "#142032", height: "30px", float: "right" }} onClick={handleClosePopup}>Dismiss</Button>


                                <Button id="workstack-popup-apply" data-testid="workstack-popup-apply" style={{ color: "white", backgroundColor: applydisable ? "rgba(20, 32, 50, 0.5)" : "#142032", height: "30px", float: "right" }}
                                    onClick={handleApply} type="submit" disabled={applydisable}>Apply</Button>
                            </div>
                        </form>
                    </Box>
                </Box>

            </Dialog>

        </div >

    );
}
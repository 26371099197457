import { Box, Button, Dialog, Snackbar, Alert, DialogActions, DialogContent, DialogContentText, Modal, Paper, Radio, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import AssignedDialog from './AssignedDialog';
import { BreadCrumbContext } from '../common/BreadCrumbContext';
import axios from 'axios';
import { ACR, BASE_URL, HOST_CABLES } from '../../constant/urls';
import { useMsal } from '@azure/msal-react';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 850,
  height: "auto",
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 3,
};
export default function JobAssignDialog({ sau, RecoveryCable, assignopen, exchange, cabinet, handlenewexchangevalues, css, job_id, job_owner }) {
  const [open, setOpen] = useState(false);
  const [opensummary, setOpensummary] = useState(false);
  const [selectedvalues, setSelectedvalues] = useState(1);
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [snackMsg, setSnackMsg] = React.useState("");
  const { setSelectedrow, setjobdetails } = useContext(BreadCrumbContext);
  const { accounts } = useMsal()

  //to pass logged user params for apis
  let params = {
    logged_user: accounts[0]?.idTokenClaims.EIN
  }

  // to close snackbar
  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  // column names
  const columns = ["Select/Unselect Cables to Offload", "Mark as Unviable", "Mark as Onhold"]

  const navigate = useNavigate();

  const [hostpopup, setHostpopup] = useState(false);
  const [hostmessage, setHostmessage] = useState("");

  // axios call to assign a job
  const handleClickOpen = () => {
    setOpen(true);
    const rows = [RecoveryCable, sau]
    setSelectedrow(rows)
    localStorage.setItem('selectedrow', JSON.stringify(rows));
    const hostcablesurl = `${BASE_URL}/${ACR}/${HOST_CABLES}?sau=${sau}&exchange=${exchange}&cable=${RecoveryCable}`;
    axios.get(hostcablesurl, { params: params })
      .then((res) => {
        console.log(res.data,'====<<')
        res.data.Status_code === 200 && res.data.status === "fail" && setHostpopup(false)
        // if (res.data.Status_code === 200 && res.data.status === "fail") {
        //   setHostpopup(false)
        // }
        res.data.Status_code === 200 && res.data.status === "Success" && setHostpopup(true)
        res.data.Status_code === 200 && res.data.status === "Success" && setHostmessage(res.data.MESSAGE)
        // else if (res.data.Status_code === 200 && res.data.status === "Success") {
        //   setHostpopup(true)
        //   setHostmessage(res.data.MESSAGE)
        // }
      })
      .catch((error) => {
        console.error(error);
        setSnackMsg("Something went wrong.Try Again!!");
        setAlertOpen(true);
      })
  };


  const handleClose = () => {
    setOpen(false);
  };

  const handleSummaryOpen = () => {
    setOpensummary(true);
  };
  const handleSummaryClose = () => {
    setOpensummary(false);
  };

  // checkbox 
  const handleCheckboxChange = (value) => {
    setSelectedvalues(value);
  }

  const values = {
    "sau": sau,
    "cable": RecoveryCable,
    "exchange": exchange,
    "total_pcps_to_offload": cabinet,
    "marked_as_unviable": false,
    "marked_as_onhold": false,
    "overall_status": "YET TO START"
  }
  // to check values for user selected
  // const getvalues = () => {
  //   // if (selectedvalues === 1) {
  //   //   return { ...values, ...{ marked_as_unviable: false, marked_as_onhold: false } }
  //   // }
  //   // else if (selectedvalues === 2) {
  //   //   return { ...values, ...{ marked_as_unviable: true, marked_as_onhold: false } }
  //   // }
  //   // else if (selectedvalues === 3) {
  //   //   return { ...values, ...{ marked_as_unviable: false, marked_as_onhold: true } }
  //   // }
  // }
  const getvalues = () => {
    const mapping = {
      1: { marked_as_unviable: false, marked_as_onhold: false },
      2: { marked_as_unviable: true, marked_as_onhold: false },
      3: { marked_as_unviable: false, marked_as_onhold: true }
    };
  
    return { ...values, ...mapping[selectedvalues] };
  };
  const getjsonValues = getvalues();

  const handleSelectOk = () => {

    handlenewexchangevalues(sau, RecoveryCable, exchange, getjsonValues);
    close()

  }
  const close = () => {
    handleSummaryClose();
    handleClose();
  }

  // on sucessfully creation of job navigate to the cable view
  const handleNavigate = () => {
    navigate({
      pathname: '/cable',
      search: `?css=${css}&sau=${sau}&RecoveryCable=${RecoveryCable}&exchange=${exchange}&result=${"TRUE"}`,
    })
    const jobdetails = {
      "job_id": null,
      "job_owner": null,
    }
    setjobdetails(jobdetails)
    localStorage.setItem('selectedPCP', null);
    localStorage.setItem('cablepage', 0);
    localStorage.setItem('cabinetpage', 0);

  }
  return (
    <div>
      <Snackbar open={alertOpen} autoHideDuration={6000} onClose={handleAlertClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
        <Alert onClose={handleAlertClose} severity="error" sx={{ width: '100%' }}>
          {snackMsg}
        </Alert>
      </Snackbar>

      <p style={{ textDecoration: "underline", color: "blue", padding: "0 1px", margin: 0, cursor: "pointer" }} data-testid={`view-job-${RecoveryCable}`}
        onClick={handleClickOpen}>View cable</p>
      {hostpopup ? <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        data-testid="alert-dialog-modal"
        aria-describedby="alert-dialog-description"
        sx={{ marginTop: "-350px", marginLeft: "450px", width: "500px" }}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div dangerouslySetInnerHTML={{ __html: hostmessage }} />
          </DialogContentText>
          <Button id="alert-dialog-button-Dismiss" style={{ color: "white", backgroundColor: "rgb(2, 2, 81)", margin: "0px 10px 9px", width: "auto", height: "30px", float: "right", fontSize: "12px" }} onClick={handleClose}>Dismiss</Button>
        </DialogContent>
      </Dialog> :
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          sx={{ marginTop: "-350px", marginLeft: "450px", width: "400px" }}
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Do you want to proceed with assigning new job?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button id="alert-dialog-button-yes" style={{ color: "white", backgroundColor: "rgb(2, 2, 81)", margin: "0px 10px 9px", width: "auto", height: "30px", float: "right", fontSize: "12px" }} data-testid="handle-summary-open" onClick={handleSummaryOpen}>Yes</Button>

            {opensummary && <div>
              <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{ marginTop: "-200px" }}
              >
                <Box sx={style}>
                  <p style={{ fontSize: "16px", textAlign: "center", fontWeight: "500", marginBottom: "5px" }}>SELECTION SUMMARY</p>
                  <TableContainer component={Paper}>
                    <Table >

                      <TableHead>
                        <TableRow sx={{ backgroundColor: "#5488c7", color: "white" }}>
                          {columns.map((headcell) => (
                            <TableCell key={headcell} align='center' sx={{ color: "white", minWidth: "50px", margin: 0, padding: "4px", border: "1px solid rgba(224, 224, 224, 1)", fontSize: "13px" }}>
                              {headcell}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>

                      <TableBody >
                        <TableRow>
                          <TableCell sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "1px" }} align='left' > <Radio checked={selectedvalues === 1} inputProps={{ "data-testid": `radio-button-1` }} onChange={() => handleCheckboxChange(1)} />{RecoveryCable}</TableCell>
                          <TableCell sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "1px" }} align='left' > <Radio checked={selectedvalues === 2} inputProps={{ "data-testid": `radio-button-2` }} onChange={() => handleCheckboxChange(2)} />{RecoveryCable}</TableCell>
                          <TableCell sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "1px" }} align='left'> < Radio checked={selectedvalues === 3} inputProps={{ "data-testid": `radio-button-3` }} onChange={() => handleCheckboxChange(3)} />{RecoveryCable}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <p>Do you want to proceed with selection?</p>
                  <Button style={{ color: "white", backgroundColor: "rgb(2, 2, 81)", marginTop: "30px", marginRight: "10px", width: "100px", height: "30px", float: "right" }} data-testid="cancel-selection-summary" onClick={() => { setOpensummary(false); setOpen(false); }}>Cancel</Button>
                  <Button style={{ color: "white", backgroundColor: "rgb(2, 2, 81)", marginTop: "30px", marginRight: "10px", width: "100px", height: "30px", float: "right" }} data-testid="selection-summary-ok" onClick={handleSelectOk}>OK</Button>
                </Box>
              </Modal>
            </div>}

            <Button id="alert-dialog-button-no" style={{ color: "white", backgroundColor: "rgb(2, 2, 81)", margin: "0px 10px 9px", width: "auto", height: "30px", float: "right", fontSize: "12px" }} data-testid="not-assign-job" onClick={handleNavigate}>No</Button>
          </DialogActions>
        </Dialog>}
      {assignopen ? <AssignedDialog assignopen={assignopen} sau={sau} RecoveryCable={RecoveryCable} exchange={exchange} css={css} /> : null}

    </div>
  );
}
import { useState, useContext } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import axios from 'axios';
import { ACR, BASE_URL, CHANGE_OWNERSHIP } from '../../constant/urls';
import { AllContext, BreadCrumbContext } from '../common/BreadCrumbContext';
import { useMsal } from '@azure/msal-react';
import Select from 'react-select';
import { handledisable } from './ChangeOwnershipDialog.util';

export default function ChangeOwnershipDialog({ jobid, handleTable, disable, jobdetailsdisable, setsnackOpenfail, setSnackmsg, setsnackopensucess }) {
    const { setExchrecall } = useContext(BreadCrumbContext);
    const { userData } = useContext(AllContext)
    const [open, setOpen] = useState(false);
    const [owners, setOwners] = useState([])
    const [selectedowners, setSelectedowners] = useState('');
    const { accounts } = useMsal()


    let params = {
        logged_user: accounts[0]?.idTokenClaims.EIN
    }
    // dropdown value handle
    const handleChange = (owners) => {
        const select = owners.value.split("(")
        const updated_ein = select[1].split(")")[0]
        setSelectedowners(updated_ein);
    };

    // styling for dropdown 
    const customstyles = {
        control: (provided, state) => ({
            ...provided,
            fontSize: '12px',
            width: '300px',
            minHeight: '25px'
        }),
        option: (provided, state) => ({
            ...provided,
            fontSize: '12px'
        }),
        menuPortal: (provided, state) => ({
            ...provided,
            zIndex: 9999
        })
    }

    // on clicl change ownership
    const handleClickOpen = () => {
        setOpen(true);
        handledropdown()
    };

    // axios call to get users name
    const handledropdown = () => {
        const ownerurl = `${BASE_URL}/${ACR}/${CHANGE_OWNERSHIP}?job_id=${jobid}`
        axios.get(ownerurl, { params: params })
            .then((res) => {
                const resowner = res.data.Data
                const pnameobject = resowner?.map(item => ({ value: item, label: item }))
                setOwners(pnameobject)
            })
    }

    // to change ownership
    const handleOk = () => {
        const patchownerurl = `${BASE_URL}/${ACR}/${CHANGE_OWNERSHIP}`
        const newowner = {
            "updated_ein": selectedowners,
            "job_id": jobid,
            "user_ein": JSON.parse(localStorage.getItem("USER_EIN")),
            "role": userData?.ROLE
        }
        axios.patch(patchownerurl, newowner,)
            .then((res) => {
                ((res.data.status).toLowerCase() === "fail") && setsnackOpenfail(true);
                ((res.data.status).toLowerCase() === "fail") && setSnackmsg(res.data.MESSAGE);
                ((res.data.status).toLowerCase() === "success") && setsnackopensucess(true);
                ((res.data.status).toLowerCase() === "success") && setSnackmsg("Changed owner successfully")

                handleTable();
                setExchrecall(true)
                setOpen(false);
            })
            .catch((error) => {
                console.error(error)
            })
    }
    // to close the modal
    const handleClose = (event, reason) => {
        (reason !== 'backdropClick') && setOpen(false);


    };


    return (
        <div>
            <Button id="jobdetails-button-ownership" variant="contained" data-testid="jobdetails-button-ownership"
                sx={{ mr: 1, fontSize: "10px", fontWeight: 700, backgroundColor: "#142032", color: "white", width: "130px", height: "32px" }}
                onClick={handleClickOpen} disabled={handledisable(disable, jobdetailsdisable, userData)} >Change Ownership</Button>
            <Dialog disableEscapeKeyDown open={open} onClose={handleClose}>
                <DialogTitle id="title-change-ownership" sx={{ fontSize: "15px" }}>Do you want to assign to new user?</DialogTitle>
                <DialogContent id="content-change-ownership">
                    <Select
                        id="change-ownership"
                        inputId="change-ownership"
                        onChange={handleChange}
                        menuPortalTarget={document.body}
                        options={owners}
                        styles={customstyles}
                        data-testid="change-ownership" />
                </DialogContent>
                <DialogActions>
                    <Button id="change-owenership-ok" data-testid="change-owenership-ok" style={{ color: "white", backgroundColor: "rgb(2, 2, 81)", marginBottom: "10px", marginRight: "10px", width: "60px", height: "30px" }} onClick={handleOk}>Ok</Button>
                    <Button id="change-owenership-dismiss" data-testid="change-owenership-dismiss" style={{ color: "white", backgroundColor: "rgb(2, 2, 81)", marginBottom: "10px", marginRight: "10px", width: "100px", height: "30px" }} onClick={handleClose}>Dismiss</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
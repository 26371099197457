import React, { useEffect, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { BreadCrumbHcrContext } from './BreadCrumbContext';

const BreadcrumbHcr = () => {
    const location = useLocation();
    let search = location.search === '' ? null : location.search;
    const pathnames = location.pathname.split('/').filter((x) => x);
    const { pathList, setPathList } = useContext(BreadCrumbHcrContext);

    useEffect(() => {

        if (location.pathname === '/hcrplanning' && pathList?.length > 1) {
            const pathData = [pathList[0]];
            setPathList(pathData);
            localStorage.setItem('hcrplanningBreadcrumb', JSON.stringify(pathData))
        } else if (location.pathname === '/planningcable' && pathList?.length > 2) {
            const pathData = [pathList[0], pathList[1]];
            setPathList(pathData);
            localStorage.setItem('hcrplanningBreadcrumb', JSON.stringify(pathData))
        }
        else
            if (location.pathname !== '/hcrplanning') {
                if (!pathList?.some((val) => val.pathname === pathnames[0])) {
                    let pathObj = { pathname: pathnames[0], search: search };
                    setPathList([...pathList, pathObj]);
                }
            }

    }, [pathnames, location.pathname, search, pathList, setPathList]);

    return (
        <div style={{ display: 'flex', fontSize: 11, marginBottom: 20, marginTop: 10 }}>

            {pathList?.map((value) => {
                const splitedValue = value.pathname.split('/').filter((x) => x);
                const names = splitedValue[splitedValue.length - 1];
                const to = value.search ? `/${value.pathname}${value.search}` : `/${value.pathname}`;

                let BreadCrumbName = "";

                if (names === 'hcrplanning') {
                    BreadCrumbName = 'Hcr_Cable'
                }
                else if (names === 'planningcable') {
                    BreadCrumbName = "Pcp"
                }
                else if (names === 'hcrpcp') {
                    BreadCrumbName = "Pcp Detail"
                }
                return (
                    <React.Fragment key={value.pathname}>
                        <span key={value.pathname} style={{ fontSize: "11px" }}>&nbsp;&nbsp;&nbsp;
                            <Link id={names} data-testid={names} to={to} style={{ textDecoration: 'none' }}>
                                {BreadCrumbName}
                            </Link>
                        </span>
                    </React.Fragment>
                );
            })}
        </div>
    );
};

export default BreadcrumbHcr;